// Third party libs/components
import React, { useState } from 'react';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// Components
import * as Styled from './Trip.style';
import BaseStep from './BaseStep';
import ReturnSelectionStep from './ReturnSelectionStep';

// Utils
import { useCurrentLang } from '@utils/translate';

// Consts
const localeLangsMap = {
  pt_BR: locales.pt,
  en_US: locales.en,
  es_ES: locales.es,
  // it_IT: locales.it,
};
const datePickerSettings = {
  months: 1,
  direction: 'horizontal',
  editableDateInputs: true,
  moveRangeOnFirstSelection: false,
  showMonthAndYearPickers: false,
  showSelectionPreview: true,
  showDateDisplay: false,
  color: '#00BFA5',
  rangeColors: ['#00BFA5'],
  weekStartsOn: 0,
  monthDisplayFormat: 'LLLL',
  fixedHeight: false,
  focusedRange: [0, 1],
  dragSelectionEnabled: false,
};
const returnSelectorSettings = {
  localeLangsMap,
  datePickerSettings,
};

const Trip = ({
  origin,
  destination,
  departureDate,
  returnDate,
  closeFilter,
  handleSetTrip,
}) => {
  const [returnSelection, setReturnSelection] = useState(false);
  const [newReturnDate, setReturnDate] = useState(returnDate);
  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: departureDate,
      endDate: returnDate || departureDate,
      key: 'selection',
    },
  ]);
  const currentLang = useCurrentLang();

  const handleBaseStepSelection = isOneWay => {
    if (isOneWay) {
      setReturnDate(null);
      setDateRangeState([
        { ...dateRangeState[0], endDate: dateRangeState[0].startDate },
      ]);
      closeFilter();
      handleSetTrip(true);
    } else {
      setReturnSelection(true);
    }
  };

  const handleReturnSelection = () => {
    closeFilter();
    handleSetTrip(
      false,
      dateRangeState[0].startDate,
      dateRangeState[0].endDate
    );
  };

  return (
    <Styled.Trip>
      {returnSelection ? (
        <ReturnSelectionStep
          settings={returnSelectorSettings}
          ranges={dateRangeState}
          origin={origin}
          destination={destination}
          newReturnDate={newReturnDate}
          currentLang={currentLang}
          onSetReturnDate={setReturnDate}
          onSetDateRange={setDateRangeState}
          onSubmit={handleReturnSelection}
          onReturnStep={setReturnSelection}
        />
      ) : (
        <BaseStep onSelect={handleBaseStepSelection} />
      )}
    </Styled.Trip>
  );
};

export default Trip;
