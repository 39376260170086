import styled, { css } from 'styled-components';

const defineGalleryResponsive = () => {
  return css`
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    overflow: hidden;

    img {
      width: 50%;
      padding: 3px;
      object-fit: cover;
    }

    img:nth-child(2) {
      border-top-right-radius: 16px;
    }

    img:nth-child(3) {
      width: 100%;
      border-bottom-right-radius: 16px;
      height: 150px;
    }

    img:last-child {
      border-bottom-right-radius: 16px;
    }
  `;
};

export const Container = styled.div`
  padding-bottom: -30px;
`;

export const Card = styled.div`
  box-shadow: 0px 3px 5px #00000029;
  border-radius: 14px;
  position: relative;
  background-color: #ffffff;
  padding: 10px 10px 3px 10px;
  z-index: 1;
  margin-left: 2px;
  animation-name: fade;
  animation-duration: 1s;
`;

export const CardImages = styled.div`
  @media (min-width: 1024px) {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: center;
    overflow: hidden;
  }
`;

export const CardMainImage = styled.img`
  @media (min-width: 1024px) {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    width: 50%;
    padding: 3px;
  }

  width: 100%;
  object-fit: cover;
`;

export const CardGallery = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    overflow: hidden;

    img {
      width: 50%;
      padding: 3px;
      object-fit: cover;
    }

    img:last-child {
      border-bottom-right-radius: 16px;
    }

    img:nth-child(2) {
      border-top-right-radius: 16px;
    }

    img:nth-child(3) {
      width: 50%;
      border-top-right-radius: 16px;
    }

    ${({ responsive }) => {
      return responsive ? defineGalleryResponsive() : '';
    }}
  }

  display: none;

  ${({ responsive }) => {
    return responsive
      ? css`
          display: none;
        `
      : '';
  }}
`;

export const ImgResponsive = styled.img`
  @media (min-width: 1024px) {
    overflow: hidden;

    ${({ t }) => {
      return t
        ? css`
            height: 300px;
          `
        : css`
            height: 150px;
          `;
    }}
  }
`;

export const CardInformation = styled.div`
  margin: 5px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    object-fit: contain;
    max-width: 50px;
    max-height: 50px;
    width: auto;
    height: auto;
    margin-right: 10px;
  }
`;

export const ContentInformationPrice = styled.div`
  margin: 0 0 0 auto;
`;

export const CardInformationPrice = styled.p`
  font-size: 15px;
  color: #000000;
  margin: 0 15px 0 0;
`;

export const CardPrice = styled.p`
  color: #009688;
  font-size: 26px;
  font-weight: 500;
  margin: 0 15px 0 0;
`;

export const SubCard = styled.div`
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 22px 22px 22px 40px;
  background-color: #ffffff;
  padding: 10px;
  padding-top: 100px;
  position: relative;
  top: -85px;
  width: calc(100% - 20px);
  left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation-name: fade;
  animation-duration: 1s;
  margin-bottom: -50px;

  img {
    height: 20px;
    width: auto;
    margin-right: 20px;
  }
`;

export const Amenities = styled.div`
  display: flex;
  margin-left: 30px;

  &:hover {
    flex-wrap: wrap;
  }

  &:hover .amenityColumn {
    margin-top: 20px;
  }

  @media (max-width: 700px) {
    &:hover .amenity:nth-child(n + 6):nth-child(-n + 20) {
      display: block;
    }
  }
`;

export const Amenity = styled.div`
  position: relative;

  img {
    width: 28px;
    height: 28px;
  }

  p {
    display: none;
    flex-wrap: nowrap;
    color: #ffffff;
    font-size: 12px;
    background-color: #009688;
    padding: 5px 10px;
    position: absolute;
    border-radius: 0 5px 5px 5px;
    left: 10px;
    margin: 5px;
    z-index: 5;
  }

  &:hover p {
    display: block;
  }

  &:nth-child(n + 10):nth-child(-n + 20) {
    display: none;
  }

  &:hover .amenity:nth-child(n + 10):nth-child(-n + 20) {
    display: block;
  }

  @media (max-width: 700px) {
    &:nth-child(n + 6):nth-child(-n + 20) {
      display: none;
    }
  }
`;

export const IconImageUrl = styled.img``;

export const Label = styled.p``;

export const BookNow = styled.div`
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  background: transparent linear-gradient(90deg, #00bba3 0%, #009688 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 48px;
  padding: 15px 25px;
  cursor: pointer;
  text-decoration: none;
  margin-left: 5px;

  @media (min-width: 1024px) {
    padding: 15px 50px;
  }
`;
