import styled from 'styled-components';

export const ButtonContainer = styled.div`
  text-align: center;

  @media (max-width: 1023px) {
    text-align: left;
  }
`;

export const ButtonWrapper = styled.div`
  display: inline-block;
  margin: 0 auto;

  button {
    box-shadow: 10px 4px 40px #1429942e;
  }
`;
