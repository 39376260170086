// Third party libs/components
import React from 'react';
import P from 'prop-types';

// Components
import * as Styled from './SalesResultSection.style';
import Button from '@components/Button';
import SectionGroupContainer from '@components/ContainerComponents/SectionGroupContainer';
import SectionContainer from '@components/ContainerComponents/SectionContainer';
import SliderContainer from '@components/ContainerComponents/SliderContainer';
import AircraftToSaleCard from '@components/Cards/AircraftToSaleCard';
import Loader from '@components/Loader';

// Utils
import { translate as t } from '@utils/translate';

const SalesResultSection = ({ items, title, subtitle, isLoading }) => {
  const TEXTS = {
    MORE_ITEMS_LABEL: t('seeMore'),
    AVAILABLE_AIRCRAFT: t('availableAircraft'),
  };

  if (!items) return null;

  const aItems = Object.entries(items);

  return (
    <Styled.SalesResultSectionWrapper>
      {isLoading && (
        <Styled.LoadingWrapper>
          <Loader show={isLoading}></Loader>
        </Styled.LoadingWrapper>
      )}
      <Styled.Title>{TEXTS.AVAILABLE_AIRCRAFT}</Styled.Title>

      <SectionGroupContainer
        title={title}
        subtitle={subtitle}
        showTitle={false}
      >
        {aItems &&
          aItems.map((group, index) => {
            return (
              <SectionContainer title={group[0]} subtitle={null} key={index}>
                <SliderContainer
                  Card={AircraftToSaleCard}
                  GridButton={
                    <Button
                      text={TEXTS.MORE_ITEMS_LABEL}
                      style={{ boxShadow: 'none' }}
                    />
                  }
                  items={group[1]['aircrafts']}
                  settingsName={'sales'}
                  stylesName={'sales'}
                  hasGrid={true}
                />
              </SectionContainer>
            );
          })}
      </SectionGroupContainer>
    </Styled.SalesResultSectionWrapper>
  );
};

SalesResultSection.propTypes = {
  items: P.object,
  title: P.string,
  subtitle: P.string,
};

export default SalesResultSection;
