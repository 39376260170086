import styled from 'styled-components';

export const StepContainer = styled.div``;

export const StepOption = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 9px 29px 9px 16px;
  white-space: nowrap;
  background-color: #fff;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  cursor: pointer;

  &:hover {
    background-color: #1eb19d;
    color: #fff;
  }

  @media (max-width: 1280px) {
    font-size: 15px;
    color: #707070;
    padding: 4px 16px;

    &:hover {
      color: #707070;
      background-color: unset;
    }
  }
`;
