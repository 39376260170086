// react/third-part modules
import React from 'react';
import P from 'prop-types';
import * as Styled from './SectionContainer.style';

const SectionContainer = ({ title, subtitle, children }) => {
  return (
    <Styled.SectionContainer>
      {title && title !== 'undefined' ? (
        <Styled.Title>{title}</Styled.Title>
      ) : null}
      {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
      {children}
    </Styled.SectionContainer>
  );
};

SectionContainer.propTypes = {
  title: P.string,
  subtitle: P.string,
  children: P.element,
};

export default SectionContainer;
