import React from 'react';
import { ThemeProvider } from 'styled-components';
import * as Styled from './Snackbar.styles';

/**
 *
 * @param string message message to be show
 * @param string contentAlign "center|top|bottom"
 * @param string message
 * @param string message
 */
const Snackbar = ({
  message,
  contentAlign = 'center',
  children,
  icon = null,
  type = 'info',
  actionButton,
}) => {
  let theme = Styled.THEMES[type] || Styled.THEMES['info'];

  // Icon
  let iconComponent = null;
  if (typeof icon === 'string') {
    iconComponent = <span className="material-icons">{icon}</span>;
  } else if (icon) {
    iconComponent = icon;
  }

  return (
    <ThemeProvider theme={theme}>
      <Styled.Snackbar>
        {iconComponent && (
          <Styled.SnackbarIcon>{iconComponent}</Styled.SnackbarIcon>
        )}
        <Styled.SnackbarContent>{message || children}</Styled.SnackbarContent>
        {actionButton}
      </Styled.Snackbar>
    </ThemeProvider>
  );
};

export default Snackbar;
