import React, { useState } from 'react';
import P from 'prop-types';

import ToogleArrow from '@components/ToogleArrow';
import ToogleTitle from '@components/ToogleTitle';

import * as S from './styled';

const Toggle = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  return (
    <S.Container>
      <S.Wrap background={isOpen && '#14bea4'}>
        <div>
          <S.Head isOpen={isOpen} onClick={handleToggle}>
            <ToogleTitle
              variant={isOpen ? 'medium' : 'regular'}
              color={isOpen ? '#fff' : '#201D20'}
            >
              {title}
            </ToogleTitle>
            <S.IconWrap isOpen={isOpen}>
              <ToogleArrow
                rotate={isOpen ? 180 : 0}
                color={isOpen ? '#fff' : '#BABABA'}
              />
            </S.IconWrap>
          </S.Head>
        </div>
      </S.Wrap>
      <S.Content isOpen={isOpen}>
        <div>{children}</div>
      </S.Content>
    </S.Container>
  );
};

Toggle.propTypes = {
  title: P.string,
  children: P.node,
};

export default Toggle;
