import styled from 'styled-components';

import SpinningWheel from '@components/SpinningWheel';

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
`;

export const MainMessage = styled.div`
  text-align: center;
  font-size: 24px;
  max-width: 400px;
  margin: 0 auto 24px;
  font-weight: 600;
  color: #009788;
`;

export const SpinningWheelStyled = styled(SpinningWheel)`
  position: relative;
  top: 50%;
  left: 50%;
`;
