import styled from 'styled-components';

export const GroupType = styled.ul`
  list-style: none;
  margin: 0;

  @media (max-width: 1280px) {
    padding-top: 6px;
  }
`;

export const Option = styled.li`
  position: relative;
  margin-bottom: 0;
  padding: 9px 16px 9px 29px;
  background-color: #fff;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  color: #39383d;
  white-space: nowrap;

  &:hover {
    background-color: #1eb19d;
    color: #fff;
  }

  @media (max-width: 1280px) {
    color: #707070;
    padding-top: 4px;
    padding-bottom: 2px;

    &:hover {
      color: #707070;
      background-color: unset;
    }
  }
`;

export const OptionLabel = styled.span`
  pointer-events: none;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 1280px) {
    font-size: 15px;
  }
`;

export const OptionValue = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;
