import styled from 'styled-components';

const ContainerInput = styled.div`
  width: 80px;
  height: 40px;
  border-radius: 17pt;
  padding: 5px;
  transition: all 0.3s linear;
  background: ${props => (props.value ? props.color : '#DCDEE7')};
  cursor: pointer;
  flex: 0 0 80px;
  transform: scale(0.7);
`;

const Ball = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2pt 3px #00000026;
  transform: ${props =>
    props.value ? 'translateX(calc(200% - 20px))' : 'translateX(0)'};
  transition: all 0.3s ease-in-out;
`;

export { ContainerInput, Ball };
