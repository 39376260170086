// Third party libs/components
import React from 'react';
import { useSelector } from 'react-redux';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

// Components
import * as Styled from './AircraftPrice.style';

// Utils
import { translate as t } from '@utils/translate';
import { formatCurrency } from '@utils/currency';

// Assets
import InputStyle from './inputStyle.module.css';

const AircraftPrice = ({ min = 0, max = 1000000000, value, onChange }) => {
  if (!(min < max)) return null;

  const currency = useSelector(state => state.general.currency);
  const inputRangeClasses = {
    activeTrack: InputStyle.activeTrack,
    disabledInputRange: InputStyle.disabledInputRange,
    inputRange: InputStyle.inputRange,
    labelContainer: InputStyle.labelContainer,
    maxLabel: InputStyle.maxLabel,
    minLabel: InputStyle.minLabel,
    slider: InputStyle.slider,
    sliderContainer: InputStyle.sliderContainer,
    track: InputStyle.track,
    valueLabel: InputStyle.valueLabel,
  };

  const TEXTS = {
    PRICE: t('price'),
  };

  return (
    <Styled.AircraftPrice>
      <Styled.AircraftPriceTitle>{TEXTS.PRICE}</Styled.AircraftPriceTitle>
      {
        <InputRange
          classNames={inputRangeClasses}
          minValue={min}
          step={1}
          maxValue={max}
          value={value}
          onChange={onChange}
          formatLabel={value =>
            formatCurrency({ value, currency: currency.code })
          }
        />
      }
    </Styled.AircraftPrice>
  );
};

export default AircraftPrice;
