import { apiHost, getAircraftsByModel } from '@utils/constants';
import useRequest from '@utils/useRequest';

async function getAmenities(id) {
  const query = apiHost + getAircraftsByModel + id + '&bringAmenities=1';
  const { data, status } = await useRequest({
    url: query,
    method: 'get',
    useSession: false,
  });

  if (status === 200) {
    return data.aircrafts[0] ? data.aircrafts[0].amenities : [];
  } else return [];
}

export default ({
  id,
  currency,
  aircraft,
  isShared,
  seats,
  originSpot,
  destinationSpot,
  departureDatetime,
  arrivalDatetime,
}) => ({
  id,
  currency,
  aircraft: {
    id: aircraft.id,
    name: aircraft.name,
    image: aircraft.highlightImageUrl,
    size: aircraft.model.size.description,
    seats: aircraft.quantityOfPassengers,
    amenities: getAmenities(aircraft.id),
  },
  isShared,
  seats: {
    available: seats.length,
    price: seats[0].price || 0.0,
    seats: seats,
  },
  origin: {
    code: originSpot.airport
      ? originSpot.airport.acronym || originSpot.airport.oaci
      : originSpot.helipad.oaci,
    dateTime: departureDatetime,
  },
  destination: {
    code: destinationSpot.airport
      ? destinationSpot.airport.acronym || destinationSpot.airport.oaci
      : destinationSpot.helipad.oaci,
    dateTime: arrivalDatetime,
  },
});
