// TODO: REFACTORING
import React from 'react';
import * as Styled from './Checkbox.style';

const Checkbox = ({ selected = true, text, onClick }) => {
  return (
    <Styled.CheckboxContainer onClick={onClick}>
      <Styled.Checkbox selected={selected} />
      <Styled.Text>
        <span>{text}</span>
      </Styled.Text>
    </Styled.CheckboxContainer>
  );
};

export default Checkbox;
