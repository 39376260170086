// TODO: REFACTORING
import React from 'react';
import * as Styled from './Button.style';

import Loader from '@components/Loader';

const Button = ({
  theme,
  label,
  onClick,
  status,
  resetAfterFinish,
  successMessage,
}) => {
  switch (status) {
    case 'initial':
      return (
        <Styled.Button theme={theme} type="button" onClick={() => onClick()}>
          {label}
        </Styled.Button>
      );

    case 'loading':
      return (
        <Styled.ButtonLoading theme={'message'}>
          <Loader></Loader>
        </Styled.ButtonLoading>
      );

    case 'finished':
      return resetAfterFinish ? (
        <Styled.Button theme={theme} type="button" onClick={() => onClick()}>
          {label}
        </Styled.Button>
      ) : (
        <Styled.Button theme={'message'} type="button">
          {successMessage}
        </Styled.Button>
      );

    case 'disabled':
      return (
        <Styled.Button
          theme={'disabled'}
          type="button"
          onClick={() => false}
          disabled={true}
        >
          {label}
        </Styled.Button>
      );

    default:
      return (
        <Styled.Button theme={theme} type="button" onClick={() => onClick()}>
          {label}
        </Styled.Button>
      );
  }
};

export default Button;
