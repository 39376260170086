// Third party libs/components
import React, { useState, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useStore, useSelector } from 'react-redux';
import P from 'prop-types';

// Components
import SearchSalesResultsHeader from '@components/SearchSalesResultsHeader';
import SalesResultSection from '@components/SalesResultSection';
import Loader from '@components/Loader';
import SearchSalesMenuContainer from './SearchSalesMenuContainer';
import SearchSalesMainContent from './SearchSalesMainContent';

// Utils
import * as Helpers from './utils/SearchSales.helper';
import { useCurrentLang, translate as t } from '@utils/translate';
import {
  mapInitialFilters,
  updateFilterOptions,
  updateGroupBy,
  updateCurrentTab,
  applyFilters,
} from '@store/reducers/filters/operations';

// Services
import * as Services from '@services/search';

const SearchSales = ({ location }) => {
  const store = useStore();
  const path = location.pathname;
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabResults, setTabResults] = useState({
    groupIndex: 0,
    groupName: '',
    groupItems: [],
  });
  const [searchResults, setSearchResults] = useState(null);
  const [filteredResults, setFilteredResults] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedGroupBy, setSelectedGroupBy] = useState('producers');
  const currency = useSelector(state => state.general.currency);
  const lang = useCurrentLang();
  const translations = [
    { path: path, polylang_current_lang: 'en_US' },
    { path: path, polylang_current_lang: 'es_ES' },
    { path: path, polylang_current_lang: 'pt_BR' },
    // { path: path, polylang_current_lang: 'it_IT' },
  ];
  const TEXTS = {
    noItems: t('noItemsAircraftSales'),
    tryAgain: t('tryAgainAircraftSales'),
  };

  const setItems = tabIndex => {
    const index = tabIndex || 0;
    if (searchResults && searchResults.aircrafts[index]) {
      setIsLoading(true);
      const newData = filteredResults
        ? filteredResults[index].items
        : searchResults.aircrafts[index].items;

      setTabResults({
        groupIndex: index,
        groupName: searchResults.aircrafts[index].title,
        groupItems: structuredClone(newData),
      });
    }

    setTimeout(() => setIsLoading(false), 1000);
  };

  const fetchData = async groupBy => {
    let responseData = null;

    responseData = await Services.fetchAircraftsToSale(groupBy);

    if (responseData.status === 200) {
      return responseData;
    } else {
      setError(true);
      return responseData;
    }
  };

  // Filter results
  const execFiltering = () => {
    let filteredData = [];
    const resultsCopy = structuredClone(searchResults);
    filteredData = store.dispatch(applyFilters(resultsCopy));
    setFilteredResults(filteredData);
  };

  const handleUpdateFilterValues = values => {
    store.dispatch(updateFilterOptions(values, searchResults));
  };

  const handleApplyFilters = () => {
    execFiltering();
  };

  const handleTabSelect = tabIndex => {
    setSelectedTab(tabIndex);
    setItems(tabIndex);
  };

  const handleGroupBy = groupName => {
    store.dispatch(updateGroupBy(groupName));
    setSelectedGroupBy(groupName);
  };

  useEffect(() => {
    (async () => {
      let producersData = await fetchData('producers');
      let categoriesData = await fetchData('categories');

      try {
        if (producersData) {
          producersData = Helpers.formatResponseForSearchSalesResults(
            producersData.data.aircraftSales,
            currency.code || 'USD',
            'producers'
          );

          store.dispatch(mapInitialFilters(producersData, 'producers'));
          setSearchResults(producersData);
        }

        if (categoriesData) {
          categoriesData = Helpers.formatResponseForSearchSalesResults(
            categoriesData.data.aircraftSales,
            currency.code || 'USD',
            'categories'
          );
          store.dispatch(mapInitialFilters(producersData, 'categories'));
          setSearchResults(categoriesData);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [lang]);

  useEffect(() => {
    store.dispatch(
      updateCurrentTab(selectedTab === 0 ? 'airplanes' : 'helicopters')
    );
    if (searchResults) {
      execFiltering();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (filteredResults) setItems(selectedTab, filteredResults);
  }, [filteredResults]);

  useMemo(() => {
    if (searchResults) {
      setItems(0);
      execFiltering();
    }
  }, [searchResults]);

  window.scrollTo({ top: 0 });

  if (!searchResults || !searchResults.aircrafts) {
    return <Loader customStyles={{ backgroundColor: 'white' }} />;
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <SearchSalesResultsHeader translations={translations} />
      {/* <InfoHeader /> */}
      <SearchSalesMenuContainer
        selectedTab={selectedTab}
        onSelectTab={handleTabSelect}
        onGroupBy={handleGroupBy}
        onUpdateFilterValues={handleUpdateFilterValues}
        onApplyFilters={handleApplyFilters}
      />
      <SearchSalesMainContent />
      {tabResults &&
      tabResults.groupItems &&
      Object.keys(tabResults.groupItems).length > 0 ? (
        <React.Fragment key={tabResults.groupIndex}>
          <SalesResultSection
            key={tabResults.groupIndex}
            items={tabResults.groupItems}
            title={tabResults.groupName}
            subtitle={''}
            isLoading={isLoading}
          />
        </React.Fragment>
      ) : null}
      {!!Object.keys(tabResults.groupItems).length || (
        <div
          style={{
            width: '100%',
            marginTop: 130,
            height: 400,
            display: 'grid',
            placeContent: 'center',
            padding: 20,
          }}
        >
          <h1 style={{ fontSize: 48, textAlign: 'center' }}>{TEXTS.noItems}</h1>
          <h2
            style={{
              fontSize: 32,
              color: '#207272',
              textAlign: 'center',
            }}
          >
            {TEXTS.tryAgain}
          </h2>
        </div>
      )}
    </>
  );
};

SearchSales.propTypes = {
  location: P.object,
};

export default SearchSales;
