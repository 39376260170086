// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

import { translate as t } from '@utils/translate';

const noticeIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 511.755 511.755" style="enable-background:new 0 0 511.755 511.755;" xml:space="preserve" width="16px" height="16px" class=""><g><path d="M436.891,74.867c-99.819-99.819-262.208-99.819-362.027,0c-99.819,99.797-99.819,262.229,0,362.027    c49.899,49.92,115.456,74.859,181.013,74.859s131.115-24.939,181.013-74.859C536.709,337.096,536.709,174.664,436.891,74.867z     M255.877,426.547c-11.776,0-21.333-9.557-21.333-21.333s9.557-21.333,21.333-21.333c11.776,0,21.333,9.557,21.333,21.333    S267.653,426.547,255.877,426.547z M277.211,319.88c0,11.776-9.536,21.333-21.333,21.333c-11.797,0-21.333-9.557-21.333-21.333    V106.547c0-11.776,9.536-21.333,21.333-21.333c11.797,0,21.333,9.557,21.333,21.333V319.88z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FFFFFF"/></g></svg>`;
const googleMapsPin = `<svg xmlns="http://www.w3.org/2000/svg" width="12.039" height="17.23" viewBox="0 0 12.039 17.23"><path fill="#ecb72b" d="M102.639,138.891l-1.788,2.641-2.644,2.631a19.408,19.408,0,0,1-2.712-3.925l1.425-2.183,2.186-2.109a2.3,2.3,0,0,0,3.417,3.071C102.563,138.975,102.6,138.933,102.639,138.891Z" transform="translate(-94.877 -131.37)"/><path fill="#5085f7" d="M207.973,15.521a2.3,2.3,0,0,0-3.411-3.078q-.057.057-.108.116l1.508-2.452,2.046-1.777a6.035,6.035,0,0,1,3.522,2.961L210.037,13.7Z" transform="translate(-200.169 -8.05)"/><path fill="#da2f2a" d="M81.362,66.436l-3.611,4.292a6.85,6.85,0,0,1-.615-2.676c0-.057,0-.114,0-.171a6,6,0,0,1,1.4-3.865Z" transform="translate(-77.133 -61.861)"/><path fill="#4274eb" d="M121.7,4.575l-2.824-2.421A6.028,6.028,0,0,1,125.31.28Z" transform="translate(-117.471 0)"/><path fill="#60a850" d="M184.785,99.085a5.994,5.994,0,0,0-.678-2.778l-8.031,9.552a9.97,9.97,0,0,1,2.093,3.818c.011.047.023.1.036.163a.573.573,0,0,0,1.123,0c.013-.062.025-.116.036-.163.924-3.9,5.311-5.893,5.42-10.42C184.784,99.2,184.785,99.143,184.785,99.085Z" transform="translate(-172.746 -93.066)"/></svg>`;
const greenPin = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16.334 24"><g transform="translate(0)"><path fill="#009788" d="M93.5,0a8.094,8.094,0,0,0-8.167,8,7.886,7.886,0,0,0,.98,3.8l6.74,11.94a.516.516,0,0,0,.893,0L100.689,11.8a7.884,7.884,0,0,0,.978-3.8A8.094,8.094,0,0,0,93.5,0Zm0,12a4.047,4.047,0,0,1-4.083-4,4.084,4.084,0,0,1,8.167,0,3.922,3.922,0,0,1-.459,1.842A4.094,4.094,0,0,1,93.5,12Z" transform="translate(-85.333)"/></g></svg>`;

const ScheduleItem = ({
  time,
  airportName,
  city,
  codeIATA,
  codeICAO,
  mapImage,
  mapLink,
  last,
}) => {
  return (
    <>
      <div
        className={[
          Style.scheduleItem,
          last ? Style.lastOne : '',
          mapImage ? Style.hasImg : '',
        ].join(' ')}
      >
        <div className={Style.dotsDecoration}>
          <div className={Style.placeMarker} />
          <div
            className={Style.lastPlaceMarker}
            dangerouslySetInnerHTML={{ __html: greenPin }}
          />
          <div className={Style.dotsWrapper}>
            <i />
            <i />
            <i />
            <i />
            <i />
            <i />
            <i />
            <i />
            <i />
            <i />
          </div>
        </div>
        <span className={Style.time}>{time}</span>
        <div className={Style.airport}>
          <div className={Style.airportNameContainer}>
            <span className={Style.airportName}>{airportName}</span>
            {city && <span className={Style.city}> • {city}</span>}
          </div>
          <a className={Style.mapLink} target="_blank" href={mapLink}>
            {mapImage && (
              <img
                className={Style.mapImage}
                src={mapImage}
                alt={airportName}
              />
            )}
            <span className={Style.mapLinkLabel}>
              Abrir GoogleMaps{' '}
              <i
                className={Style.googleMapsPin}
                dangerouslySetInnerHTML={{ __html: googleMapsPin }}
              />
            </span>
          </a>
        </div>
        <span className={Style.codeIATA}>{codeIATA},</span>
        <span className={Style.codeICAO}>{codeICAO}</span>
      </div>
      <br />
    </>
  );
};

const FlightSchedule = ({ title, date, schedule, notice }) => {
  const TEXTS = {
    NOTICE: t('notice'),
  };

  return (
    <section className={Style.flightSchedule}>
      <div className={Style.sectionTitle}>{title}</div>
      <div className={Style.date}>{date}</div>
      <div className={Style.schedule}>
        {schedule.map((scheduleItem, index) => (
          <ScheduleItem
            key={index}
            {...scheduleItem}
            last={index + 1 == schedule.length}
          />
        ))}
        {notice && (
          <div className={Style.noticeContainer}>
            <i
              className={Style.noticeIcon}
              dangerouslySetInnerHTML={{ __html: noticeIcon }}
            />
            <div className={Style.noticeContent}>
              <span className={Style.noticeLabel}>{TEXTS.NOTICE} </span>
              <span className={Style.notice}>{notice}</span>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default FlightSchedule;
