// TODO: REFACTORING
// Third party libs/components
import React, { useState } from 'react';

// Components
import * as Styled from './SlideComponent.style';
import FlightCard from '../../FlightCard';
import SliderPrevButton from '@components/SliderPrevButton';
import SliderNextButton from '@components/SliderNextButton';

// Utils
import '@components/slick.css';

const sliderSettings = {
  slidesToShow: 4,
  slidesToScroll: 3,
  infinite: false,
  draggable: true,
  swipeToSlide: true,
  speed: 1200,
  prevArrow: <SliderPrevButton />,
  nextArrow: <SliderNextButton />,
  responsive: [
    {
      breakpoint: 1550,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SlideComponent = ({ flightsList, route, onSelect }) => {
  const flightsToShow = flightsList;

  const [clientXonMouseDown, setClientXonMouseDown] = useState(0);
  const [clientYonMouseDown, setClientYonMouseDown] = useState(0);

  const handleOnMouseDown = e => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault(); // stops weird link dragging effect
  };

  const handleOnClick = (e, idModel) => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    } else {
      onSelect(idModel, route);
    }
  };

  return (
    <Styled.CustomSlider {...sliderSettings}>
      {flightsToShow.map((flight, index) => (
        <Styled.FlightCardContainer key={index}>
          <FlightCard
            flight={flight}
            onMouseDown={e => handleOnMouseDown(e)}
            onClick={(e, idModel) => {
              handleOnClick(e, idModel);
            }}
          />
        </Styled.FlightCardContainer>
      ))}
    </Styled.CustomSlider>
  );
};

export default SlideComponent;
