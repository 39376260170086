import styled from 'styled-components';

export const Aircraft = styled.div`
  padding: 39px 52px 44px;
  cursor: auto;
  text-align: left;

  .tabsHeader {
    padding-bottom: 0;
    margin-bottom: 21px;
  }

  .tabsHeader > div {
    font-size: 16px;
    border-bottom-width: 4px;
    font-weight: 600;
  }

  @media (max-width: 1280px) {
    padding: 16px 0 0;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

  > div > div {
    margin-right: 34px;
    max-width: 230px;
    overflow-x: hidden !important;
  }

  @media (max-width: 1280px) {
    flex-wrap: wrap;

    > div > div {
      margin-right: 0%;
      max-width: 100%;
      overflow-x: hidden !important;
    }
  }
`;

export const YearPriceContainer = styled.div`
  display: flex;

  @media (max-width: 1280px) {
    display: block;
  }
`;
