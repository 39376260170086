// Third party libs/components
import React from 'react';

// Components
import * as Styled from './Order.style';

const Order = ({ onClick, options, context, closeFilter }) => {
  const handleClick = (methodName, variantName) => {
    onClick(methodName, variantName);
    closeFilter();
  };

  return (
    <Styled.Order>
      {options.map(({ method, variant, label, showOn }, index) => {
        if (!showOn.includes(context)) return null;

        return (
          <Styled.Option
            key={index}
            onClick={() => handleClick(method, variant)}
          >
            <Styled.OptionLabel>{label}</Styled.OptionLabel>
            <Styled.OptionValue>{variant}</Styled.OptionValue>
          </Styled.Option>
        );
      })}
    </Styled.Order>
  );
};

export default Order;
