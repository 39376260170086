import styled from 'styled-components';

export const THEMES = {
  info: {
    backgroundColor: `transparent 
            linear-gradient(90deg, #009788 0%, #00bca8 100%) 0% 0%
            no-repeat padding-box`,
    textColor: '#FFFFFF',
  },
  'info-white': {
    backgroundColor: '#ebebeb',
    textColor: '#7f7f7f',
  },
  success: {
    backgroundColor: '#00CA86',
    textColor: '#FFFFFF',
  },
  error: {
    backgroundColor: '#F26275',
    textColor: '#FFFFFF',
  },
};

export const Snackbar = styled.div`
  height: auto;
  padding: 16px 14px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  background: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColor};
  margin-bottom: 8px;
`;

export const SnackbarIcon = styled.div`
  padding-right: 10px;
`;

export const SnackbarContent = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  margin-bottom: 0;
  margin-top: 4px;
  padding: 0;
`;
