import styled, { keyframes } from 'styled-components';

const translateBottom = keyframes`
  from {
    transform: translateY(-30%);
    opacity: 0;
  } to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Container = styled.div`
  overflow-y: hidden;
`;

export const Wrap = styled.div`
  width: 100%;
  transition: 0.4s background;
  position: relative;
  ${({ background }) => background && `background: ${background};`}
  user-select: none;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;

  ${({ isOpen }) => isOpen === false && 'border-bottom: 1px solid #E0E0E0;'}
`;

export const IconWrap = styled.div`
  width: 80px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s background;

  &:hover {
    ${({ isOpen }) => (isOpen ? `background: #3ac8b3` : `background: #EFEFEF`)}
  }

  @media only screen and (max-width: 768px) {
    height: 64px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: none;
  transition: 0.5s opacity;
  /* padding: 55px 0 25px 0; */
  animation: ${translateBottom} 0.5s ease-in-out forwards;

  ${({ isOpen }) =>
    isOpen &&
    `display: block;
    `}

  @media only screen and (max-width: 768px) {
    padding: 20px 0 0 0;

    > div {
      padding: 0;
    }
  }
`;
