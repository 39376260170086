// TODO: REFACTORING
import React from 'react';
import * as Styled from './ConfirmButton.style';

import { translate as t } from '@utils/translate';

const ConfirmButton = ({ onClick }) => {
  const TEXTS = {
    CONFIRM_BUTTON: t('confirmButton'),
  };
  return (
    <Styled.Button onClick={onClick}>{TEXTS.CONFIRM_BUTTON}</Styled.Button>
  );
};

export default ConfirmButton;
