import styled, { css } from 'styled-components';
// Background gradient
import bg from '@images/bg-gradient-02.png';

export const SearchSalesResultsHeaderWrapper = styled.header`
  background-color: #00897b;
  background-color: #212121;

  background-color: #212121;
  background-image: url(${bg});
  background-repeat: no-repeat;

  -webkit-box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.39);
  padding-top: 21px;
  z-index: 11;
  position: fixed;
  width: 100%;
  top: 0;
  height: var(--search-sales-results-header-height);
  padding-left: calc(var(--container-size) / 2);
  padding-right: calc(var(--container-size) / 2);

  .openMenuModal {
    ${({ menuOpened }) => {
      return menuOpened
        ? css`
            visibility: hidden;
          `
        : '';
    }}
  }

  @media (max-width: 1280px) {
    padding-top: 14px;
  }
`;

export const SearchSalesResultsHeaderContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;

  @media (max-width: 1280px) {
    margin-bottom: 32px;
  }
`;

export const BackButtonContainer = styled.div`
  color: #000;
  filter: invert(1);

  @media (max-width: 1280px) {
    flex-basis: 40px;

    > div {
      font-size: 0;
    }
  }
`;

export const LogoWrapper = styled.div`
  flex-basis: 105px;
  flex-grow: 0;

  > svg {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Logo = styled.div``;

export const OpenMenuModal = styled.div`
  flex-basis: 40px;
  text-align: right;
  cursor: pointer;

  svg {
    transform-origin: right;
    transition: transform 0.2s ease-out;
  }

  &:hover svg {
    transform: scaleX(1.2);
  }
`;
