import styled, { keyframes } from 'styled-components';

export const SliderContainer = styled.div`
  margin-bottom: 35px;

  @media (max-width: 768px) {
    .slick-list {
      padding-left: 16px;
    }
  }
`;

const enterAnimation = keyframes`
  0% { 
    opacity: 0;
    margin-top: -100px;
  }
  
  100% { 
    opacity: 1;
    margin-top: 0px;
  }
`;

export const Grid = styled.div.attrs({
  className: 'grid-container',
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: calc(var(--container-size) / 2);

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const SliderItem = styled.div.attrs({
  className: 'slide-item',
})``;

export const GridItem = styled.div.attrs({
  className: 'grid-item',
})`
  margin-bottom: 40px;
  opacity: 0;

  animation: ${enterAnimation} 0.8s
    ${({ animationIndex }) => `${animationIndex * 0.1}s`} forwards;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  overflow-anchor: none;
`;
