import styled from 'styled-components';

export const SectionContainer = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
`;

export const Title = styled.h3`
  color: #444444;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: calc(var(--container-size) / 2);

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const Subtitle = styled.p`
  color: #9f9f9f;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 30px;
  padding-left: calc(var(--container-size) / 2);

  @media (min-width: 1024px) {
    font-size: 20px;
    margin-bottom: 50px;
  }
`;
