// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const SliderPrevButton = ({ className, onClick }) => {
  return (
    <button
      className={[Style.prevButton, className].join(' ')}
      onClick={onClick}
    >
      <div className={Style.prevArrow}></div>
    </button>
  );
};

export default SliderPrevButton;
