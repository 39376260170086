import styled, { css } from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';

const PRICE_ACTIONS_WDT = '212px';
const ACTION_BUTTONS_SIZE = '16px';
const COLORS = {
  primaryGrey: '#EEF2F6',
  primaryGreen: '#00BFA5',
  secondaryGreen: '#00897B',
};

const desktop = {
  sharedTag: css`
    min-height: 15rem;
    position: relative;
    width: 100%;
    max-width: 1100px;
    margin: auto;

    ${ifProp(
      'shouldHide',
      css`
        opacity: 0.5;
        pointer-events: none;
        filter: blur(12px) brightness(0.9);
      `
    )}
  `,
  priceAndAction: css`
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      width: auto;
    }

    background-color: transparent;
    font-family: 'Montserrat';
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  `,
  priceContainer: css`
    background: rgb(238, 242, 246);
    background: linear-gradient(
      90deg,
      rgba(238, 242, 246, 0) 0%,
      rgba(238, 242, 246, 1) 48%
    );
    border-radius: 0 30px 0 0;
    text-align: right;
    width: 100%;

    p {
      display: inline-block;
      font-size: 25px;
      font-weight: 600;
      margin: 0;
      padding: 17px 25px 15px;
      text-align: center;
      width: ${PRICE_ACTIONS_WDT};

      .price-label {
        color: #666;
        display: block;
        font-size: 11px;
        padding-bottom: 3px;
      }
    }
  `,
  actionButtons: css`
    text-align: right;

    .buttonContainer {
      &.book {
        background-color: ${COLORS.primaryGreen};
        border-radius: 0 0 30px 0;
        padding: 15px 0;

        button {
          background-color: transparent;
          border: none;
          color: white;
          cursor: pointer;
          font-weight: 600;
          font-size: ${ACTION_BUTTONS_SIZE};
          width: ${PRICE_ACTIONS_WDT};
        }
      }

      &.details {
        button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-weight: 400;
          font-size: ${ACTION_BUTTONS_SIZE};
          width: calc(${PRICE_ACTIONS_WDT} - 60px);
          margin: 10px 30px;
        }
      }
    }
  `,
  details: css`
    background-color: ${({ theme }) =>
      theme == 'origin' ? 'white' : COLORS.secondaryGreen};
    border-radius: 10px;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    left: 0;
    min-height: 145px;
    padding: 10px;
    position: absolute;
    top: 21px;
    transition: background-color 0.4s ease-in-out;
    width: calc(100% - ${PRICE_ACTIONS_WDT});

    .originBox {
      transition: width 0.4s ease-in-out;
      width: ${({ theme }) => (theme === 'origin' ? '70%' : '43%')};
    }
  `,
  passangersAndCarousel: css`
    position: relative;
    transition: width 0.4s ease-in-out;
    width: ${({ theme }) => (theme === 'origin' ? '30%' : '57%')};
  `,
};

const mobile = {
  sharedTag: css`
    min-height: ${prop => (!prop.shouldExpand ? '33rem' : '38rem')};
    position: relative;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.35);
    padding: 10px;

    ${ifProp(
      'shouldHide',
      css`
        opacity: 0;
        pointer-events: none;
      `
    )}
  `,
  priceAndAction: css`
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      width: auto;
    }

    background-color: transparent;
    font-family: 'Montserrat';
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  `,
  priceContainer: css`
    text-align: center;
    width: 100%;

    p {
      display: inline-block;
      font-size: 25px;
      font-weight: 600;
      margin: 0;
      padding-bottom: 5px;
      text-align: center;
      width: ${PRICE_ACTIONS_WDT};

      .price-label {
        color: #666;
        display: block;
        font-size: 11px;
        padding-bottom: 3px;
      }
    }
  `,
  actionButtons: css`
    display: flex;
    flex-direction: row-reverse;

    .buttonContainer {
      width: 50%;
      text-align: center;
      padding: 10px;

      &:first-child {
        padding-left: 3px;
      }

      &:last-child {
        padding-right: 3px;
      }

      button {
        width: 100%;
        padding: 15px 0;
        border-radius: 10px;
      }

      &.book {
        button {
          background-color: ${COLORS.primaryGreen};
          border: none;
          color: white;
          cursor: pointer;
          font-weight: 600;
          font-size: ${ACTION_BUTTONS_SIZE};
        }
      }

      &.details {
        button {
          background-color: rgba(0, 0, 0, 0.05);
          border: none;
          cursor: pointer;
          font-weight: 400;
          font-size: ${ACTION_BUTTONS_SIZE};
        }
      }
    }
  `,
  details: css`
    background-color: ${({ theme }) =>
      theme == 'origin' ? 'white' : COLORS.secondaryGreen};
    border-radius: 10px;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    left: 10px;
    min-height: ${({ isReturnFlight }) => (isReturnFlight ? '464px' : '315px')};
    max-height: 472px;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: background-color 0.4s ease-in-out;
    flex-direction: column;
    overflow: hidden;

    .originBox {
      transition: width 0.4s ease-in-out;
      width: 100%;
      height: auto;
    }
  `,
  passangersAndCarousel: css``,
};

const SharedTag = styled.div`
  ${ifNotProp('isMobile', desktop.sharedTag, mobile.sharedTag)}
`;

const PriceAndAction = styled.div`
  ${ifNotProp('isMobile', desktop.priceAndAction, mobile.priceAndAction)}

  .priceContainer {
    ${ifNotProp('isMobile', desktop.priceContainer, mobile.priceContainer)}
  }

  .actionButtons {
    ${ifNotProp('isMobile', desktop.actionButtons, mobile.actionButtons)}
  }
`;

const FlightDetails = styled.div`
  ${ifNotProp('isMobile', desktop.details, mobile.details)}

  .passangersAndCarousel {
    ${ifNotProp(
      'isMobile',
      desktop.passangersAndCarousel,
      mobile.passangersAndCarousel
    )}
  }
`;

const EmptyLegMessage = styled.div`
  color: white;
  background-color: black;
  border-radius: 5px;
  display: flex;
  height: 100%;
  padding: 15px;

  .message {
    min-width: 85%;

    h1 {
      padding: 0;
      margin: 0;
      margin-bottom: 5px;
      font-size: 16px;
    }

    h2 {
      color: rgba(255, 255, 255, 0.7);
      margin: 0;
      margin-bottom: 10px;
      font-size: 15px;
      padding: 0;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
  }

  .seats-counter {
    align-items: center;
    display: flex;
    font-size: 28px;
    font-weight: 500;
    justify-content: center;
    margin: 0;
    min-width: 15%;
    padding: 0;
  }
`;

export { SharedTag, PriceAndAction, FlightDetails, EmptyLegMessage };
