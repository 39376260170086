import styled from 'styled-components';

export const SearchLoading = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 50;
`;

export const SearchLoadingContent = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
`;

export const MainMessage = styled.div`
  text-align: center;
  font-size: 32px;
  max-width: 400px;
  margin: 0 auto 24px;
  font-weight: 600;
  color: #009788;

  p {
    margin-top: 7px;
    display: block;
    font-size: 18px;
  }
`;

export const SecondaryMessage = styled.div`
  text-align: center;
  font-size: 16px;
  margin: 24px auto 0;
  max-width: 400px;
  font-weight: 500;
`;

export const OriginToDestination = styled.p``;
