import styled from 'styled-components';
import Button from '@components/Button';

const Container = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  max-width: 380px;
  margin: auto;
  padding: 40px 60px;
`;

const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='%55555561' d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  padding: 16px;
  left: 0px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 8px;
  transition: all 0.3s linear;

  &:hover {
    background-color: #ddd;
  }
`;

const Logo = styled.img`
  display: block;
  margin: auto;
  margin-bottom: 40px;
  width: 130px;
`;

const DialogTitle = styled.p`
  margin: 10px 0;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  color: #7c7c7c;
`;

const ButtonOutlined = styled(Button)`
  background: white;
  color: #469689;
  border: 1px solid #469689;
  margin-top: 10px;
`;

export { Container, CloseButton, Logo, ButtonOutlined, DialogTitle };
