import styled, { css, keyframes } from 'styled-components';

const fadeDown = keyframes`
  from{
    opacity: 0;
    transform: translateY(100%);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const InfoHeaderContainer = styled.div`
  background-color: #00897b;
  background-color: #212121;
  z-index: 10;
  position: fixed;
  width: 100%;
  top: var(--search-sales-results-header-height);
  height: calc(var(--search-sales-results-header-height) / 2);
  padding-left: calc(var(--container-size) / 2);
  padding-right: calc(var(--container-size) / 2);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* .openMenuModal {
    ${({ menuOpened }) => {
      return menuOpened
        ? css`
            visibility: hidden;
          `
        : '';
    }}
  } */
`;

export const ItemsLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ItemsRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const FiltersWrapper = styled.div`
  display: ${props => (props.opened ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 18px;
  margin-top: 30px;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 99999;
  background-color: #fff;
  box-shadow: 0px 4px 30px #0002;
  padding: 30px;
  border-radius: 20px;
  animation: ${fadeDown} 0.4s ease-in-out forwards;

  @media (max-width: 1350px) {
    border-radius: 0 0 20px 20px;
    margin-top: 0px;
    position: fixed;
    top: 150px;
    right: 0;
    width: 100%;
    padding: 20px 31px 10px 41px;
    box-shadow: 0px 10px 15px #0000004d;
    display: none;
    max-height: calc(100vh - var(--search-results-header-height));
    overflow-y: auto;
    z-index: 999999;

    ${({ opened }) =>
      opened &&
      css`
        display: block;
      `}
  }

  @media (max-height: 600px) {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #00897b;
      border-radius: 3px;
    }
  }
`;

export const FiltersContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div > span {
    font-weight: normal;
  }

  @media (max-width: 1350px) {
    display: block;
  }
`;

export const MenuItemWrapper = styled.div`
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 0;
  min-width: 100px;
  height: calc(var(--search-sales-results-header-height) / 2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #13bdae;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  ${({ selected }) =>
    selected &&
    css`
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(1);
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: #13bdae;
      }
    `}
`;

export const MenuItem = styled.h3`
  font-size: 17px;
  color: white;
  font-weight: normal;
  margin: 0;
  padding: 0;
`;

export const ToggleFilters = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: #7ec9c2;
    border-bottom-width: 2px;
    margin-left: 8px;
  }
`;
