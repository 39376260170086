import styled from 'styled-components';

export const CheckboxList = styled.div`
  margin-bottom: 10px;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export const ListHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #010101;
`;

export const ToggleSelectWrapper = styled.div``;

export const ToggleSelect = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #94949e;
  cursor: pointer;
`;

export const List = styled.div`
  max-height: 160px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00897b;
    border-radius: 3px;
  }

  @media (max-width: 1280px) {
    margin-right: 0%;
    max-width: 100%;
    overflow-x: hidden !important;
  }
`;
