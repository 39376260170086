const distinctArrayFromObjectProperty = (propertyName, array) => {
  let distinctedArray = [];
  let propertyTmpList = [];
  try {
    distinctedArray = array.filter(elem => {
      if (!propertyTmpList.includes(elem[propertyName])) {
        propertyTmpList.push(elem[propertyName]);
        return true;
      } else return false;
    });
  } catch (err) {
    console.error(err);
  }

  return distinctedArray;
};

export { distinctArrayFromObjectProperty };
