// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const FileInput = ({ name, ...props }) => {
  return (
    <div className={Style.textInputContainer}>
      <input type="file" name={name} {...props} />
    </div>
  );
};

export default FileInput;
