import { getSeatPrice } from '../Search.helper';

const sortValues = {
  flightType: null,
};

/**
 * Sort by lower price
 *
 * @param {object} a
 * @param {object} b
 */
const sortByLowerPrice = (a, b) => {
  if (sortValues.flightType === 'flights')
    return getSeatPrice(a.seats) - getSeatPrice(b.seats);
  else return a.startingPrice - b.startingPrice;
};

/**
 * Sort by higher price
 *
 * @param {object} a
 * @param {object} b
 */
const sortByHigherPrice = (a, b) => {
  if (sortValues.flightType === 'flights')
    return getSeatPrice(b.seats) - getSeatPrice(a.seats);
  else return b.startingPrice - a.startingPrice;
};

const sortVariants = {
  lower: sortByLowerPrice,
  higher: sortByHigherPrice,
};

/**
 * Executes the sort method
 *
 * @param {string} variant // Sorting variant (lower, higher)
 * @param {object} data
 * @param {string} flightType // Type of flight results (charter, flights)
 */
const applySort = ({ variant, data, flightType }) => {
  let sortedData = [...data];
  sortValues.flightType = flightType;

  if (sortValues.flightType === 'flights') {
    sortedData.sort(sortVariants[variant]);
  } else {
    sortedData.forEach(route => {
      route.aircraftModels.sort(sortVariants[variant]);
    });
  }

  return sortedData;
};

const sortByPrice = { applySort };

export default sortByPrice;
