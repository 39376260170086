import styled from 'styled-components';

export const PassengersWrapper = styled.div`
  padding: 13px;
  margin-left: auto;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
`;
