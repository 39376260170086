import styled from 'styled-components';

export const Button = styled.button`
  color: #fff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  background-image: linear-gradient(to right, #00bfa5, #009688);
  padding: 10px 0 9px;
  width: 100%;
  cursor: pointer;
`;
