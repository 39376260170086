import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-height: 400px;
  overflow: auto;
  margin: 15px 0 30px 0;
  padding-right: 20px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #009688;
    border-radius: 4px;
  }
`;

const NewCard = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 64px;
  border-radius: 0 0 8px 8px;
  background: #009688;
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in;
  user-select: none;

  &:active {
    transform: translateY(10px);
  }
`;

export { Container, NewCard };
