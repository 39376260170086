// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import PinPosition from './pinPosition.svg';

const FlightDisplay = ({
  destinationImage,
  destinationAlt,
  destinationName,
  flyImage,
  flyAlt,
}) => {
  if (!destinationImage) return null;
  return (
    <section className={[Style.displaySection, 'container'].join(' ')}>
      <div className={Style.cityWrapper}>
        <img
          src={destinationImage}
          alt={destinationAlt}
          className={Style.cityImage}
        />
      </div>
    </section>
  );
};

export default FlightDisplay;
