import { useStaticQuery, graphql } from 'gatsby';

export const useFlightPoliciesData = lang => {
  const data = useStaticQuery(graphql`
    {
      wordpressAcfOptions {
        options_pt_BR {
          politics {
            title
            policies {
              text: description
              link
              inner_link
            }
          }
        }
        options_en_US {
          politics {
            title
            policies {
              text: description
              link
              inner_link
            }
          }
        }
        options_es_ES {
          politics {
            title
            policies {
              text: description
              link
              inner_link
            }
          }
        }
      }
    }
  `);

  switch (lang) {
    case 'en_US':
      return data.wordpressAcfOptions.options_en_US.politics;
    case 'es_ES':
      return data.wordpressAcfOptions.options_es_ES.politics;
    // case 'it_IT':
    //   return data.wordpressAcfOptions.options_it_IT.politics;
    default:
      return data.wordpressAcfOptions.options_pt_BR.politics;
  }
};
