import { apiHost, createCart } from '@utils/constants';
import useRequest from '@utils/useRequest';

/**
 * Create checkout
 *
 * @param {number} proposalId The proposal ID
 */
async function createCheckout(typeId, data, currency = 'BRL') {
  let postData;

  if (!Array.isArray(data)) {
    postData = {
      cart: {
        currency,
        products: [
          {
            action: 'add',
            typeId,
            data,
          },
        ],
      },
    };
  } else {
    postData = {
      cart: {
        currency,
        products: data,
      },
    };
  }

  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${createCart}`,
      method: 'post',
      useSession: false,
      data: postData,
    })
      .then(({ data }) => {
        resolve(data.cart.token);
      })
      .catch(err => {
        reject('Houve um erro ao criar o checkout');
      });
  });
}

export default createCheckout;
