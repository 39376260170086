// Third party libs/components
import React, { Fragment, useState, useCallback } from 'react';

// Components
import * as Styled from './Aircraft.style';
import TabSection from '@components/TabSection';
import CheckboxList from '@components/CheckboxList';
import ConfirmButton from '@components/ConfirmButton';
import AircraftPrice from './AircraftPrice';

// Utils
import { translate as t } from '@utils/translate';

const Aircraft = ({ data, onSubmit, closeFilter }) => {
  const TEXTS = {
    CATEGORY_TITLE: t('category'),
    MODEL_TITLE: t('model'),
  };
  const [prices, setPrices] = useState(() => {
    return {
      airplanes: {
        ...data.airplanes.price,
      },
      helicopters: {
        ...data.helicopters.price,
      },
    };
  });
  const [lists, setLists] = useState({
    airplanes: {
      categoriesList: [...data.airplanes.categoriesList],
      modelsList: [...data.airplanes.modelsList],
    },
    helicopters: {
      categoriesList: [...data.helicopters.categoriesList],
      modelsList: [...data.helicopters.modelsList],
    },
  });

  const handleSubmit = () => {
    const newData = {
      airplanes: {
        price: {
          ...prices['airplanes'],
        },
        ...lists['airplanes'],
      },
      helicopters: {
        price: {
          ...prices['helicopters'],
        },
        ...lists['helicopters'],
      },
    };
    onSubmit(newData);
    closeFilter();
  };

  const renderPriceComponent = ({ type, values }) => {
    return (
      <Styled.YearPriceContainer>
        {/*<AircraftYear {...aircraftYear} />*/}
        {type === 'airplane' ? (
          <AircraftPrice
            {...values}
            onChange={values => handleSetPrice('airplanes', values)}
          />
        ) : (
          <AircraftPrice
            {...values}
            onChange={values => handleSetPrice('helicopters', values)}
          />
        )}
      </Styled.YearPriceContainer>
    );
  };

  const handleSelect = useCallback(
    (targetGroup, targetList, index, selected) => {
      let updatedList = [...lists[targetGroup][targetList]];

      updatedList[index] = { ...updatedList[index], selected };

      setLists({
        ...lists,
        [targetGroup]: {
          ...lists[targetGroup],
          [targetList]: updatedList,
        },
      });
    }
  );

  const handleSelectAll = useCallback((targetGroup, targetList, selected) => {
    let updatedList = [...lists[targetGroup][targetList]];

    updatedList = updatedList.map(item => {
      return { ...item, selected };
    });

    setLists({
      ...lists,
      [targetGroup]: {
        ...lists[targetGroup],
        [targetList]: updatedList,
      },
    });
  });

  const handleSetPrice = useCallback((target, values) => {
    setPrices(prevState => {
      return {
        ...prevState,
        [target]: { ...prevState[target], value: { ...values } },
      };
    });
  });

  const helicopterTab = (
    <Fragment>
      {data.helicopters.categoriesList && data.helicopters.categoriesList.length
        ? renderPriceComponent({
            type: 'helicopter',
            values: prices.helicopters,
          })
        : null}
      <Styled.OptionsContainer>
        {lists.helicopters.categoriesList &&
        lists.helicopters.categoriesList.length ? (
          <CheckboxList
            title={TEXTS.CATEGORY_TITLE}
            items={lists.helicopters.categoriesList}
            onClick={(index, checked) =>
              handleSelect('helicopters', 'categoriesList', index, checked)
            }
            onToggleCheckAll={checked =>
              handleSelectAll('helicopters', 'categoriesList', checked)
            }
          />
        ) : null}
        {lists.helicopters.modelsList && lists.helicopters.modelsList.length ? (
          <CheckboxList
            title={TEXTS.MODEL_TITLE}
            items={lists.helicopters.modelsList}
            onClick={(index, checked) =>
              handleSelect('helicopters', 'modelsList', index, checked)
            }
            onToggleCheckAll={checked =>
              handleSelectAll('helicopters', 'modelsList', checked)
            }
          />
        ) : null}
      </Styled.OptionsContainer>
    </Fragment>
  );

  const airplaneTab = (
    <Fragment>
      {/*<AircraftType {...aircraftTypes} />*/}
      {lists.airplanes.categoriesList &&
        lists.airplanes.categoriesList.length &&
        renderPriceComponent({
          type: 'airplane',
          values: prices.airplanes,
        })}
      <Styled.OptionsContainer>
        {lists.airplanes.categoriesList &&
        lists.airplanes.categoriesList.length ? (
          <CheckboxList
            title={TEXTS.CATEGORY_TITLE}
            items={lists.airplanes.categoriesList}
            onClick={(index, checked) =>
              handleSelect('airplanes', 'categoriesList', index, checked)
            }
            onToggleCheckAll={checked =>
              handleSelectAll('airplanes', 'categoriesList', checked)
            }
          />
        ) : null}
        {lists.airplanes.modelsList && lists.airplanes.modelsList.length ? (
          <CheckboxList
            title={TEXTS.MODEL_TITLE}
            items={lists.airplanes.modelsList}
            onClick={(index, checked) =>
              handleSelect('airplanes', 'modelsList', index, checked)
            }
            onToggleCheckAll={checked =>
              handleSelectAll('airplanes', 'modelsList', checked)
            }
          />
        ) : null}
      </Styled.OptionsContainer>
    </Fragment>
  );

  const aircraftTabs = [];

  if (lists.helicopters.categoriesList.length) {
    aircraftTabs.push({
      title: t('helicopter'),
      content: helicopterTab,
    });
  }

  if (lists.airplanes.categoriesList.length) {
    aircraftTabs.push({
      title: t('airplane'),
      content: airplaneTab,
    });
  }

  if (!aircraftTabs.length) return null;

  return (
    <Styled.Aircraft>
      <TabSection headerClassName={'tabsHeader'} sections={aircraftTabs} />
      <ConfirmButton onClick={handleSubmit} />
    </Styled.Aircraft>
  );
};

export default Aircraft;
