import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const ToogleArrow = ({ color, rotate }) => {
  return (
    <S.ArrowWrap rotate={rotate}>
      <svg
        width="24"
        height="13"
        viewBox="0 0 24 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 1.66663L11.2929 10.9595C11.6834 11.35 12.3166 11.35 12.7071 10.9595L22 1.66662"
          stroke={color}
          stroke-width="3"
          stroke-linecap="round"
        />
      </svg>
    </S.ArrowWrap>
  );
};

ToogleArrow.propTypes = {
  color: PropTypes.string,
  rotate: PropTypes.number,
};

ToogleArrow.defaultProps = {
  color: '#201d20',
};

export default ToogleArrow;
