import styled, { css } from 'styled-components';

export const CardWrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const Card = styled.div`
  width: calc((100vw - var(--container-size)) / 4);
  max-width: 600px;
  padding-right: 20px;
  box-sizing: border-box;
  text-decoration: none;

  @media (min-width: 1024px) {
    margin-right: 16px;

    &:last-of-type {
      margin: 0;
    }
  }

  @media (max-width: 1445px) {
    width: calc((100vw - var(--container-size)) / 3);
  }

  @media (max-width: 1024px) {
    width: calc((100vw - var(--container-size)) / 2);
  }

  @media (max-width: 768px) {
    width: calc((100vw - var(--container-size)));
  }
`;

export const CardImageWrapper = styled.div`
  border-radius: 14px;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 16px;
  height: 260px;
  // border: 2px solid white;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0);
  transition: all 0.3s ease-out;

  ${props =>
    props.isHover
      ? css`
          transform: scale(1.06);
        `
      : css`
          transform: scale(1);
        `}

  ${props =>
    props.isHover
      ? css`
          -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.31);
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.31);
        `
      : css`
          -webkit-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0);
          box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0);
        `}

  @media (min-width: 1024px) {
    height: 250px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    &:after {
      transition: background-image 0.3s ease-out;

      ${props =>
        props.isHover
          ? css`
              background-image: linear-gradient(
                to top,
                #000000,
                rgba(0, 0, 0, 0)
              );
            `
          : css`
              background-image: none;
            `}
    }
  }
`;

export const Image = styled.img`
  z-index: -1;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 14px;
  border-radius: 7px;
  position: absolute;
  object-fit: cover;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 95px;
    width: 100%;
    background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));
  }

  @media (min-width: 1024px) {
    transition: 0.3s ease-out;

    ${props =>
      props.isHover
        ? css`
            transform: scale(1.2);
          `
        : css`
            transform: scale(1);
          `}
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0px;
  width: 100%;
  padding: 5px;
  z-index: 1;

  @media (min-width: 1024px) {
    transition: 0.3s;

    ${props =>
      props.isHover
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `}
  }
`;

export const CardTexts = styled.div`
  position: absolute;
  top: 5px;
  right: 25px;
  z-index: 2;

  @media (min-width: 1024px) {
    transition: 0.3s;

    ${props =>
      props.isHover
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `}
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  min-width: 130px;
  height: 100%;
  min-height: 60px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 1024px) {
    margin-left: 10px;
  }
`;

export const PriceLabel = styled.strong`
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  z-index: 2;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const PriceValue = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  z-index: 2;
  margin: 0;
`;

export const Subtitle = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: #009788;
  text-align: center;
  margin-bottom: 0;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const SubtitleHidden = styled.p`
  display: none;

  > span {
    margin-top: 10px;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 0;
    color: #ffffff;
    z-index: 1;
    font-size: 14px;
    font-weight: 500;
    padding-left: 15px;
    box-sizing: border-box;

    transition: 0.3s;

    ${props =>
      props.isHover
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `}
  }
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #2c2c2c;
  margin-bottom: 0;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const Year = styled.span`
  margin-left: 10px;
`;
