import styled from 'styled-components';
import Slider from 'react-slick';

export const CustomSlider = styled(Slider)`
  .slick-slider {
    margin-bottom: 12px;
  }

  .slick-track {
    padding: 20px 0;
  }

  .slick-arrow {
    top: 50%;
  }

  .slick-prev {
    left: 0;
    transform: translate(calc(-100% - 16px), -50%);
  }

  .slick-next {
    right: 0;
    transform: translate(calc(100% + 16px), -50%);
  }

  .slick-slide {
    text-align: center;
    transition: opacity 1s ease-out;
  }

  @media (max-width: 1023px) {
    .slick-track {
      padding: 10px 0;
    }

    .slick-arrow {
      top: calc(100% + 12px);
      left: unset;
      right: 0;
    }

    .slick-prev {
      transform: translateX(calc(-100% - 16px));
    }

    .slick-next {
      transform: none;
    }

    .slick-slide {
      padding: 0;
    }
  }

  @media (max-width: 660px) {
    .slick-slide {
      padding: 0 8px;
    }
  }
`;

export const FlightCardContainer = styled.div``;
