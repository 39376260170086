// Third party libs/components
import React from 'react';
import { DateRange } from 'react-date-range';

// Components
import * as Styled from './ReturnSelectionStep.style';
import ConfirmButton from '@components/ConfirmButton';

// Utils
import { translate as t } from '@utils/translate';
import { dateToString } from '@utils/dates';

// Assets
import Style from './datepicker-style.module.css';

const ReturnSelectionStep = ({
  settings,
  ranges,
  origin,
  destination,
  departureDate,
  newReturnDate,
  currentLang,
  onSetReturnDate,
  onSetDateRange,
  onSubmit,
  onReturnStep,
}) => {
  const today = new Date();
  const TEXTS = {
    ROUND_TRIP_TITLE: t('Ida e volta'),
  };

  const setDateRange = selection => {
    onSetDateRange(selection);
    const { startDate, endDate } = selection[0];

    if (dateToString(startDate) === dateToString(endDate)) {
      onSetReturnDate(null);
    } else {
      onSetReturnDate(endDate);
    }
  };

  return (
    <div className={Style.returnSelectionWrapper}>
      <Styled.StepContainer>
        <Styled.StepHeader>
          <Styled.BackButton
            onClick={() => onReturnStep(false)}
          ></Styled.BackButton>
          <Styled.Title>{TEXTS.ROUND_TRIP_TITLE}</Styled.Title>
        </Styled.StepHeader>
        <Styled.TripInfos>
          <Styled.TripPlaces>
            <Styled.Origin>{origin}</Styled.Origin>
            <Styled.Destination>{destination}</Styled.Destination>
          </Styled.TripPlaces>
          <Styled.TripDates hasReturn={!!newReturnDate}>
            <Styled.DepartureInput
              readOnly
              type="text"
              value={dateToString(departureDate)}
            />
            <Styled.ReturnInput
              readOnly
              type="text"
              value={newReturnDate ? dateToString(newReturnDate) : ''}
            />
          </Styled.TripDates>
          <DateRange
            ranges={ranges}
            locale={settings.localeLangsMap[currentLang]}
            onChange={item => setDateRange([item.selection])}
            minDate={today}
            {...settings.datePickerSettings}
          />
          {newReturnDate && (
            <Styled.ConfirmButtonContainer>
              <ConfirmButton onClick={() => onSubmit()} />
            </Styled.ConfirmButtonContainer>
          )}
        </Styled.TripInfos>
      </Styled.StepContainer>
    </div>
  );
};

export default ReturnSelectionStep;
