// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import ArrowIcon from '@images/arrowIcon.svg';

const ArrowButton = ({
  text,
  type = 'next',
  className,
  onClick,
  /** React stick overwrites the className property T.T */
  _className,
}) => {
  return (
    <button
      className={[
        Style.arrowButton,
        className,
        _className,
        type == 'next' ? Style.next : Style.prev,
      ].join(' ')}
      onClick={onClick}
    >
      <span>{text}</span>{' '}
      <img src={ArrowIcon} className={Style.arrowIcon} alt={text} />
    </button>
  );
};

export default ArrowButton;
