import styled, { css } from 'styled-components';
import { COLORS } from '@utils/styles/colors';

export const Container = styled.div`
  cursor: initial;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: auto;
  width: 100%;
  overflow: hidden;
  background-color: ${COLORS.WHITE};
  color: ${COLORS.PERSIAN_GREEN};
  border-top: 1px solid rgba(0, 150, 136, 0.3);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0px 10px 10px 10px;
  min-height: 50px;
  width: 100%;
  margin-top: 10px;
`;

export const Buttons = styled.div`
  button {
    cursor: pointer;
    height: 45px;
    width: 45px;
    border: 0;
    font-size: 1.3rem;
    transition: all ease 0.2s;
    color: ${COLORS.WHITE};
    background-color: ${COLORS.PERSIAN_GREEN};

    &:first-of-type {
      border-radius: 5px 0 0 5px;
    }

    &:last-of-type {
      border-radius: 0 5px 5px 0;
    }

    &:enabled {
      &:hover {
        background-color: ${COLORS.PERSIAN_GREEN_LIGHT};
      }
    }

    &:disabled {
      opacity: 0.4;
      background-color: ${COLORS.PERSIAN_GREEN};
      box-shadow: none;
    }
  }
`;

export const Span = styled.span`
  width: auto;
  font-size: 1.3rem;
  padding: 10px;
  white-space: nowrap;
`;
