import React, { useState } from 'react';
import P from 'prop-types';
import * as Styled from './CreditCardList.styles.js';
import { useStore } from 'react-redux';
import { FaPlus } from 'react-icons/fa';
import { deletePaymentMethod } from '@store/reducers/cart/operations';

// Components
import CreditCardItem from '@components/CreditCardItem';

// Utils
import { translate as t } from '@utils/translate';
import Dialog from '@components/Dialog';
import ConfirmDelete from '@components/CreditCardSelector/ConfirmDelete';
import debounce from '@utils/debounce';

function CreditCardList({ onAddNewCard }) {
  const TEXTS = {
    ADD_NEW_CARD: t('addNewCard'),
    DELETE: t('delete'),
    CARD_DELETED: t('cardDeleted'),
    DELETING: t('deleting'),
  };

  const store = useStore();

  const state = store.getState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(TEXTS.DELETE);
  const [cardId, setCardId] = useState(null);

  const paymentMethods = state.cart.paymentMethods;

  function handleDelete() {
    setLoading(true);
    setLabel(TEXTS.DELETING + '...');
    debounce(() => {
      try {
        store.dispatch(deletePaymentMethod(cardId));
      } finally {
        setLabel(TEXTS.CARD_DELETED);
        setTimeout(() => {
          setLoading(false);
          setShow(false);
        }, 600);
      }
    }, 2000);
  }

  return (
    <Styled.Container>
      <Styled.NewCard onClick={onAddNewCard} data-test="cardlist.addNewCard">
        {TEXTS.ADD_NEW_CARD} &nbsp;
        <FaPlus />
      </Styled.NewCard>
      {paymentMethods.map(paymentMethod => {
        return (
          <CreditCardItem
            paymentMethod={paymentMethod}
            onDelete={id => {
              setShow(true);
              setCardId(id);
            }}
            key={paymentMethod.card.id}
          />
        );
      })}

      {show && (
        <Dialog open={show} setOpen={setShow}>
          <ConfirmDelete
            onCancel={() => setShow(false)}
            onConfirm={handleDelete}
            loading={loading}
            currentLabel={label}
          />
        </Dialog>
      )}
    </Styled.Container>
  );
}

CreditCardList.propTypes = {
  onAddNewCard: P.func,
};

export default CreditCardList;
