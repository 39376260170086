import styled from 'styled-components';

export const StepContainer = styled.div`
  padding: 18px 14px;
`;

export const StepHeader = styled.div`
  position: relative;
  margin-bottom: 24px;
  text-align: center;
`;

export const BackButton = styled.div`
  cursor: pointer;
  margin-left: 0;
  position: absolute;

  &:before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;'%3E%3Cg%3E%3Cpolygon points='512,236.011 76.526,236.011 248.43,64.106 220.162,35.838 0,256 220.162,476.162 248.43,447.894 76.526,275.989 512,275.989'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    vertical-align: sub;
    margin-right: 12px;
    position: relative;
    left: 0;
    transition: left 0.1s ease-out;
  }
`;

export const Title = styled.div``;

export const TripInfos = styled.div``;

export const TripPlaces = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 14px;
`;

export const Origin = styled.div`
  flex-basis: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #313131;
  border: 2px solid #00bfa5;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  border-right-width: 1px;
  padding: 19px 13px 17px;
`;

export const Destination = styled.div`
  flex-basis: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #313131;
  border: 2px solid #00bfa5;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  border-left-width: 1px;
  padding: 19px 13px 17px;
`;

export const TripDates = styled.div`
  display: flex;
`;

export const DepartureInput = styled.div`
  flex-basis: 50%;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  color: #b0b0bc;
  text-align: center;
  border: 2px solid #ecedf2;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  border-right-width: 1px;
  padding: 18px 13px 16px;

  ${({ hasReturn }) => {
    if (hasReturn) {
      return css`
        border-color: #00bfa5;
        color: #313131;
      `;
    }
  }}
`;

export const ReturnInput = styled.div`
  flex-basis: 50%;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  color: #b0b0bc;
  text-align: center;
  border: 2px solid #ecedf2;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  border-left-width: 1px;
  padding: 18px 13px 16px;

  ${({ hasReturn }) => {
    if (hasReturn) {
      return css`
        border-color: #00bfa5;
        color: #313131;
      `;
    }
  }}
`;

export const ConfirmButtonContainer = styled.div``;
