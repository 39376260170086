import { getFlightTypeRoute } from '@utils/search';
import store from '@store/../state/store';
import useRequest from '@utils/useRequest';
import { apiHost } from '@utils/constants';

/**
 * Returns a Request<useRequest> for shared flights
 *
 * @param {object} params Params to query string
 */
const fetchSharedFlights = (params, statsOnly) => {
  const range = [];
  let eve = '';

  if (typeof window === 'object') {
    const searchParams = new URLSearchParams(window.location.search);
    eve = searchParams.get('eve') == '1' ? 'onlyEveFlights=1&' : '';
  }

  if (params.minDatetime) range.push(`minDatetime=${params.minDatetime}`);
  if (params.maxDatetime) range.push(`maxDatetime=${params.maxDatetime}`);

  let url = getFlightTypeRoute('flights');
  if (statsOnly) url += '/stats';

  const state = store.getState();
  const currency = state.general.currency;
  const queryString =
    '?' +
    eve +
    // (params.returnDate !== '-' ? "excludeEmptyLeg=1&" : '') +
    (currency ? `currency=${currency.code}&` : '') +
    Object.keys(params)
      .reduce(
        (result, param) => {
          let str = '';
          switch (param) {
            case 'origin':
              str =
                params[param].type === 's'
                  ? `originSpotId=${params[param].id}`
                  : `originCityId=${params[param].id}`;
              break;

            case 'destination':
              str =
                params[param].type === 's'
                  ? `destinationSpotId=${params[param].id}`
                  : `destinationCityId=${params[param].id}`;
              break;

            default:
              break;
          }

          if (str) result.push(str);

          return result;
        },
        [...range]
      )
      .join('&');

  return useRequest({
    url: url + queryString,
    mockup: null,
    method: 'get',
    useSession: false,
    context: 'quote-search',
  });
};

/**
 * Returns a Request<useRequest> for chartered flights
 *
 * @param {object} requestBody Request body for post
 */
const fetchCharteredFlights = requestBody => {
  const url = getFlightTypeRoute('charter');

  return useRequest({
    url: url,
    mockup: null,
    method: 'post',
    useSession: false,
    client: 'Web App;4.4.0',
    data: requestBody,
  });
};

/**
 * @return a Request<useRequest> for aircrafts to sale
 * @param currency optional parameter, value: USD, EUR...
 */
const fetchAircraftsToSale = (selectedGroupBy, currency) => {
  const url =
    selectedGroupBy === 'producers'
      ? `${apiHost}/api/v1/aircraft/aircraftsales/get?onlyMinPrices=1&groupBy=producer`
      : `${apiHost}/api/v1/aircraft/aircraftsales/get?onlyMinPrices=1&groupBy=size`;

  return useRequest({
    url: url,
    mockup: null,
    method: 'get',
    useSession: false,
    context: 'sales-search',
  });
};

export { fetchCharteredFlights, fetchSharedFlights, fetchAircraftsToSale };
