import { formatDateString, dateToString } from '@utils/dates';
import {
  formatResponseForSearchResults,
  reduceLocationParams,
  decodeParams,
} from '@utils/search';
import { setQuotationsData } from '@store/reducers/quotations/operations';

/**
 * Returns an object with search header information
 *
 * @param {object} routeParams
 * @param {string} path
 */
const getHeaderPropsForMultipleRoutes = (routeParams, path) => {
  return {
    translations: [
      { path: path, polylang_current_lang: 'en_US' },
      { path: path, polylang_current_lang: 'es_ES' },
      { path: path, polylang_current_lang: 'pt_BR' },
      // { path: path, polylang_current_lang: 'it_IT' },
    ],
    origin: {
      name: routeParams.origin.name,
      code: routeParams.origin.code,
    },
    destination: {
      name: routeParams.destination.name,
      code: routeParams.destination.code,
    },
    departureDate: new Date(
      formatDateString(routeParams.departureDate, routeParams.departureTime)
    ),
    returnDate:
      routeParams.returnDate.split('-')[0].length > 0
        ? new Date(
            formatDateString(routeParams.returnDate, routeParams.returnTime)
          )
        : null,
  };
};

/**
 * Returns an object with the params prepared to search's request
 *
 * @param {object} routeParams
 */
const prepareSearchParams = (routeParams, spotIds = {}) => {
  const segmentsParams = {
    originType: routeParams.origin.type,
    originId: routeParams.origin.id,
    destinationType: routeParams.destination.type,
    destinationId: routeParams.destination.id,
    departureDate: routeParams.departureDate,
    departureTime: routeParams.departureTime,
    returnDate: routeParams.returnDate,
    returnTime: routeParams.returnTime,
  };

  function getSpotId(spotId, segmentType, segmentId) {
    if (spotId) return String(spotId);

    return segmentType === 's' ? segmentId : null;
  }

  const departureOrigin = {
    spotId: getSpotId(
      spotIds['originSpotId'],
      segmentsParams.originType,
      segmentsParams.originId
    ),
    cityId: segmentsParams.originType === 'c' ? segmentsParams.originId : null,
  };
  const departureDestination = {
    spotId: getSpotId(
      spotIds['destinationSpotId'],
      segmentsParams.destinationType,
      segmentsParams.destinationId
    ),
    cityId:
      segmentsParams.destinationType === 'c'
        ? segmentsParams.destinationId
        : null,
  };
  const departureSegment = {
    origin: departureOrigin,
    destination: departureDestination,
    departureDatetime: formatDateString(
      segmentsParams.departureDate,
      segmentsParams.departureTime
    ),
    returnDatetime:
      segmentsParams.returnDate !== '-'
        ? formatDateString(segmentsParams.returnDate, segmentsParams.returnTime)
        : null,
    adults: routeParams.adults,
    children: routeParams.children,
  };

  return departureSegment;
};

/**
 * Returns min and max dates for search
 *
 * @param {object} routeParams
 */
const formatMinMaxDates = ({
  departureDate,
  departureTime,
  returnDate,
  returnTime,
  maxFlightDatetime,
}) => {
  const range = {
    minDatetime: null,
  };

  if (departureDate !== '-') {
    range.minDatetime = formatDateString(departureDate, departureTime);
  }

  return range;
};

/**
 * Returns the min or max seat price
 *
 * @param {array} seats
 * @param {string} edge (min, max)
 */
const getSeatPrice = (seats = [], edge = 'min') => {
  let value = 0;

  if (edge === 'min') {
    seats.forEach(seat => {
      let price = parseInt(seat.price);
      if (price > value) value = price;
    });
  } else {
    value = 9999999999999;
    seats.forEach(seat => {
      let price = parseInt(seat.price);
      if (price < value) value = price;
    });
  }

  return value;
};

export {
  getHeaderPropsForMultipleRoutes,
  prepareSearchParams,
  formatMinMaxDates,
  getSeatPrice,
  // external helpers
  setQuotationsData,
  formatResponseForSearchResults,
  reduceLocationParams,
  decodeParams,
};
