// TODO: REFACTORING
import React, { useState, cloneElement, useRef, useEffect } from 'react';
import Style from './style.module.css';
import useOutsideClick from '@utils/useOutsideClick';

const SearchFilter = ({
  label,
  rightSide,
  children,
  disabled,
  closeOnOutsideClick = true,
}) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    if (closeOnOutsideClick) setOpened(false);
  });

  const openSearchFilter = () => {
    setOpened(!opened);
  };

  return (
    <div
      className={[
        disabled || !children ? Style.disabled : '',
        Style.searchFilterWrapper,
        opened && children ? Style.opened : '',
      ].join(' ')}
      ref={ref}
    >
      <span
        onClick={openSearchFilter}
        className={[Style.searchFilter].join(' ')}
      >
        {label}
      </span>
      {children && (
        <div
          className={[Style.fitlerBody, rightSide ? Style.rightSide : ''].join(
            ' '
          )}
        >
          {cloneElement(children, {
            closeFilter: () => setOpened(false),
          })}
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
