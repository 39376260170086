// Third party libs/components
import React, { useState } from 'react';

// Components
import * as Styled from './Passengers.style';
import ConfirmButton from '@components/ConfirmButton';
import PassengersCountSelector from '@components/PassengersCountSelector';

const Passengers = ({ data, onSubmit, closeFilter }) => {
  const [values, setValues] = useState({
    ...data,
  });

  const handleChange = value => {
    setValues(prevState => {
      return {
        ...prevState,
        numberOfSeats: value,
      };
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    onSubmit(values);

    closeFilter();
  };

  return (
    <Styled.PassengersWrapper>
      <PassengersCountSelector
        onIncrement={handleChange}
        onDecrement={handleChange}
        initialValue={values.numberOfSeats}
        theme={'default'}
        minPassengers={values.minSeatsAvailable}
        maxPassengers={values.maxSeatsAvailable}
        /* showMaxPassengers={true}
        showTitle={true} */
      />
      <Styled.ButtonWrapper>
        <ConfirmButton onClick={handleSubmit} />
      </Styled.ButtonWrapper>
    </Styled.PassengersWrapper>
  );
};

export default Passengers;
