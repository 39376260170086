import styled, { css } from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';

const desktop = {
  main: css`
    margin-top: var(--search-results-header-height);
    padding-bottom: 0px;
    padding-left: var(--container-size);
    padding-right: var(--container-size);
    transition: 'background 10s ease-in-out';
    background: ${ifProp(
        'itemSelected',
        'linear-gradient(180deg, rgba(215,215,215,1) 0%, rgba(215,215,215,1) 50%, rgba(215,215,215,0) 100%)',
        'transparent'
      )}
      ${ifProp(
        'itemSelected',
        css`
          &:after {
            content: '';
            background-color: white;
            height: 350px;
            left: 0;
            position: absolute;
            top: var(--search-results-header-height);
            width: 100vw;
            z-index: 0;
          }
        `
      )};
  `,
  header: css`
    background-color: white;
    margin: auto;
    max-width: 1140px;
    min-height: 100px;
    padding: 32px 17.5% 30px 24px;
    position: relative;
    z-index: 2;

    h1 {
      font-size: 24px;
      margin: 0;
      padding: 0;
    }

    h2 {
      font-size: 20px;
      font-weight: 400;
      padding: 10px 0 0;
      color: #666;
    }

    .step-info {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 30px;
      justify-content: space-between;

      &__out,
      &__return {
        display: flex;
        align-items: center;

        &__step {
          position: relative;
          top: 2px;
          color: #009688;
          font-weight: 700;
          font-size: 24px;

          &.isActive {
            color: black;
          }
        }

        &__confirmed {
          align-items: center;
          display: flex;
          color: #009688;
          margin-left: 15px;

          * {
            font-style: normal;
          }

          i {
            position: relative;
            top: 4px;
            font-size: 20px;
            font-weight: 500px;
          }

          svg {
            height: 35px;
            width: auto;
          }
        }

        &__edit {
          display: flex;
          align-items: center;
          background: transparent;
          border: none;
          cursor: pointer;
          margin-left: 10px;
          color: #666;

          * {
            font-style: normal;
          }

          i {
            position: relative;
            top: 4px;
            font-size: 20px;
            font-weight: 500px;
            margin-left: 3px;
          }

          svg {
            height: 25px;
            width: auto;
          }
        }
      }
    }
  `,
  list: css`
    position: relative;
    z-index: 1;
    transition: all 0.4s ease-out;
    top: 0;
  `,
};

const mobile = {
  main: css`
    margin: 0;
    padding: 0;
    margin-top: var(--search-results-header-height);
  `,
  header: css`
    background-color: transparent;
    margin: auto;
    padding: 30px 20px;
    position: relative;
    z-index: 2;

    h1 {
      font-size: 20px;
      margin: 0;
      padding: 0;
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      padding: 10px 0 0;
      color: #666;
    }

    .step-info {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 30px;
      justify-content: space-between;
      position: relative;

      &__return {
        display: none;
      }

      &__out {
        display: flex;
        align-items: center;
        width: 100%;

        &__step {
          position: relative;
          top: 2px;
          color: #009688;
          font-weight: 700;
          font-size: 20px;
        }

        &__confirmed {
          align-items: center;
          display: flex;
          color: #009688;
          margin-left: 15px;

          * {
            font-style: normal;
          }

          i {
            position: relative;
            top: 4px;
            font-size: 16px;
            font-weight: 500px;
          }

          svg {
            height: 30px;
            width: auto;
          }
        }

        &__edit {
          display: flex;
          align-items: center;
          background: transparent;
          border: none;
          cursor: pointer;
          margin-left: 10px;
          color: #666;
          position: absolute;
          right: 0;

          * {
            font-style: normal;
          }

          i {
            position: relative;
            top: 4px;
            font-size: 16px;
            font-weight: 500px;
            margin-left: 3px;
          }

          svg {
            height: 21px;
            width: auto;
          }
        }
      }
    }
  `,
  list: css`
    position: relative;
    z-index: 1;
    transition: all 0.4s ease-out;
    padding: 0 15px;
    top: 0;

    ${ifProp(
      'shouldShrink',
      css`
        height: ${({ shouldShrink }) => shouldShrink + 40 + 'px'};
      `
    )}
  `,
};

const Main = styled.main`
  ${ifNotProp('isMobile', desktop.main, mobile.main)}
`;

const Header = styled.header`
  ${ifNotProp('isMobile', desktop.header, mobile.header)}
`;

const FLightsList = styled.div`
  ${ifNotProp('isMobile', desktop.list, mobile.list)}
`;

export { Main, Header, FLightsList };
