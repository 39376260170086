import React, { useState, useEffect } from 'react';
import { connect, useStore } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import PathSection from '../../components/PathSection';
import { useInView } from 'react-intersection-observer';
import TripDetailNewPath from '../../components/TripDetailNewPath';
import hero from './mocks/hero';
import { Creators as SearchSegmentsActions } from '@store/reducers/search-segments/reducer';
import Style from './style.module.css';

// Utils
import * as Helpers from '@components/../app/Search/utils/Search.helper';
import { decodeObjectToken } from '@utils/token';
import { flightSearchSecret } from '@utils/secrets';

const TripDetail = ({ resetSegments, location }) => {
  const path = location.pathname;
  const store = useStore();
  const [openModal, setOpenModal] = useState(false); //controls the opening of the login modal
  const [hideLoginBackButton, setHideLoginBackButton] = useState(true);
  const [newPathRef, newPathInView] = useInView({ threshold: 1 });
  const [quotation, setQuotation] = useState(null);
  const [optionalLogin, setOptionalLogin] = useState(false);
  const [openUserDecision, setOpenUserDecision] = useState(false);

  const handleOptionalLogin = state => {
    setOptionalLogin(state);
  };

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('st');
    const segments = decodeObjectToken(token, flightSearchSecret);
    store.dispatch(Helpers.setQuotationsData([...segments]));

    if (segments) {
      if (segments[0] && segments[0]['routes']) {
        const routes = segments[0]['routes'];
        const data = {
          visibility: newPathInView,
          link: '/',
          routes: [...routes],
        };
        setQuotation(data);
      }
    }
  }, [path]);

  const translations = [
    { path: path, polylang_current_lang: 'en_US' },
    { path: path, polylang_current_lang: 'es_ES' },
    { path: path, polylang_current_lang: 'pt_BR' },
    // { path: path, polylang_current_lang: 'it_IT' },
  ];
  return (
    quotation && (
      <Layout
        solidHeader="white"
        translations={translations}
        showFooter={false}
        onOpenLogin={openModal}
        onLoginBack={({ step }) => {
          if (step === 0) {
            setOpenModal(false);
            handleOptionalLogin(false);
            setOpenUserDecision(true);
          }
        }}
        closeLogin={() => {
          setOpenModal(false);
          handleOptionalLogin(false);
          setHideLoginBackButton(true);
        }}
        hideLoginBackButton={hideLoginBackButton}
        optionalLogin={optionalLogin}
      >
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <PathSection
          {...quotation}
          onOpenLogin={() => {
            setHideLoginBackButton(false);
            setOpenModal(true);
          }}
          onOptionalLogin={handleOptionalLogin}
          openUserDecision={openUserDecision}
          setOpenUserDecision={value => {
            if (!value) {
              setHideLoginBackButton(true);
            }
            setOpenUserDecision(value);
          }}
        />
        <div ref={newPathRef} className={Style.newPathHero}>
          {hero ? <TripDetailNewPath {...hero} /> : null}
        </div>
      </Layout>
    )
  );
};

const mapStateToProps = state => ({
  fleet: state.fleet,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(SearchSegmentsActions, dispatch);

export default connect(null, mapDispatchToProps)(TripDetail);
