// Third party libs/components
import React, { useState } from 'react';
import { Link } from 'gatsby';
import P from 'prop-types';

// Components
import * as Styled from './SearchSalesResultsHeader.style';
import LoginModal from '@components/LoginModal';
import BackButton from '@components/BackButton';
import MenuModal from '@components/MenuModal';

// Utils
import * as Helpers from './utils/SearchSalesResultsHeader.helper';

// Assets
const menuIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="15" viewBox="0 0 24 9"><path fill="#fff" d="M12 3h-12v-3h24v3zm0 3h-6v3h18v-3zm0"/></svg>`;

const SearchSalesResultsHeader = ({ translations }) => {
  const pageLang = Helpers.useCurrentLang();
  const query = Helpers.headerQuery();
  const [loginOpened, setLogin] = useState(false);
  const [menuOpened, setMenu] = useState(false);

  const allHeaderData = {
    pt_BR: query.wordpressAcfOptions.options_pt_BR,
    en_US: query.wordpressAcfOptions.options_en_US,
    es_ES: query.wordpressAcfOptions.options_es_ES,
    // it_IT: query.wordpressAcfOptions.options_it_IT,
  };
  const headerData = allHeaderData[pageLang];

  const queryLanguages = headerData.header_languages;
  const loginContent = headerData.header_login;
  const menuItems = Helpers.mountMenuItems({ query, pageLang });

  return (
    <Styled.SearchSalesResultsHeaderWrapper menuOpened={menuOpened}>
      <Styled.SearchSalesResultsHeaderContainer>
        <Styled.Top>
          <Styled.BackButtonContainer>
            <BackButton text={Helpers.t('back')} />
          </Styled.BackButtonContainer>

          <Styled.LogoWrapper>
            <Link to="/">
              <Styled.Logo
                dangerouslySetInnerHTML={{
                  __html: headerData.desktop_logo_svg,
                }}
              />
            </Link>
          </Styled.LogoWrapper>

          <Styled.OpenMenuModal
            onClick={() => setMenu(true)}
            dangerouslySetInnerHTML={{ __html: menuIcon }}
          />
        </Styled.Top>

        {menuOpened && (
          <MenuModal
            translations={translations}
            queryLanguages={queryLanguages}
            loginContent={loginContent}
            openLogin={() => setLogin(true)}
            menuItems={menuItems}
            closeMenu={() => setMenu(false)}
            blockLocaleOptions={{
              currency: true,
              language: false,
            }}
          />
        )}
        {loginOpened && (
          <LoginModal
            loginImage={loginContent.login_modal_image}
            forgotPasswordMsg={loginContent.forgot_password_msg}
            logo={loginContent.login_modal_logo_svg}
            opened={loginOpened}
            closeModal={() => setLogin(false)}
          />
        )}
      </Styled.SearchSalesResultsHeaderContainer>
    </Styled.SearchSalesResultsHeaderWrapper>
  );
};

SearchSalesResultsHeader.propTypes = {
  translations: P.array,
};

export default SearchSalesResultsHeader;
