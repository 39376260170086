import React, { useState } from 'react';
import P from 'prop-types';

import { Arrow } from './atoms';

import * as S from './styled';

const TextToggle = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  return (
    <S.Container>
      <S.Wrap>
        <div className="dropdown-flex-title container" onClick={handleToggle}>
          <span className="dropdown-flex-title left">{title}</span>
          <S.IconWrap isOpen={isOpen}>
            <Arrow rotate={isOpen ? 180 : 0} />
          </S.IconWrap>
        </div>
      </S.Wrap>
      <S.Content isOpen={isOpen}>
        <div>{children}</div>
      </S.Content>
    </S.Container>
  );
};

TextToggle.propTypes = {
  title: P.string,
  children: P.node,
};

export default TextToggle;
