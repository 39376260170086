// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';

const Instruction = ({ number, text, subtitle, description, image }) => {
  const [opened, setOpened] = useState(false);
  return (
    <div className={[Style.instruction, opened ? Style.opened : ''].join(' ')}>
      <div
        onClick={() => setOpened(!opened)}
        className={Style.instructionHeader}
      >
        <div className={Style.number}>{number}</div>
        <div className={Style.text}>{text}</div>
      </div>
      <div className={Style.content}>
        <div className={Style.subtitle}>{subtitle}</div>
        {image && <img className={Style.image} src={image} alt={subtitle} />}
        <div className={Style.description}>{description}</div>
      </div>
    </div>
  );
};

const InstructionGroup = ({
  title,
  airport,
  airportLocation,
  instructions,
  className,
}) => {
  if (!instructions) return null;
  return (
    <div className={className}>
      <div className={Style.title}>{title}</div>
      <div className={Style.airport}>{airport}</div>
      <div className={Style.airportLocation}>{airportLocation}</div>
      <div className={Style.instructionsList}>
        {instructions.map((instruction, index) => (
          <Instruction key={index} number={index + 1} {...instruction} />
        ))}
      </div>
    </div>
  );
};

const FlightInstructions = ({
  sectionTitle,
  boardingInstructions,
  landingInstructions,
}) => {
  return (
    <section className={[Style.flightInstructions, 'container'].join(' ')}>
      <div className={Style.sectionTitle}>{sectionTitle}</div>
      <div className={Style.sectionBody}>
        <InstructionGroup
          className={Style.boardingInstructions}
          {...boardingInstructions}
        />
        <InstructionGroup
          className={Style.landingInstructions}
          {...landingInstructions}
        />
      </div>
    </section>
  );
};

export default FlightInstructions;
