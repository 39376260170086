import React, { useEffect, useState } from 'react';
import P from 'prop-types';
import { useParams } from '@reach/router';
import { useForm } from 'react-hook-form';

// Components
import SEO from '@components/seo';
import LoginInput from '@components/LoginInput';
import SpinningWheel from '@components/SpinningWheel';

// Utils
import { translate as t } from '@utils/translate';
import { handleResetPassword } from '@services/auth.js';

// Styles
import Style from './style.module.css';

const ResetPasswordForm = ({ onSubmitSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { hash } = useParams();

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const TEXTS = {
    INVALID_PASSWORD: t('invalidPassword'),
    SOMETHING_WRONG_MSG: t('somethingWrongMsg'),
    NEW_PASSWORD_MSG: t('newPasswordMsg'),
    NEW_PASSWORD: t('newPassword'),
    SUBMIT_FIELD: t('submitField'),
    CHANGE_PASSWORD: t('changePassword'),
    REQUIRED_FIELD: t('requiredField'),
    MIN_LENGTH: t('minLength'),
  };

  const SCHEMA = {
    password: {
      required: TEXTS.REQUIRED_FIELD,
      minLength: {
        value: 6,
        message: TEXTS.MIN_LENGTH.replace('-MIN-', '6'),
      },
    },
  };

  const onSubmit = data => {
    const { password } = data;

    handleResetPassword(setLoading, setErrorMsg, onSubmitSuccess, {
      password,
      hash,
    });
  };

  return (
    <div>
      <div className={Style.title}>{TEXTS.CHANGE_PASSWORD}</div>
      <div className={Style.message}> {TEXTS.ERROR_MSG}</div>
      <p className={Style.instruction}> {TEXTS.NEW_PASSWORD_MSG} </p>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <LoginInput
          name="password"
          type="password"
          placeholder={TEXTS.NEW_PASSWORD}
          register={register}
          schema={SCHEMA.password}
          errors={errors?.password}
        />
        <div className={Style.submitContainer}>
          {loading ? (
            <SpinningWheel />
          ) : (
            <input
              className={Style.inputSubmit}
              type="submit"
              value={TEXTS.SUBMIT_FIELD}
            />
          )}
        </div>
        <div className={Style.loginModalErrorMsg}>{errorMsg}</div>
      </form>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  onSubmitSuccess: P.func,
};

const ResetPasswordSuccess = ({ redirectTo }) => {
  const TEXTS = {
    SUCESS_MSG: t('sucessMsg'),
    RESET_PASSWORD_SUCCESS: t('resetPasswordSuccess'),
  };

  useEffect(() => {
    if (redirectTo) {
      setTimeout(() => {
        window.location.href = redirectTo;
      }, 500);
    }
  }, [redirectTo]);

  return (
    <div>
      <p className={Style.titleSuccess}> {TEXTS.SUCESS_MSG}</p>
      <p className={Style.message}> {TEXTS.RESET_PASSWORD_SUCCESS}</p>
    </div>
  );
};

ResetPasswordSuccess.propTypes = {
  redirectTo: P.string,
};

const ResetPassword = ({ hash }) => {
  const [step, setStep] = useState(0);
  const [redirectTo, setRedirectTo] = useState('');
  const TEXTS = {
    CHANGE_PASSWORD: t('changePassword'),
  };

  const onSubmitSuccess = redirectToUrl => {
    setRedirectTo(redirectToUrl);
    setStep(1);
  };

  return (
    <div className={Style.container}>
      <SEO yoast_head={'<title>' + TEXTS.CHANGE_PASSWORD + '</title>'} />
      <img className={Style.image} src="/reset-password-image.png" />
      <div className={Style.content}>
        {step == 0 ? (
          <ResetPasswordForm
            onSubmitSuccess={redirectToUrl => onSubmitSuccess(redirectToUrl)}
            hash={hash}
          />
        ) : (
          <ResetPasswordSuccess redirectTo={redirectTo} />
        )}
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  hash: P.string,
};

export default ResetPassword;
