import styled, { css } from 'styled-components';
import { COLORS } from '@utils/styles/colors';

const defaultThemeContainer = css`
  background-color: ${COLORS.WHITE};
  color: ${COLORS.PERSIAN_GREEN};
`;

const primaryThemeContainer = css`
  background-color: ${COLORS.PERSIAN_GREEN};
  color: ${COLORS.WHITE};
`;

const primaryClearThemeContainer = css`
  background-color: 'transparent';
  color: ${COLORS.WHITE};
`;

const defaultThemeButton = css`
  background-color: ${COLORS.PERSIAN_GREEN};
  background-image: radial-gradient(
    ellipse farthest-corner at 45px 45px,
    ${COLORS.PERSIAN_GREEN_LIGHT} 0%,
    rgba(0, 0, 255, 0) 50%,
    ${COLORS.PERSIAN_GREEN} 95%
  );
  color: ${COLORS.WHITE};

  &:enabled {
    &:hover {
      background-color: ${COLORS.PERSIAN_GREEN_LIGHT};
      color: ${COLORS.WHITE};
      box-shadow: 2px 2px 2px #367d76;
    }
  }

  &:disabled {
    opacity: 0.3;
  }
`;

const primaryThemeButton = css`
  background-color: ${COLORS.PERSIAN_GREEN};
  background-image: radial-gradient(
    ellipse farthest-corner at 45px 45px,
    ${COLORS.PERSIAN_GREEN_LIGHT} 0%,
    rgba(0, 0, 255, 0) 50%,
    ${COLORS.PERSIAN_GREEN} 95%
  );
  color: ${COLORS.WHITE};
  box-shadow: 0px 0px 9px #367d76;

  &:enabled {
    &:hover {
      background-color: ${COLORS.PERSIAN_GREEN_LIGHT};
    }
  }

  &:disabled {
    opacity: 0.4;
    background-color: ${COLORS.PERSIAN_GREEN};
    box-shadow: none;
  }
`;

const selectThemeContainer = theme => {
  return {
    default: defaultThemeContainer,
    primary: primaryThemeContainer,
    'primary-clear': primaryClearThemeContainer,
  }[theme || 'default'];
};

const selectThemeButton = theme => {
  return {
    default: defaultThemeButton,
    primary: primaryThemeButton,
    'primary-clear': primaryThemeButton,
  }[theme || 'default'];
};

export const Container = styled.div`
  cursor: initial;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: auto;
  width: 100%;
  overflow: hidden;

  border-radius: 5px;

  ${({ theme }) => selectThemeContainer(theme)};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0px 10px 10px 10px;
  min-height: 50px;
  width: 100%;
  margin-top: 10px;
`;

export const Title = styled.span`
  ${({ theme }) => selectThemeContainer(theme)};

  margin-top: 20px;
  font-weight: 500;
  background-color: transparent;
`;

export const SubTitle = styled.span`
  ${({ theme }) => selectThemeContainer(theme)};

  font-size: 0.8rem;
  font-weight: 500;
  background-color: transparent;
`;

export const Button = styled.button`
  cursor: pointer;
  ${({ size }) => css`
    height: ${size === 'small' ? '40px' : '50px'};
    width: ${size === 'small' ? '40px' : '50px'};
  `}

  border: 0;
  border-radius: 25px;
  font-size: 1.6rem;
  transform: scale(1);

  transition: all ease 0.2s;

  ${({ theme }) => selectThemeButton(theme)};

  &:enabled {
    &:hover {
      transform: scale(1.01);
    }
    &:active {
      transform: scale(0.9);
    }
  }

  @media (max-width: 720px) {
    height: 60px;
    width: 60px;
    border-radius: 30px;
  }
`;

export const Span = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  max-height: 50px;
  width: 50px;

  margin: 0px 15px;
  text-align: center;

  font-size: 1.7rem;

  padding: 10px;
  border-radius: 25px;
`;
