import styled from 'styled-components';

export const Aerodrome = styled.div`
  --lateral-padding: 44px;
  padding: 39px var(--lateral-padding) 24px;
  cursor: auto;

  .tabsHeader {
    padding-bottom: 0;
    margin-bottom: 21px;
  }

  .tabsHeader > div {
    font-size: 16px;
    border-bottom-width: 4px;
    font-weight: 600;
  }

  @media (max-width: 1280px) {
    padding: 16px 0 0;
  }
`;

export const Separator = styled.div`
  height: 6px;
  background-color: #f6f6f9;
  margin: 0 calc(var(--lateral-padding) * -1);
  margin-bottom: 20px;
`;
