import { distinctArrayFromObjectProperty } from '@utils/arrays';

const IFilterOptions = {
  originAirports: [],
  originHelipads: [],
  destinationAirports: [],
  destinationHelipads: [],
};

let filterValues = { ...IFilterOptions };

/**
 * Initializes the filter passing the data to be used as initial values
 *
 * @param {object} data
 */
const initFilterValues = ({ data }) => {
  filterValues = { ...IFilterOptions };
  data.forEach(route => {
    // get origins
    if (route.originSpot['helipad'])
      filterValues.originHelipads.push({
        id: parseInt(route.originSpot.id),
        text: route.originSpot.name,
        selected: true,
      });
    else
      filterValues.originAirports.push({
        id: parseInt(route.originSpot.id),
        text: route.originSpot.name,
        selected: true,
      });

    // get destinations
    if (route.destinationSpot['helipad'])
      filterValues.destinationHelipads.push({
        id: parseInt(route.destinationSpot.id),
        text: route.destinationSpot.name,
        selected: true,
      });
    else
      filterValues.destinationAirports.push({
        id: parseInt(route.destinationSpot.id),
        text: route.destinationSpot.name,
        selected: true,
      });
  });

  for (let list in filterValues) {
    filterValues[list] = distinctArrayFromObjectProperty(
      'id',
      filterValues[list]
    );
  }
};

/**
 * Returns the filter's values
 *
 */
const getFilterValues = () => {
  return filterValues;
};

/**
 * Updates the filter's values
 *
 * @param {any} values
 */
const updateFilterValues = values => {
  filterValues = { ...filterValues, ...values };
};

/**
 * Executes the filter's method
 *
 * @param {object} data
 */
const applyFilter = data => {
  let routes = [...data];
  const originSpots = [
    ...filterValues.originAirports,
    ...filterValues.originHelipads,
  ]
    .filter(spot => spot.selected)
    .map(spot => {
      return spot.id;
    });

  const destinationSpots = [
    ...filterValues.destinationAirports,
    ...filterValues.destinationHelipads,
  ]
    .filter(spot => spot.selected)
    .map(spot => {
      return spot.id;
    });

  routes = routes.filter(route => {
    return (
      originSpots.includes(parseInt(route.originSpot.id)) &&
      destinationSpots.includes(parseInt(route.destinationSpot.id))
    );
  });

  return routes;
};

const filterByAerodrome = {
  initFilterValues,
  getFilterValues,
  updateFilterValues,
  applyFilter,
};

export default filterByAerodrome;
