import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
  max-height: 74px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: 15px;
  max-width: 70px;
`;

const CardNumber = styled.span``;

const IconDelete = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  display: grid;
  place-content: center;
  cursor: pointer;

  &:hover {
    background-color: #009688;
  }

  &:active {
    transform: translateY(5px);
  }
`;

export { Container, Icon, CardNumber, Group, IconDelete };
