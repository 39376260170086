// Third party libs/components
import React from 'react';
import { useSelector } from 'react-redux';

// Components
import * as Styled from './AircraftResults.style';
import AircraftCard from './AircraftCard';

// Utils
import { translate as t } from '@utils/translate';

const AircraftsResults = ({
  aircraftsDetails,
  aircrafts,
  onSelectAircraft,
}) => {
  const TEXTS = {
    LOADING_MESSAGE: t('loadingResults'),
  };
  const currency = useSelector(state => state.general.currency);

  const handleSelectModel = id => {
    const findAircraftById = aircraft => {
      return aircraft.aircraft.id === id;
    };
    const aircraft = aircrafts.find(findAircraftById);
    onSelectAircraft(aircraft);
  };

  return (
    <div>
      {aircraftsDetails !== undefined ? (
        aircrafts.map(({ price, aircraft }) => {
          const item = aircraftsDetails.find(item => item.id == aircraft.id);
          if (item) {
            return (
              <AircraftCard
                {...item}
                key={aircraft.id}
                aircraft={item}
                onClick={id => handleSelectModel(id)}
                price={price}
                currency={currency.code}
              />
            );
          }
        })
      ) : (
        <Styled.LoaderContainer>
          <Styled.MainMessage>{TEXTS.LOADING_MESSAGE}</Styled.MainMessage>
          <SpinningWheelStyled />
        </Styled.LoaderContainer>
      )}
    </div>
  );
};

export default AircraftsResults;
