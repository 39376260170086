import React, { useEffect, useState } from 'react';
import * as Styles from './ProfileNotification.styles.js';

// Components
import InputToggle from '@components/InputToggle';
import Dialog from '@components/Dialog';
import DialogPhoneNumber from '@components/DialogPhoneNumber';

// Utils
import { translate as t } from '@utils/translate';
import debounce from '@utils/debounce';

import {
  getUserPreferences,
  updateUserPreferences,
} from '@services/userPreferences';

import { updateUserPhoneNumber } from '@services/user';

import { getUserStorage, setUserStorage } from '@services/auth';
import SpinningWheel from '../SpinningWheel/SpinningWheel.js';

const ProfileNotification = () => {
  const [valueInput, setValueInput] = useState({
    whatsapp: false,
    push: false,
  });

  const [showError, setShowError] = useState(false);
  const [showSuccsess, setShowSuccsess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [userId, setUserId] = useState(null);

  const TEXTS = {
    PHONE_MODAL_INFO: t('getPhoneNumber'),
    SAVE_BUTTON: t('saveButton'),
    ERROR_API: t('genericErrorApi'),
    WHATSAPP_NOTIFICATION: t('whatsappNotification'),
    PUSH_NOTIFICATION: t('pushNotification'),
    SAVE_SUCCESS: t('saveWithSuccess'),
  };

  const user = getUserStorage();

  const activateWpp = () => {
    setValueInput({ ...valueInput, whatsapp: true });
  };

  const handleWithUpdatePreferences = async () => {
    setLoading(true);
    setShowError(false);
    setShowSuccsess(false);

    const {
      data: { result },
    } = await updateUserPreferences({
      userPreferences: {
        id: userId,
        isAvailableForReceiveOfferWpp: valueInput.whatsapp ? 1 : 0,
        isAvailableForReceiveOfferNotification: valueInput.push ? 1 : 0,
      },
    });

    setLoading(false);

    if (result.status !== 0) {
      setShowError(true);
      setShowSuccsess(false);
    } else {
      setShowSuccsess(true);
      setShowError(false);
    }
  };

  const handleWithUpdatePhoneNumber = async value => {
    if (!user.profile.mobileNumber) {
      setDialogOpen(true);
      return;
    }
    setValueInput({ ...valueInput, whatsapp: value });
  };

  const setPhoneNumber = async phoneNumber => {
    setLoading(true);

    debounce(() => {
      updateUserPhoneNumber({ userId: user.profile.id, phoneNumber })
        .then(async () => {
          user.profile.mobileNumber = phoneNumber;
          setUserStorage(user);
          handleWithUpdatePreferences();
          activateWpp();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
          setDialogOpen(false);
        });
    }, 1500);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { id } = user;
      setUserId(id);

      const {
        userPreferences: {
          isAvailableForReceiveOfferWpp,
          isAvailableForReceiveOfferNotification,
        },
      } = await getUserPreferences();

      setValueInput({
        whatsapp: !!Number(isAvailableForReceiveOfferWpp) || false,
        push: !!Number(isAvailableForReceiveOfferNotification) || false,
      });
      setLoading(false);
    })();
  }, []);

  return (
    <Styles.ProfileContainer>
      <Styles.Group>
        <Styles.ContainerInput>
          <Styles.InputLabel>{TEXTS.WHATSAPP_NOTIFICATION}</Styles.InputLabel>
          <InputToggle
            value={valueInput.whatsapp}
            test="ProfileNotification-whatsapp"
            setValue={value => handleWithUpdatePhoneNumber(value)}
          />
        </Styles.ContainerInput>
        <Styles.ContainerInput>
          <Styles.InputLabel>{TEXTS.PUSH_NOTIFICATION}</Styles.InputLabel>
          <InputToggle
            value={valueInput.push}
            test="ProfileNotification-push"
            setValue={value => setValueInput({ ...valueInput, push: value })}
          />
        </Styles.ContainerInput>
      </Styles.Group>
      {loading ? (
        <SpinningWheel />
      ) : (
        <Styles.Button onClick={handleWithUpdatePreferences}>
          {TEXTS.SAVE_BUTTON}
        </Styles.Button>
      )}
      {showError && (
        <Styles.ErrorMesssage>{TEXTS.ERROR_API}</Styles.ErrorMesssage>
      )}
      {showSuccsess && (
        <Styles.SuccessMesssage>{TEXTS.SAVE_SUCCESS}</Styles.SuccessMesssage>
      )}
      <Dialog open={dialogOpen} setOpen={setDialogOpen} persistent>
        <DialogPhoneNumber
          info={TEXTS.PHONE_MODAL_INFO}
          loading={loading}
          onCancel={() => setDialogOpen(false)}
          onOk={phoneNumber => setPhoneNumber(phoneNumber)}
        />
      </Dialog>
    </Styles.ProfileContainer>
  );
};

export default ProfileNotification;
