import React, { useState, useEffect } from 'react';
import { sprintf } from 'sprintf-js';
import Style from './style.module.css';

// utils
import aircraftModelNameHelper from '@utils/aircraftModelNameHelper';
import { translate as t } from '@utils/translate';
import { formatCurrency } from '@utils/currency';

const ModelCard = ({
  aircraftModel,
  active,
  selected,
  price,
  currency,
  onClick,
}) => {
  const TEXTS = {
    STARTING_AT: t('startingAt'),
    SHOW_AIRCRAFT: t('showAircraft'),
    SEATS_UP_TO: t('seatsUpTo'),
  };

  return (
    <div
      onClick={onClick}
      className={
        active
          ? [Style.cardActive].join(' ')
          : selected
          ? Style.cardSelected
          : Style.card
      }
    >
      <img className={Style.cardImg} src={aircraftModel.highlightImageUrl} />
      <div>
        <p className={Style.cardTitle}>
          {' '}
          {aircraftModelNameHelper(aircraftModel)}{' '}
        </p>
        <p className={Style.cardPriceText}>
          {' '}
          <span className="priceInformation">{TEXTS.STARTING_AT} </span>
          <span className={Style.cardPrice}>
            {formatCurrency({ value: price, currency: currency })}
          </span>{' '}
        </p>
        <p className={Style.cardDescription}> {TEXTS.SHOW_AIRCRAFT} </p>
        <p className={Style.cardNumberOfSeats}>
          {' '}
          {sprintf(TEXTS.SEATS_UP_TO, aircraftModel.maxSeats)}
        </p>
      </div>
    </div>
  );
};

const ModelsResults = ({ models, currency, modelIdActive, onSelected }) => {
  const [idModelActive, setIdModelActive] = useState(modelIdActive);
  const [modalActive, setModalActive] = useState(false);
  const [modalCardActive, setModalCardActive] = useState(0);

  const getMaxSeats = aircrafts => {
    let maxSeats = 0;
    aircrafts.forEach(aircraft => {
      if (maxSeats < aircraft.aircraft.quantityOfPassengers)
        maxSeats = aircraft.aircraft.quantityOfPassengers;
    });

    return maxSeats;
  };

  // Get current selected aircraft model
  const activeModels = models
    .filter(aircraftModels => aircraftModels.aircraftModel.id === idModelActive)
    .map(activeModel => {
      const mappedModel = {
        ...activeModel,
        aircraftModel: {
          ...activeModel.aircraftModel,
          maxSeats: getMaxSeats(activeModel.aircrafts),
        },
      };
      return mappedModel;
    });

  // get all the others unselected models
  const disabledModels = models
    .filter(aircraftModels => aircraftModels.aircraftModel.id !== idModelActive)
    .map(disabledModel => {
      const mappedModel = {
        ...disabledModel,
        aircraftModel: {
          ...disabledModel.aircraftModel,
          maxSeats: getMaxSeats(disabledModel.aircrafts),
        },
      };
      return mappedModel;
    });

  useEffect(() => {
    onSelected(activeModels);
  }, [idModelActive, models]);

  // set model selected when the component render
  useEffect(() => {
    if (modelIdActive) setIdModelActive(modelIdActive);
  }, [modelIdActive, models]);

  const TEXTS = {
    AVAILABLE_MODELS: t('availableModels'),
    SELECT_MODEL: t('selectModel'),
    CONFIRM_BUTTON: t('confirmButton'),
    CANCEL_BUTTON: t('cancel'),
  };

  return (
    <>
      <div
        className={
          modalActive ? [Style.section, Style.modal].join(' ') : Style.section
        }
      >
        <span className={Style.modalTitle}> {TEXTS.SELECT_MODEL} </span>

        <div className={Style.containerContentCardActive}>
          <div className={Style.contentCardActive}>
            {activeModels.map((aircraftModels, index) => (
              <ModelCard
                key={index}
                {...aircraftModels}
                active={idModelActive == aircraftModels.aircraftModel.id}
                onClick={() => {
                  setIdModelActive(aircraftModels.aircraftModel.id);
                  setModalActive(false);
                }}
                price={aircraftModels.startingPrice}
                currency={currency}
              />
            ))}
            <div
              className={`${Style.toggleIcon} ${
                !modalActive ? Style.toggleInactive : ''
              }`}
              onClick={() => {
                setModalActive(!modalActive);
              }}
            />
          </div>
          <div>
            <div className={Style.content}>
              {disabledModels.map((aircraftModels, index) => (
                <ModelCard
                  key={index}
                  {...aircraftModels}
                  active={idModelActive == aircraftModels.aircraftModel.id}
                  selected={modalCardActive === aircraftModels.aircraftModel.id}
                  onClick={() => {
                    setIdModelActive(aircraftModels.aircraftModel.id);
                    setModalActive(false);
                  }}
                  price={aircraftModels.startingPrice}
                  currency={currency}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={Style.overlay}></div>
    </>
  );
};

export default ModelsResults;
