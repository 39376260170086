import React, { useReducer, createContext } from 'react';

import { formatDateString } from '../utils/dates';

export const SearchResultsStateContext = createContext();
export const SearchResultsDispatchContext = createContext();

const initialState = {
  currentSegment: {},
  completedSegments: [],
  incompleteSegments: [],
  displayedResults: null,
  searchSegments: [],
  results: [],
  flightDetailsResults: null,
  showDetails: false,
};

const prepareNewSegments = ({
  originType,
  originId,
  destinationType,
  destinationId,
  departureDate,
  departureTime,
  returnDate,
  returnTime,
}) => {
  const hasReturndate = () => returnDate.split('-')[0].length > 0;

  const departureOrigin = {
    spotId: originType === 's' ? originId : null,
    cityId: originType === 'c' ? originId : null,
  };
  const departureDestination = {
    spotId: destinationType === 's' ? destinationId : null,
    cityId: destinationType === 'c' ? destinationId : null,
  };
  const departureSegment = {
    origin: departureOrigin,
    destination: departureDestination,
    //departureDatetime: `${departureDate.replace(/\//g, "-")} ${departureTime}`,
    departureDatetime: formatDateString(departureDate, departureTime),
    //returnDatetime: returnDate !== '-' ? `${returnDate.replace(/\//g, "-")} ${returnTime}` : null,
    returnDatetime:
      returnDate !== '-' ? formatDateString(returnDate, returnTime) : null,
    aircraftId: null,
  };

  let returnSegment;
  if (hasReturndate()) {
    returnSegment = {
      origin: departureDestination,
      destination: departureOrigin,
      departureDatetime: `${returnDate.replace(/\//g, '-')} ${returnTime}`,
      aircraftId: null,
    };
  }
  return departureSegment;
  //return returnSegment ? [departureSegment, returnSegment] : departureSegment;
};

const searchResultsReducer = (state, action) => {
  switch (action.type) {
    case 'newSearch':
      return {
        ...state,
        incompleteSegments: state.incompleteSegments.concat(
          prepareNewSegments(action.params)
        ),
      };
    case 'setSearchSegment':
      return {
        ...state,
        searchSegments: state.completedSegments.concat(
          state.incompleteSegments[0]
        ),
      };
    case 'reset':
      return { ...initialState };
    case 'selectRoute':
    case 'setResults':
      return {
        ...state,
        results: state.displayedResults,
        displayedResults: action.routes,
      };

    case 'setFlightDetailRoutes':
      return {
        ...state,
        flightDetailsResults: action.routes,
        showDetails: false,
      };

    case 'updateSegments':
      return {
        ...state,
        ...action.payload,
      };
    case 'setDisplayedResults':
      return {
        ...state,
        displayedResults: action.routes,
      };
    case 'removeFirstSection':
      return {
        ...state,
        displayedResults: state.displayedResults.slice(
          1,
          state.displayedResults.length
        ),
      };
    default:
      throw new Error('Bad action type');
  }
};

const SearchResultsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(searchResultsReducer, initialState);
  return (
    <SearchResultsStateContext.Provider value={state}>
      <SearchResultsDispatchContext.Provider value={dispatch}>
        {children}
      </SearchResultsDispatchContext.Provider>
    </SearchResultsStateContext.Provider>
  );
};

export default SearchResultsContextProvider;
