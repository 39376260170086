export default {
  title: 'Necessita adicionar um novo trecho?',
  description:
    'É possível adicionar um novo trecho a esta cotação atual, apenas selecione no botão abaixo.',
  form: {
    initialDay: new Date(),
    initialOriginId: '1',
    onlyDestination: true,
  },
};
