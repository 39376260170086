// Third party libs/components
import React, { useEffect, useState } from 'react';

// Components
import * as Styled from './SearchSalesMenuContainer.style';
import SearchFilter from '@components/SearchFilter';
import Aircraft from '@components/SearchSalesResultsHeader/Filters/Aircraft';
import GroupType from '@components/SearchSalesResultsHeader/Filters/GroupType';

// Utils
import { translate as t } from '@utils/translate';
import * as Helpers from '@components/SearchSalesResultsHeader/utils/SearchSalesResultsHeader.helper';

const SearchSalesMenuContainer = ({
  selectedTab = 0,
  onSelectTab = () => null,
  onGroupBy = () => null,
  onUpdateFilterValues = () => null,
  onApplyFilters = () => null,
}) => {
  const TEXTS = {
    AIRPLANES: t('airplanes'),
    HELICOPTERS: t('helicopters'),
  };
  const [filtersOpened, setFiltersOpened] = useState(false);

  const orderOptions = [
    {
      name: 'producers',
      label: Helpers.t('producer'),
    },
    {
      name: 'categories',
      label: Helpers.t('category'),
    },
  ];

  return (
    <Styled.InfoHeaderContainer>
      <Styled.ItemsLeft>
        <Styled.MenuItemWrapper
          selected={selectedTab === 0}
          onClick={() => onSelectTab(0)}
        >
          <Styled.MenuItem>{TEXTS.AIRPLANES}</Styled.MenuItem>
        </Styled.MenuItemWrapper>

        <Styled.MenuItemWrapper
          selected={selectedTab === 1}
          onClick={() => onSelectTab(1)}
        >
          <Styled.MenuItem>{TEXTS.HELICOPTERS}</Styled.MenuItem>
        </Styled.MenuItemWrapper>
      </Styled.ItemsLeft>
      <Styled.ItemsRight>
        <Styled.ToggleFilters
          onClick={() => {
            setFiltersOpened(!filtersOpened);
          }}
        >
          {Helpers.t('filters')}
        </Styled.ToggleFilters>
        <Styled.FiltersWrapper opened={filtersOpened}>
          <Styled.FiltersContainer>
            <Aircraft
              onChangeFilters={values => {
                onUpdateFilterValues(values);
              }}
              closeFilter={() => setFiltersOpened(false)}
              onSubmit={() => onApplyFilters()}
              showTabs={[selectedTab === 0 ? 'airplanes' : 'helicopters']}
            />
          </Styled.FiltersContainer>
        </Styled.FiltersWrapper>
      </Styled.ItemsRight>
    </Styled.InfoHeaderContainer>
  );
};

export default SearchSalesMenuContainer;
