import styled, { css } from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';

const DEVICES = {
  mobile: 'max-width: 600px',
  desktop: 'min-width: 601px',
};

const COLORS = {
  infoTitle: '#01BEA4',
  darkGreen: '#009688',
  darkGrey: '#616161',
  lightGrey: '#858585',
};

const SharedFlightInfo = styled.div`
  display: flex;
  height: 100%;

  @media (${DEVICES.mobile}) {
    flex-direction: column;
    height: auto;
  }
`;

const AircraftInfo = styled.div`
  align-items: flex-end;
  background-color: lightgrey;
  background-image: url('${props => props.bgImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px;
  display: flex;
  height: 100%;
  overflow: hidden;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  position: relative;
  transition: opacity .5s ease-in-out, height .3s ease-in-out;
  width: 35%;
  z-index: 0;

  @media(${DEVICES.mobile}) {
    width: 100%;
    height: 10rem;

    ${ifNotProp(
      'isVisible',
      css`
        height: 0;
      `
    )}
  }
  
  @media(${DEVICES.desktop}) {
    &:hover {
      &:after {
        opacity: 1;
      }
      
      .infos {
        opacity: 1;
      }
    }
  }
  
  &:after {
    content: '';
    background: rgb(0, 0, 0);
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    transition: all .15s ease-in-out;

    @media(${DEVICES.desktop}) {
      /* opacity: 1; */
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.9) 80%
      );
    }
    
    @media(${DEVICES.mobile}) {
      opacity: 1;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.1) 10%,
        rgba(0, 0, 0, 0.7) 80%
      );
    }
  }

  .infos {
    opacity: 0;
    padding: 10px;
    z-index: 2;
    pointer-events: none;
    transition: all .15s ease-in-out;

    span {
      color: white;
      display: block;
    }

    &__primary {
      color: ${COLORS.infoTitle} !important;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 2px;
      text-transform: uppercase;
    }

    &__seats {
      font-weight: 200;
    }
  }

  @media(${DEVICES.mobile}) {
    .infos {
      opacity: 1;
      font-size: 14px;

      &__primary {
        font-size: 14px;
      }
    }
  }
`;

const FlightInfo = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin-left: ${({ shortMode }) => (!shortMode ? '25%' : 0)};
  padding: 10px;
  position: absolute;
  width: 43%;
  height: 90%;
  transition: margin-left 0.5s ease-in-out;

  @media (${DEVICES.mobile}) {
    position: static;
    width: 100%;
    margin: 0;
    height: auto;
    margin: auto;
    padding: ${({ shortMode }) =>
      !shortMode ? '25px 10px 0px 10px' : '25px 10px'};
  }

  ${ifProp(
    'shortMode',
    css`
      * {
        color: white !important;
        transition: all 0.1s ease-in-out;
      }

      .segmentsTime .type :after {
        background-color: white !important;
      }
    `
  )}

  ${ifProp(
    { variant: 'carousel' },
    css`
      margin-left: 35%;
      width: 59%;
      height: 70%;

      .dateTime {
        font-size: 15px !important;
      }

      .segmentsTime {
        padding: 10px 0 4px !important;
      }
    `
  )}

  .dateTime {
    font-size: 18px;
    color: ${COLORS.darkGrey};

    @media (${DEVICES.mobile}) {
      font-size: 14px;
      text-align: center;
    }
  }

  .segmentsTime {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-transform: uppercase;
    width: 90%;
    padding: 10px 0;

    .startTime,
    .endTime {
      font-size: 26px;
      font-weight: 700;
    }

    .type {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &:after {
        content: '';
        display: block;
        width: 60px;
        height: 2px;
        background-color: ${COLORS.darkGreen};
        position: relative;
        top: -2px;
        margin-left: 6px;
      }
    }

    @media (${DEVICES.mobile}) {
      margin: auto;

      .startTime,
      .endTime {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  .iataAndAmeneties {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (${DEVICES.mobile}) {
      width: 90%;
      margin: auto;
    }

    .iata {
      width: auto;
      display: flex;
      align-items: center;

      &__code {
        font-size: 20px;
        font-weight: 500;
        color: ${COLORS.lightGrey};
      }

      &__arrow {
        position: relative;
        margin: 0 7px;
        top: -2px;
        width: 16px;
      }

      @media (${DEVICES.mobile}) {
        position: relative;
        top: 3px;

        &__code {
          font-size: 16px;
        }

        &__arrow {
          position: relative;
          margin: 0 7px;
          top: -2px;
          width: 12px;
        }
      }
    }

    .amenities {
      width: 50%;

      @media (${DEVICES.mobile}) {
        zoom: 0.85;
        margin-right: 0;
        width: auto;
      }
    }
  }
`;

export { SharedFlightInfo, AircraftInfo, FlightInfo };
