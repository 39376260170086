import headerQuery from './graphql/headerQuery';
import useOutsideClick from '@utils/useOutsideClick';
import { useCurrentLang } from '@utils/translate';
import { translate as t } from '@utils/translate';

const mountMenuItems = ({ query, pageLang }) => {
  const langMenus = {
    pt_BR: 'header-menu',
    en_US: 'header-menu-english',
    es_ES: 'header-menu-espanol',
    // it_IT: 'header-menu-italiano',
  };
  const items = query.allWordpressWpApiMenusMenusItems.nodes.reduce(
    (obj, { slug, items }) => {
      obj[slug] = items;
      return obj;
    },
    {}
  );
  const menuItems = pageLang
    ? items[langMenus[pageLang]]
    : items['header-menu'];
  return menuItems;
};

export {
  mountMenuItems,
  // external helpers
  headerQuery,
  useOutsideClick,
  t,
  useCurrentLang,
};
