// Third party libs/components
import React, { Fragment, useState, useCallback } from 'react';

// Components
import * as Styled from './Aerodrome.style';
import TabSection from '@components/TabSection';
import CheckboxList from '@components/CheckboxList';
import ConfirmButton from '@components/ConfirmButton';

// Utils
import { translate as t } from '@utils/translate';

const Aerodrome = ({ data, onSubmit, closeFilter }) => {
  const TEXTS = {
    AIRPORTS_TITLE: t('airports'),
    HELIPADS_TITLE: t('helipads'),
    ORIGIN_TITLE: t('origin'),
    DESTINATION_TITLE: t('destination'),
  };
  const [lists, setLists] = useState(() => {
    return {
      ...data,
    };
  });

  const handleSelect = useCallback((targetList, index, selected) => {
    let updatedList = [...lists[targetList]];
    updatedList[index] = { ...updatedList[index], selected };
    setLists({
      ...lists,
      [targetList]: updatedList,
    });
  });

  const handleSelectAll = useCallback((targetList, selected) => {
    let updatedList = [...lists[targetList]];
    updatedList = updatedList.map(item => {
      return { ...item, selected };
    });
    setLists({
      ...lists,
      [targetList]: updatedList,
    });
  });

  const handleSubmit = () => {
    onSubmit(lists);
    closeFilter();
  };

  const originSection = (
    <Fragment>
      <CheckboxList
        title={TEXTS.AIRPORTS_TITLE}
        items={lists.originAirports || []}
        onClick={(index, checked) =>
          handleSelect('originAirports', index, checked)
        }
        onToggleCheckAll={checked => handleSelectAll('originAirports', checked)}
      />
      <Styled.Separator></Styled.Separator>
      <CheckboxList
        title={TEXTS.HELIPADS_TITLE}
        items={lists.originHelipads || []}
        onClick={(index, checked) =>
          handleSelect('originHelipads', index, checked)
        }
        onToggleCheckAll={checked => handleSelectAll('originHelipads', checked)}
      />
    </Fragment>
  );

  const destinationection = (
    <Fragment>
      <CheckboxList
        title={TEXTS.AIRPORTS_TITLE}
        items={lists.destinationAirports || []}
        onClick={(index, checked) =>
          handleSelect('destinationAirports', index, checked)
        }
        onToggleCheckAll={checked =>
          handleSelectAll('destinationAirports', checked)
        }
      />
      <Styled.Separator></Styled.Separator>
      <CheckboxList
        title={TEXTS.HELIPADS_TITLE}
        items={lists.destinationHelipads || []}
        onClick={(index, checked) =>
          handleSelect('destinationHelipads', index, checked)
        }
        onToggleCheckAll={checked =>
          handleSelectAll('destinationHelipads', checked)
        }
      />
    </Fragment>
  );

  const sections = [
    {
      title: TEXTS.ORIGIN_TITLE,
      content: originSection,
    },
    {
      title: TEXTS.DESTINATION_TITLE,
      content: destinationection,
    },
  ];

  return (
    <Styled.Aerodrome>
      <TabSection headerClassName={'tabsHeader'} sections={sections} />
      <ConfirmButton onClick={handleSubmit} />
    </Styled.Aerodrome>
  );
};

export default Aerodrome;
