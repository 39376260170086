/**
 * Sort by date
 *
 * @param {object} a
 * @param {object} b
 */
const sortByClosestDate = (a, b) => {
  return new Date(a.flightDatetime) - new Date(b.flightDatetime);
};

const sortVariants = {
  closest: sortByClosestDate,
};

/**
 * Executes the sort method
 *
 * @param {string} variant // Sorting variant (closest)
 * @param {object} data
 * @param {string} flightType // Type of flight results (charter, flights)
 */
const applySort = ({ data, variant }) => {
  let sortedData = [...data];

  sortedData.sort(sortVariants[variant]);

  return sortedData;
};

const sortByDate = { applySort };

export default sortByDate;
