import {
  dateToFlightDetailsFormat,
  dateToHour,
  dateToYear,
} from '@utils/dates';
import { parseLangType, translate_to } from '@utils/translate';
import { groupBy } from '@utils/functions';
import aircraftNameHelper from '@utils/aircraftNameHelper';

const mapChipsAircraftAbout = (aircraft, lang) => {
  let chips = [];

  if (
    aircraft.quantityOfPassengers
      ? aircraft.quantityOfPassengers
      : aircraft.model.quantityOfPassengers
  )
    chips.push({
      text:
        translate_to('quantityOfPassengers', lang) +
        ' ' +
        (aircraft.quantityOfPassengers
          ? aircraft.quantityOfPassengers
          : aircraft.model.quantityOfPassengers),
      svgSource:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-771 -273)"><g transform="translate(0 -549)"><rect class="a" width="16" height="16" transform="translate(771 822)"/><g transform="translate(9 -0.276)"><g transform="translate(762 823.276)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path d="M54.81,16.9a3.41,3.41,0,1,0-.845-4.754A3.414,3.414,0,0,0,54.81,16.9Z" transform="translate(-49.084 -10.689)"/><path d="M6.768,133.459,3.414,117.312H0l3.405,16.651a8.528,8.528,0,0,0,8.432,7.246H23.9v-3.414H11.829A5.121,5.121,0,0,1,6.768,133.459Z" transform="translate(0 -108.781)"/><path d="M92.9,130.986H84.57l-1.75-7c2.688,1.519,5.6,2.637,8.79,2.082v-3.627a10.5,10.5,0,0,1-8.005-2.125L80.8,118.133a3.96,3.96,0,0,0-3-.751l-.043.009a3.841,3.841,0,0,0-3.124,4.446l2.313,10.1a5.12,5.12,0,0,0,5.027,4.165H93.667l6.52,5.121,2.56-2.56Z" transform="translate(-68.748 -108.8)"/></g></g></g></g></g></g></svg>',
    });

  if (aircraft.baggageInfo ? aircraft.baggageInfo : aircraft.model.baggageInfo)
    chips.push({
      text:
        translate_to('baggage', lang) +
        ' ' +
        (aircraft.baggageInfo
          ? aircraft.baggageInfo
          : aircraft.model.baggageInfo),
      svgSource:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><g transform="translate(-97 -110.561)"><path d="M27.767,8.045l-5.5,5.5h3.766a1.328,1.328,0,0,1,0,2.656h-4.98a3.324,3.324,0,0,1-3.32-3.32V7.9a1.328,1.328,0,1,1,2.656,0v3.766l5.5-5.5a1.328,1.328,0,0,1,1.879,1.878ZM15.008,27.227A1.328,1.328,0,0,0,16.336,25.9v-4.98a3.324,3.324,0,0,0-3.32-3.32H8.035a1.328,1.328,0,1,0,0,2.656H11.8l-5.5,5.5a1.328,1.328,0,0,0,1.879,1.878s4.127-5.068,5.5-5.5,0,3.766,0,3.766a1.328,1.328,0,0,0,1.328,1.328Zm17.664-3.852A1.328,1.328,0,0,0,34,22.047V5.313A5.318,5.318,0,0,0,28.688,0H5.313A5.318,5.318,0,0,0,0,5.313V28.688A5.318,5.318,0,0,0,5.313,34H28.688A5.318,5.318,0,0,0,34,28.688a1.328,1.328,0,0,0-2.656,0,2.659,2.659,0,0,1-2.656,2.656H5.313a2.659,2.659,0,0,1-2.656-2.656V5.313A2.659,2.659,0,0,1,5.313,2.656H28.688a2.659,2.659,0,0,1,2.656,2.656V22.047A1.328,1.328,0,0,0,32.672,23.375Zm0,0" transform="translate(97 110.561)"/></g></svg>',
    });

  if (aircraft.cruiseSpeed ? aircraft.cruiseSpeed : aircraft.model.cruiseSpeed)
    chips.push({
      text:
        translate_to('cruiseSpeed', lang) +
        ' ' +
        (aircraft.cruiseSpeed
          ? aircraft.cruiseSpeed
          : aircraft.model.cruiseSpeed) +
        ' km/h',
      svgSource:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-97 -418)"><g transform="translate(0 -433)"><rect class="a" width="16" height="16" transform="translate(97 851)"/><g transform="translate(97 787.145)"><g transform="translate(0 68.855)"><path d="M19.372,73.368l1.839-4.142A24.023,24.023,0,0,0,0,75.879L3.091,78.97A19.638,19.638,0,0,1,19.372,73.368Z" transform="translate(0 -68.855)"/><path d="M96.639,189.613a15.273,15.273,0,0,0-11.3,4.451l3.091,3.091a10.819,10.819,0,0,1,6.228-3.083Z" transform="translate(-79.152 -180.858)"/><path d="M200.821,80.478a.761.761,0,0,0-.634.355l-.1.224L192.074,99.07a3.023,3.023,0,0,0-.394,1.491,3.1,3.1,0,0,0,6.128.649l.008-.054,3.779-19.905A.775.775,0,0,0,200.821,80.478Z" transform="translate(-177.794 -79.636)"/><path d="M315.307,215.338a10.86,10.86,0,0,1,1.885,1.5l3.091-3.091a15.3,15.3,0,0,0-4.119-2.921Z" transform="translate(-292.465 -200.54)"/><path d="M339.6,89.652,338.774,94a19.6,19.6,0,0,1,6.367,4.258l3.091-3.091A23.927,23.927,0,0,0,339.6,89.652Z" transform="translate(-314.232 -88.145)"/></g></g></g></g></svg>',
    });

  if (aircraft.manufacturedAt)
    chips.push({
      text:
        translate_to('yearOfManufacture', lang) +
        ' ' +
        dateToYear(aircraft.manufacturedAt),
      svgSource:
        '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M7 18.333v-1.395l1.667-1.25v-4.334l-7 2.063v-1.813l7-4.146V3q0-.562.385-.948.386-.385.948-.385.562 0 .948.385.385.386.385.948v4.458l7 4.104v1.855l-7-2.063v4.334L13 16.938v1.395l-3-.854Z"/></svg>',
    });

  if (aircraft.refurbishedAt)
    chips.push({
      text:
        translate_to('renewalYear', lang) +
        ' ' +
        dateToYear(aircraft.refurbishedAt),
      svgSource:
        '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M4.5 17q-.625 0-1.062-.438Q3 16.125 3 15.5v-11q0-.625.438-1.062Q3.875 3 4.5 3H11v1.5H4.5v11h11V9H17v6.5q0 .625-.438 1.062Q16.125 17 15.5 17ZM7 14v-1.5h6V14Zm0-2.25v-1.5h6v1.5ZM7 9.5V8h6v1.5Zm7-2V6h-1.5V4.5H14V3h1.5v1.5H17V6h-1.5v1.5Z"></path></svg>',
    });

  return chips;
};

export const getProps = (flight, aircraft, pageDetails) => {
  const lang = pageDetails.language;
  // This variable use a other format (es-ES)
  const _language = parseLangType(lang);
  const mapToImagesProp = imagesArr =>
    imagesArr.map((img, idx) => ({
      label: null,
      description: img.description,
      url: img.url,
    }));
  const mapToAmenities = amenities =>
    amenities.map(am => ({
      title: am.name,
      icon: am.iconImageUrl,
      description: null,
    }));
  const mapFlighSegments = segments => [];

  const getExpirationDate = expDate =>
    new Date(expDate).getFullYear() - new Date().getFullYear();
  const extractFromBoardStep = instructions => instructions.split(',')[0];
  const getAirCarrier = (airCarrier, ownerCompany) => {
    if (airCarrier && airCarrier.iconImageUrl) {
      return {
        firstRender: airCarrier.name,
        secRender: airCarrier.registration,
        thirdRender: airCarrier.hoursFlown,
        image: airCarrier.iconImageUrl,
      };
    } else {
      return {
        firstRender: ownerCompany.fantasyName,
        secRender: ownerCompany.registration,
        thirdRender: null,
        image: ownerCompany.profilePictureUrl,
      };
    }
  };
  const getCertificationsGroup = certifications => {
    const certs = certifications.map(
      ({
        certification,
        certificationDate,
        expirationDate,
        firstCertificatedAt,
      }) => ({
        firstRender: certification.name,
        secRender: [
          getExpirationDate(expirationDate),
          dateToYear(certificationDate),
        ],
        memberSince: dateToYear(firstCertificatedAt),
        expirationDate: expirationDate,
        thirdRender: {
          name: certification.country?.name,
          iso3Code: certification.country?.iso3Code,
        },
        image: certification.iconImageUrl,
        category: certification.category,
        isLive: certification.isLive,
      })
    );
    return groupBy(certs, 'category');
  };

  const getDateTime = (datetime, part = 'date') => {
    const aDatetime = datetime.split(' ');
    let date = aDatetime[0];
    let time = aDatetime[1];
    if (time.length === 8) time = time.slice(0, 5);
    return part === 'date' ? date : time;
  };

  const getTutorials = type => {
    let tutorial = {
      title: '',
      airport: '',
      airportLocation: '',
      instructions: '',
    };

    if (type === 'boarding' && flight.boardingTutorial) {
      tutorial.title = flight.boardingTutorial.title;
      tutorial.airport = flight.boardingTutorial.spot.name;
      tutorial.airportLocation = extractFromBoardStep(
        flight.boardingTutorial.subtitle
      );
      tutorial.instructions = getSteps(flight.boardingTutorial.steps);
    } else if (type === 'landing' && flight.landingTutorial) {
      tutorial.title = flight.landingTutorial.title;
      tutorial.airport = flight.landingTutorial.spot.name;
      tutorial.airportLocation = extractFromBoardStep(
        flight.landingTutorial.subtitle
      );
      tutorial.instructions = getSteps(flight.landingTutorial.steps);
    }

    return tutorial;
  };

  const aircraftCerts = getCertificationsGroup(aircraft.certifications);
  const getSteps = steps =>
    steps.map(step => ({
      text: step.title,
      subtitle: step.subtitle,
      description: step.instructions,
    }));

  const getCancelationFees = fees => {
    return fees.ranges.map(range => {
      return range;
    });
  };

  return {
    flightDisplay: {
      destinationImage: flight.destinationSpot.address.city.imageUrl,
      destinationAlt: flight.destinationSpot.address.city.name,
      destinationName: flight.destinationSpot.address.city.name,
      flyImage: '/fly_rio.png',
      flyAlt: flight.destinationSpot.address.city.name,
    },
    flightPrice: flight.price,
    flightSchedule: {
      date: dateToFlightDetailsFormat(flight.flightDatetime, _language),
      schedule: [
        {
          time: dateToHour(flight.departureDatetime),
          airportName: flight.originSpot.name,
          codeIATA: flight.originSpot.address.city.iata,
          codeICAO: flight.originSpot.airport
            ? flight.originSpot.airport.oaci
            : flight.originSpot.helipad.oaci,
          //mapImage: flight.originSpot.imageUrl,
          mapImage: flight.originSpot.address.city.imageUrl,
          mapLink: 'https://www.google.com/',
        },
        ...mapFlighSegments(flight.segments),
        {
          time: dateToHour(flight.arrivalDatetime),
          airportName: flight.destinationSpot.name,
          codeIATA: flight.destinationSpot.address.city.iata,
          codeICAO: flight.destinationSpot.airport
            ? flight.destinationSpot.airport.oaci
            : flight.destinationSpot.helipad.oaci,
          //mapImage: flight.destinationSpot.imageUrl,
          mapImage: flight.destinationSpot.address.city.imageUrl,
          mapLink: 'https://www.google.com/',
        },
      ],
      notice: flight.itineraryNotice,
    },
    flightInquire: {
      availableSeats: flight.seats,
      selectedSeats: flight.selectedSeats,
      isSharedFlight: flight.isShared,
      currency: flight.currency,
      tripType: flight.tripType,
      flightType: flight.flightType,
      searchParams: {
        originCity: flight.originCity,
        originSpot: flight.originSpot,
        destinationCity: flight.destinationCity,
        destinationSpot: flight.destinationSpot,
        departureDate:
          flight.flightsDateTime.departureDate ||
          encodeURIComponent(getDateTime(flight.departureDatetime, 'date')),
        departureTime:
          flight.flightsDateTime.departureTime ||
          encodeURIComponent(getDateTime(flight.departureDatetime, 'time')),
        returnDate: flight.flightsDateTime.returnDate,
        returnTime: flight.flightsDateTime.returnTime,
      },
      flightId: flight.id,
    },
    aircraftGallery: {
      flightPage: true,
      images: mapToImagesProp(aircraft.pictures),
    },
    aircraftDescription: {
      flightPage: true,
      ptitle: aircraftNameHelper(aircraft),
      pscore: aircraft.model.rate,
      scoreSvg: `<svg xmlns="http://www.w3.org/2000/svg" width="22.884" height="22.849" viewBox="0 0 22.884 22.849"><path fill="#00bfa5" d="M5.315,14.649,4.107,22.055a.716.716,0,0,0,1.052.741l6.284-3.473L17.725,22.8a.716.716,0,0,0,1.052-.741L17.569,14.65,22.68,9.412a.715.715,0,0,0-.4-1.206L15.242,7.131,12.088.415a.744.744,0,0,0-1.294,0L7.642,7.13.607,8.2A.715.715,0,0,0,.2,9.411Z" transform="translate(0 -0.039)"/></svg>`,
      company: {
        companySvg: aircraft.model.producer.iconImageUrl,
        companyName: aircraft.model.producer.name,
        country: aircraft.model.producer.country.name,
        foundedAt: dateToYear(aircraft.model.producer.foundedAt),
      },
    },
    aircraftSecurity: {
      flightPage: true,
      airCarrier: {
        title: 'Transportadora aérea',
        items: [getAirCarrier(aircraft.airCarrier, aircraft.ownerCompany)],
      },
      airCarrierCerts: {
        items: aircraftCerts.government,
      },
      safetyCerts: {
        items: aircraftCerts.safety,
      },
      certifications: aircraft.certifications,
    },
    aircraftAbout: {
      flightPage: true,
      title: 'Detalhes',
      chips: mapChipsAircraftAbout(aircraft, lang),
      imgSrc: aircraft.seatMapImageUrl
        ? aircraft.seatMapImageUrl
        : aircraft.model.seatMapImageUrl,
    },
    aircraftComfort: {
      items: aircraft.amenities,
      flightPage: true,
    },
    flightInstructions: {
      boardingInstructions: getTutorials('boarding'),
      landingInstructions: getTutorials('landing'),
    },
    flightCancelation: {
      items: getCancelationFees(flight.operator.cancellationFee),
    },

    pageProps: {
      prevUrl: pageDetails.prevUrl,
    },
  };
};
