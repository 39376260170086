// Third party libs/components
import React, { Fragment } from 'react';

// Components
import * as Styled from './FlightType.style';

// Utils
import { translate as t } from '@utils/translate';

const FlightType = ({ closeFilter, handleSetFlightType, selectedType }) => {
  const TEXTS = {
    CHARTER_TITLE: t('charteredFlight'),
    SHARED_TITLE: t('sharedFlight'),
  };

  const handleFligthType = type => {
    handleSetFlightType(type);
    closeFilter();
  };

  return (
    <Fragment>
      <Styled.DirectOption
        selected={selectedType === 2}
        onClick={() => handleFligthType(2)}
      >
        {TEXTS.CHARTER_TITLE}
      </Styled.DirectOption>
      <Styled.DirectOption
        selected={selectedType === 4}
        onClick={() => handleFligthType(4)}
      >
        {TEXTS.SHARED_TITLE}
      </Styled.DirectOption>
    </Fragment>
  );
};

export default FlightType;
