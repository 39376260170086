// TODO: REFACTORING
import React, { useEffect, useRef, useState } from 'react';

import { BiCheck } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Modal from '@components/Modal/Modal';
import SharedFlightTag from '@components/SharedFlightTag';
import { navigate, useParams } from '@reach/router';

import createCheckout from '@services/createCheckout';
import { fetchSharedFlights } from '@services/search';

import { apiHost, getAvailableFlightsStats } from '@utils/constants';
import { formatDateString } from '@utils/dates';
import { getFlightTypeString } from '@utils/search';
import { flightSearchSecret } from '@utils/secrets';
import { decodeObjectToken } from '@utils/token';
import { translate as t, useCurrentLang } from '@utils/translate';
import useRequest from '@utils/useRequest';

import ModalStyles from './modal.module.css';
import * as Styled from './SharedFlight.styles';

import PropTypes from 'prop-types';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

function SharedFlight({ searchParams, flights }) {
  const child = useRef();
  const modalConstants = {
    NOTHING_TO_SHOW: 'nothing-to-show',
    NO_RETURNING_FLIGHTS: 'no-returning-flights',
    ONLY_RETURNING_FLIGHTS: 'only-returning-flights',
  };
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const params = useParams();
  const lang = useCurrentLang();

  const [modalOpened, setModalOpened] = useState(false);
  const [flightsList, setFlightsList] = useState(null);
  const [flightStats, setFlightStats] = useState(null);
  const [isLookingForReturn, setIsLookingForReturn] = useState(false);
  const [returningFLights, setReturningFlights] = useState(null);
  const [seatsSelected, setSeatsSelected] = useState();
  const [toggleFlights, setToggleFlights] = useState(false);
  const [finishedCheckData, setFinishedCheckData] = useState(false);
  const [initialSeats, setInitialSeats] = useState(0);
  const [selectedFlight, setSelectedFlight] = useState({
    originFlightId: null,
    originFlightPrice: 0,
    originFlightPath: '',
    destinationFlightId: null,
    destinationFlightPrice: 0,
    destinationFlightPath: '',
  });
  const currency = useSelector(state => state.general.currency);
  const isNewCheckout = useFeatureIsOn('new-checkout-web');
  const [listShouldShrink, setListShouldShrink] = useState(null);

  const TEXTS = {
    NO_AVAILABLE: t('noFlightsAvailable'),
    NO_AVAILABLE_INFO: t('noFlightsAvailableInfo'),
    BACK_TO: t('backToHomepage'),
    SEARCH_FOR: t('searchForCharterFlight'),
    NO_RETURNING_FLIGHTS: t('noReturnFlightsAvailable'),
    NO_RETURNING_FLIGHTS_INFO: t('noReturnFlightsAvailableInfo'),
    ID_LIKE: t('idLikeToProceed'),
    NO_OUTBOUND_FLIGHTS_AVAILABLE: t('noOutboundFlightsAvailable'),
    NO_OUTBOUND_FLIGHTS_AVAILABLE_INFO: t('noOutboundFlightsAvailableInfo'),
    SIGIN_TO: t('signInToContinue'),
    FORGOT_PASSWORD: t('didYouforgotYourPassword'),
    SHARED_TITLE: t('sharedFlightsTitle'),
    SELECT_OUTBOUND_SEGMENT: t('selectOutboundSegment'),
    SELECT_INBOUND_SEGMENT: t('selectInboundSegment'),
    INBOUND: t('inbound'),
    OUTBOUND: t('outbound'),
    CONFIRMED: t('confirmed'),
    EDIT: t('edit'),
  };

  useEffect(() => {
    if (!finishedCheckData) return;

    let searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('st');

    if (token) {
      const flighDetailsState = decodeObjectToken(token, flightSearchSecret);

      if (flighDetailsState) {
        try {
          const result = flightsList.find(
            flight => Number(flight.id) === flighDetailsState.flightId
          );
          const path = {
            originCode: result.originSpot.airport
              ? result.originSpot.airport.acronym ||
                result.originSpot.airport.oaci
              : result.originSpot.helipad.oaci,
            destinationCode: result.destinationSpot.airport
              ? result.destinationSpot.airport.acronym ||
                result.destinationSpot.airport.oaci
              : result.destinationSpot.helipad.oaci,
          };

          setSeatsSelected({
            seats: flighDetailsState.selectedSeats,
            flightIds: {
              originFlightId: flighDetailsState.flightId.toString(),
              originFlightPrice: result.seats[0].price || 0.0,
              originFlightPath: `${path.originCode} ➔ ${path.destinationCode}`,
            },
          });
          setInitialSeats(flighDetailsState.selectedSeats);
        } catch (error) {
          console.log(error);
          // nothing to do here
        }
      }
    }
  }, [finishedCheckData]);

  useEffect(() => {
    if (flights && flights.length) {
      setFlightsList(flights);
    }
  }, [flights]);

  useEffect(() => {
    if (flightsList) {
      const {
        origin,
        destination,
        departureDate,
        departureTime,
        returnDate,
        returnTime,
      } = searchParams;

      getFlightStats({
        origin: { id: destination.id, type: destination.type },
        destination: { id: origin.id, type: origin.type },
        minDatetime: formatDateString(departureDate, departureTime),
        maxDatetime:
          returnDate !== '-' ? formatDateString(returnDate, returnTime) : null,
      });
    }
  }, [flightsList]);

  useEffect(() => {
    if (toggleFlights) {
      selectReturningFlights(searchParams);
    }
  }, [toggleFlights]);

  useEffect(() => {
    const flightsContainer = document.querySelector('#flights-list');
    const flightItem = document.querySelector(
      `#shared_${selectedFlight.originFlightId}`
    );

    if (!isMobile) {
      document.documentElement.style.overflow = selectedFlight.originFlightId
        ? 'hidden'
        : 'auto';
    }

    if (selectedFlight.originFlightId && isLookingForReturn) {
      window.scrollTo(0, 0);
      setListShouldShrink(isMobile ? flightItem.offsetHeight : null);

      if (flightsContainer) {
        flightsContainer.style.top = `-${flightItem.offsetTop}px`;
      }
    } else {
      setListShouldShrink(null);
      if (flightsContainer) {
        flightsContainer.style.top = `0px`;
      }
    }

    if (selectedFlight.originFlightId && !isLookingForReturn) {
      endProcess();
    }
  }, [selectedFlight]);

  useEffect(() => {
    if (seatsSelected) {
      if (seatsSelected.flightIds) {
        setSelectedFlight(oldState => ({
          ...oldState,
          ...seatsSelected.flightIds,
          seats: seatsSelected.seats,
          currency: currency.code,
        }));
      } else {
        endProcess();
      }
    }
  }, [seatsSelected]);

  /**
   * Fetch returning available flights and seats
   */
  async function getFlightStats({
    origin,
    destination,
    minDatetime,
    maxDatetime,
  }) {
    let params = {
      minDatetime,
      maxDatetime,
    };

    if (origin.type === 'c') params.originCityId = origin.id;
    else params.originSpotId = origin.id;

    if (destination.type === 'c') params.destinationCityId = destination.id;
    else params.destinationSpotId = destination.id;

    if (searchParams.returnDate !== '-') {
      params.excludeEmptyLeg = 1;
    }

    try {
      const { data } = await useRequest({
        method: 'get',
        useSession: false,
        url: `${apiHost}${getAvailableFlightsStats}`,
        params,
      });

      const { flightStats } = data;
      setFlightStats(flightStats);

      if (flightStats.countFlights) {
        selectReturningFlights(searchParams);
      }

      if (searchParams.returnDate === '-' && !flightsList.length) {
        setModalOpened(modalConstants.NOTHING_TO_SHOW);
      } else if (searchParams.returnDate !== '-') {
        setIsLookingForReturn(true);

        if (!flightsList.length && !flightStats.countFlights) {
          setModalOpened(modalConstants.NOTHING_TO_SHOW);
        } else if (flightsList.length && !flightStats.countFlights) {
          setIsLookingForReturn(false);
          setModalOpened(modalConstants.NO_RETURNING_FLIGHTS);
        } else if (!flightsList.length && flightStats.countFlights) {
          setIsLookingForReturn(false);
          setModalOpened(modalConstants.ONLY_RETURNING_FLIGHTS);
        }
      }
    } catch (error) {
      console.log('Flight stats -> ', error);
    } finally {
      setFinishedCheckData(true);
    }
  }

  /**
   * Fetch the returning flights
   *
   * @param {object} searchParams The search parameters
   */
  async function selectReturningFlights(searchParams) {
    const {
      origin,
      destination,
      departureDate,
      departureTime,
      returnDate,
      returnTime,
    } = searchParams;

    const params = {
      ...searchParams,
      origin: { ...destination },
      destination: { ...origin },
      minDatetime: formatDateString(departureDate, departureTime),
      maxDatetime:
        returnDate !== '-' ? formatDateString(returnDate, returnTime) : null,
    };

    try {
      const { data } = await fetchSharedFlights(params);

      if (!toggleFlights) {
        setReturningFlights(data.flights);
      } else {
        setFlightsList(data.flights);
      }
    } catch (error) {
      console.log('Returning flights -> Error getting returning flights');
    }
  }

  /**
   * Set selected flight ids to the state object
   *
   * @param {object} data The object containing the selected flight id
   */
  function selectFlight(data) {
    setSelectedFlight(oldState => ({
      ...oldState,
      ...data,
    }));
  }

  /**
   * Book flight
   *
   * @param {number} seats Selected seats amount
   * @param {object | null} flightIds The object with selected flight id (optional)
   */
  function onBook(seats, flightIds) {
    setSeatsSelected({ seats, flightIds });
  }

  function endProcess() {
    goToCheckout();
  }

  /**
   * Close modal handler
   */
  function handleCloseModal() {
    setModalOpened(false);

    if (selectedFlight.originFlightId && !isLookingForReturn) {
      resetTag();
    }
  }

  /** Finish process and go to checkout  */
  function goToCheckout() {
    const dataArray = [
      {
        typeId: 2,
        action: 'add',
        data: {
          seats: seatsSelected.seats,
          flight: {
            id: selectedFlight.originFlightId,
          },
        },
      },
    ];

    if (selectedFlight.destinationFlightId && isLookingForReturn) {
      dataArray.push({
        typeId: 2,
        action: 'add',
        data: {
          seats: seatsSelected.seats,
          flight: {
            id: selectedFlight.destinationFlightId,
          },
        },
      });
    }

    createCheckout(null, dataArray, currency.code)
      .then(cartId => {
        window.sessionStorage.setItem('search-page-url', window.location.href);
        if (isNewCheckout) {
          navigate(`/${lang.replace('_', '-')}/checkout/${cartId}`);
        } else {
          navigate(`/checkout/${cartId}`);
        }
      })
      .catch(err => {
        console.error('go to checkout ->', err);
      });
  }

  /**
   *
   */
  function showOnlyReturningFlights() {
    handleCloseModal();
    setToggleFlights(true);
  }

  /**
   * Find fleet flights if no shared flights was found
   */
  function findFleet() {
    const searchParams = { ...params };

    searchParams.flightType = getFlightTypeString(2);

    const query = Object.values(searchParams)
      .map(param => (param ? encodeURIComponent(param) : '-'))
      .join('/');

    window.location.replace(`/p/search/${query}`);
  }

  /**
   * Go home anchor handler
   */
  function goHome() {
    navigate('/');
  }

  /**
   * Render the right modal content based on fligth results
   *
   * @param {string} content The content string reference
   */
  function renderModal(content) {
    if (content === modalConstants.NOTHING_TO_SHOW) {
      return (
        <div className={ModalStyles.modal}>
          <div className={ModalStyles.description}>
            <h4 className={ModalStyles.title}>{TEXTS.NO_AVAILABLE}</h4>
            <p>{TEXTS.NO_AVAILABLE_INFO}</p>
          </div>
          <div className={ModalStyles.actions}>
            <button className={ModalStyles.buttonWhite} onClick={goHome}>
              {TEXTS.BACK_TO}
            </button>
            <button className={ModalStyles.buttonGreen} onClick={findFleet}>
              {TEXTS.SEARCH_FOR}
            </button>
          </div>
        </div>
      );
    }

    if (content === modalConstants.NO_RETURNING_FLIGHTS) {
      return (
        <div className={ModalStyles.modal}>
          <div className={ModalStyles.description}>
            <h4 className={ModalStyles.title}>{TEXTS.NO_RETURNING_FLIGHTS}</h4>
            <p>{TEXTS.NO_RETURNING_FLIGHTS_INFO}</p>
          </div>
          <div className={ModalStyles.actions}>
            <button className={ModalStyles.buttonWhite} onClick={goHome}>
              {TEXTS.BACK_TO}
            </button>
            <button
              className={ModalStyles.buttonGreen}
              onClick={handleCloseModal}
            >
              {TEXTS.ID_LIKE}
            </button>
          </div>
        </div>
      );
    }

    if (content === modalConstants.ONLY_RETURNING_FLIGHTS) {
      return (
        <div className={ModalStyles.modal}>
          <div className={ModalStyles.description}>
            <h4 className={ModalStyles.title}>
              {TEXTS.NO_OUTBOUND_FLIGHTS_AVAILABLE}
            </h4>
            <p>{TEXTS.NO_OUTBOUND_FLIGHTS_AVAILABLE_INFO}</p>
          </div>
          <div className={ModalStyles.actions}>
            <button className={ModalStyles.buttonWhite} onClick={goHome}>
              {TEXTS.BACK_TO}
            </button>
            <button
              className={ModalStyles.buttonGreen}
              onClick={showOnlyReturningFlights}
            >
              {TEXTS.ID_LIKE}
            </button>
          </div>
        </div>
      );
    }

    return null;
  }

  function resetTag() {
    setInitialSeats(0);
    child.current.resetFromParent();
  }

  function isItemSelected() {
    return selectedFlight.originFlightId && isLookingForReturn;
  }

  return (
    <Styled.Main isMobile={isMobile} itemSelected={isItemSelected()}>
      <Modal
        opened={!!modalOpened}
        type={'center'}
        hasCloseButton={false}
        closeModal={handleCloseModal}
      >
        {renderModal(modalOpened)}
      </Modal>
      <Styled.Header isMobile={isMobile}>
        <h1>{TEXTS.SHARED_TITLE}</h1>
        <h2>
          {!selectedFlight.originFlightId
            ? TEXTS.SELECT_OUTBOUND_SEGMENT
            : TEXTS.SELECT_INBOUND_SEGMENT}
        </h2>
        <div className="step-info">
          <div className="step-info__out">
            <span
              className={`step-info__out__step ${
                !isItemSelected() ? '' : 'isActive'
              }`}
            >
              {TEXTS.INBOUND}
            </span>
            {isItemSelected() && (
              <>
                <span className="step-info__out__confirmed">
                  <BiCheck />
                  <i>{TEXTS.CONFIRMED}</i>
                </span>
                <button
                  className="step-info__out__edit"
                  type="button"
                  onClick={resetTag}
                >
                  <MdEdit />
                  <i>{TEXTS.EDIT}</i>
                </button>
              </>
            )}
          </div>
          {isItemSelected() && (
            <div className="step-info__return">
              <span className="step-info__return__step">{TEXTS.OUTBOUND}</span>
            </div>
          )}
        </div>
      </Styled.Header>
      <Styled.FLightsList
        isMobile={isMobile}
        shouldShrink={listShouldShrink}
        id="flights-list"
      >
        {flightsList &&
          flightsList.map((flight, index) => (
            <SharedFlightTag
              ref={child}
              index={index}
              onSelect={selectFlight}
              onBook={onBook}
              isActive={selectedFlight.originFlightId === flight.id}
              key={flight.id}
              flightData={flight}
              flightStats={flightStats}
              selectedFlights={selectedFlight}
              returningFLights={returningFLights}
              isLookingForReturn={isLookingForReturn}
              initialSeats={initialSeats}
              shouldHide={
                selectedFlight.originFlightId &&
                selectedFlight.originFlightId !== flight.id
              }
            />
          ))}
      </Styled.FLightsList>
    </Styled.Main>
  );
}

SharedFlight.propTypes = {
  flights: PropTypes.arrayOf(PropTypes.shape()),
  searchParams: PropTypes.shape().isRequired,
};

SharedFlightTag.defaultProps = {
  flights: null,
};

export default SharedFlight;
