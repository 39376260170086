import { distinctArrayFromObjectProperty } from '@utils/arrays';
import { getSeatPrice } from '../Search.helper';

const IFilterOptions = {
  flightType: null,
  airplanes: {
    price: {
      min: 9999999,
      max: 0,
      value: {
        min: 9999999,
        max: 0,
      },
    },
    categoriesList: [],
    modelsList: [],
  },
  helicopters: {
    price: {
      min: 9999999,
      max: 0,
      value: {
        min: 9999999,
        max: 0,
      },
    },
    categoriesList: [],
    modelsList: [],
  },
};

let filterValues = { ...IFilterOptions };

/**
 * Initializes the filter passing the data to be used as initial values
 *
 * @param {object} data
 */
const initFilterValues = ({ data, flightType }) => {
  filterValues['flightType'] = flightType;

  data.forEach(route => {
    // iterate through models to get prices, categories and models
    route.aircraftModels.forEach(aircraftModel => {
      const aircraft = aircraftModel.aircraftModel;
      let category = aircraft.size ? aircraft.size.name : '';
      let model =
        aircraft.manufacturer && aircraft.model
          ? `${aircraft.manufacturer} ${aircraft.model}`
          : '';
      let startingPrice =
        flightType === 'charter'
          ? aircraftModel.startingPrice
          : getSeatPrice(route.seats);

      let aircraftType =
        aircraft.aircraftTypeId === 1 ? 'helicopters' : 'airplanes';

      // get category
      if (category)
        filterValues[aircraftType].categoriesList.push({
          id: category,
          text: category,
          selected: true,
        });

      // get model
      if (model)
        filterValues[aircraftType].modelsList.push({
          id: aircraft.id,
          text: model,
          selected: true,
        });

      // get prices
      if (startingPrice < filterValues[aircraftType].price.min)
        filterValues[aircraftType].price.min = startingPrice;

      if (startingPrice > filterValues[aircraftType].price.max)
        filterValues[aircraftType].price.max = startingPrice;
    });
  });

  // get distincted arrays
  filterValues.airplanes.categoriesList = distinctArrayFromObjectProperty(
    'id',
    filterValues.airplanes.categoriesList
  );
  filterValues.airplanes.modelsList = distinctArrayFromObjectProperty(
    'id',
    filterValues.airplanes.modelsList
  );
  filterValues.helicopters.categoriesList = distinctArrayFromObjectProperty(
    'id',
    filterValues.helicopters.categoriesList
  );
  filterValues.helicopters.modelsList = distinctArrayFromObjectProperty(
    'id',
    filterValues.helicopters.modelsList
  );

  filterValues.airplanes.price.value.min = filterValues.airplanes.price.min;
  filterValues.airplanes.price.value.max = filterValues.airplanes.price.max;
  filterValues.helicopters.price.value.min = filterValues.helicopters.price.min;
  filterValues.helicopters.price.value.max = filterValues.helicopters.price.max;
};

/**
 * Returns the filter's values
 *
 */
const getFilterValues = () => {
  return filterValues;
};

/**
 * Updates the filter's values
 *
 * @param {any} values
 */
const updateFilterValues = values => {
  filterValues = { ...filterValues, ...values };
};

/**
 * Executes the filter's method
 *
 * @param {object} data
 */
const applyFilter = data => {
  let routes = [...data];

  const categoriesIds = [
    ...filterValues.airplanes.categoriesList,
    ...filterValues.helicopters.categoriesList,
  ]
    .filter(catg => catg.selected)
    .map(catg => {
      return catg.id;
    });

  const modelsIds = [
    ...filterValues.airplanes.modelsList,
    ...filterValues.helicopters.modelsList,
  ]
    .filter(modl => modl.selected)
    .map(modl => {
      return modl.id;
    });

  routes = routes.reduce((reducedRoutes, route) => {
    let models = [];

    models = route.aircraftModels.reduce((reducedModels, model) => {
      let validAircraft = true;
      let aircraftType =
        model.aircraftModel.aircraftTypeId === 1 ? 'helicopters' : 'airplanes';
      let lowerPrice =
        filterValues.flightType === 'charter'
          ? model.startingPrice
          : getSeatPrice(route.seats, 'min');
      let higherPrice =
        filterValues.flightType === 'charter'
          ? model.startingPrice
          : getSeatPrice(route.seats, 'max');
      let categoryId = model.aircraftModel.size.name;
      let modelId = model.aircraftModel.id;

      validAircraft =
        filterValues[aircraftType].price.value.min <= lowerPrice &&
        filterValues[aircraftType].price.value.max >= higherPrice &&
        categoriesIds.includes(categoryId) &&
        modelsIds.includes(modelId);

      if (validAircraft) reducedModels.push({ ...model });

      return reducedModels;
    }, []);

    if (models.length) reducedRoutes.push({ ...route, aircraftModels: models });

    return reducedRoutes;
  }, []);

  return routes;
};

const filterByAircraft = {
  initFilterValues,
  getFilterValues,
  updateFilterValues,
  applyFilter,
};

export default filterByAircraft;
