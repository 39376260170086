// Third party libs/components
import React from 'react';

// Components
import * as Styled from './SearchLoading.style';
import SpinningWheel from '@components/SpinningWheel';

// Utils
import { translate as t } from '@utils/translate';

const SearchLoading = ({ origin, destination }) => {
  const TEXTS = {
    LOADING_MESSAGE: t('loadingAircrafts'),
    SECONDARY_MESSAGE: t('loadingRoutes'),
  };

  return (
    <Styled.SearchLoading>
      <Styled.SearchLoadingContent>
        <Styled.MainMessage>
          {TEXTS.LOADING_MESSAGE}
          <Styled.OriginToDestination>{`${origin} → ${destination}`}</Styled.OriginToDestination>
        </Styled.MainMessage>
        <SpinningWheel />
        <Styled.SecondaryMessage>
          {TEXTS.SECONDARY_MESSAGE}
        </Styled.SecondaryMessage>
      </Styled.SearchLoadingContent>
    </Styled.SearchLoading>
  );
};

export default SearchLoading;
