// Third party libs/components
import React from 'react';
import P from 'prop-types';

// Components
import * as Styled from './SectionGroupContainer.style';

const SectionGroupContainer = ({ title, children, showTitle = true }) => {
  return (
    <Styled.SectionGroupContainer>
      {showTitle && <Styled.Title>{title}</Styled.Title>}
      {children}
    </Styled.SectionGroupContainer>
  );
};

SectionGroupContainer.propTypes = {
  title: P.string,
  children: P.arrayOf(P.element),
};

export default SectionGroupContainer;
