import { apiHost, shortLink } from '@utils/constants';
import useRequest from '@utils/useRequest';

/**
 * @param {string} link a url link to be shortened
 * @returns
 */
export const getShortLink = async link => {
  try {
    const url = apiHost + shortLink;

    const msgBody = {
      url: link,
    };

    const { data } = await useRequest({
      url: url,
      method: 'post',
      useSession: false,
      data: JSON.stringify(msgBody),
    });

    return data;
  } catch (error) {
    return error;
  }
};
