// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';

import FileInput from '../FormInputs/FileInput';

const HeaderProfile = ({ avatarSrc, name, place, onChangeAvatar }) => {
  const homeIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="15.681" height="13.328" viewBox="0 0 15.681 13.328"><defs><style>.a{fill:#00bca3;}</style></defs><g transform="translate(0 0)"><path class="a" d="M7.84,32,0,39.056H2.352v6.272h3.92v-4.7H9.408v4.7h3.92V39.056h2.352Z" transform="translate(0 -32)"/></g></svg>';
  const [avatar, setAvatar] = useState(
    avatarSrc ? avatarSrc : '/userPlaceholder.png'
  );

  const handleChange = event => {
    if (
      event.target.files.length &&
      event.target.files[0].type.includes('image/')
    ) {
      setAvatar(URL.createObjectURL(event.target.files[0]));
      onChangeAvatar(event.target.files[0]);
    }
  };

  const photoIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22.549 20.588"><defs><style>.photo-a{opacity:0.86;}.photo-b{fill:#bdbdbd;}</style></defs><g class="photo-a"><g transform="translate(0 0)"><path class="photo-b" d="M81.647,87.294H78.539l-1.794-1.961H69.882v2.941H66.941v2.941H64v9.8a1.967,1.967,0,0,0,1.961,1.961H81.647a1.967,1.967,0,0,0,1.961-1.961V89.255A1.967,1.967,0,0,0,81.647,87.294ZM73.8,100.039a4.9,4.9,0,1,1,4.9-4.9A4.9,4.9,0,0,1,73.8,100.039Z" transform="translate(-61.059 -82.392)"/><path class="photo-b" d="M2.941,29.176H4.9V26.235H7.843V24.274H4.9V21.333H2.941v2.941H0v1.961H2.941Z" transform="translate(0 -21.333)"/><path class="photo-b" d="M212.2,230.4a3.134,3.134,0,0,0-3.137,3.137h0A3.137,3.137,0,1,0,212.2,230.4Z" transform="translate(-199.458 -220.792)"/></g></g></svg>';

  return (
    <div className={Style.container}>
      <div className={Style.avatar_container}>
        <img className={Style.avatar} src={avatar} alt={name} title={name} />
        <div className={Style.file}>
          <FileInput onChange={handleChange} name="avatar" accept="image/*" />
        </div>
        <div className={Style.hover}>
          <i dangerouslySetInnerHTML={{ __html: photoIcon }} />
        </div>
      </div>
      <div>
        <p className={Style.name}> {name} </p>
        {place && (
          <p className={Style.place}>
            {' '}
            <i dangerouslySetInnerHTML={{ __html: homeIcon }} /> {place}{' '}
          </p>
        )}
      </div>
    </div>
  );
};

export default HeaderProfile;
