import styled, { css } from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  text-align: left;
  margin-bottom: 14px;
  padding-right: 8px;
  cursor: pointer;
`;

export const Checkbox = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid #ecedf2;
  border-radius: 8px;
  background-color: #fff;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  flex-shrink: 0;
  margin-right: 10px;

  ${({ selected }) => {
    if (selected) {
      return css`
        border-color: #00bda4;
        background-color: #00bda4;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 60%;
      `;
    }
  }}
`;

export const Text = styled.div`
  padding-top: 2px;
  font-size: 14px;
  font-weight: 600;
  color: #b0b0bc;

  white-space: nowrap;
  position: relative;
  height: 1.4em;
  overflow: hidden;
  flex-grow: 1;

  > span {
    display: inline-block;
    min-width: 100%;
    position: absolute;
    transition: left 0.5s linear, text-indent 0.5s linear;
    left: 0;
    text-indent: 0;
  }

  &:hover > span {
    left: 100%;
    text-indent: -100%;
  }
`;
