// Third party libs/components
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import SearchResultsHeader from '@components/SearchResultsHeader';
import FlightResultSection from '@components/FlightResultSection';
import SharedFlight from '@components/SharedFlight';
import SearchLoading from './SearchLoading';
import SearchResultsAircraft from '../SearchResultsAircraft';

// Utils
import { translate as t } from '@utils/translate';
// Services
import useViewModel from './useViewModel';

const Search = () => {
  const {
    searchResults,
    searchFilteredResults,
    searchHeaderProps,
    routeParams,
    selectedModelId,
    handleSelectAircraft,
    selectRoute,
  } = useViewModel();

  if (!searchResults.routes) {
    return (
      <SearchLoading
        origin={searchHeaderProps.origin.name}
        destination={searchHeaderProps.destination.name}
      />
    );
  }

  if (
    routeParams.flightType === 'flights' &&
    searchFilteredResults.routes?.length
  ) {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <SearchResultsHeader {...searchHeaderProps} />
        <SharedFlight
          searchParams={routeParams}
          flights={searchFilteredResults.routes}
        />
      </>
    );
  } else if (routeParams.flightType !== 'flights') {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>

        <SearchResultsHeader {...searchHeaderProps} />

        {!searchFilteredResults?.routes?.length || true === 0 ? (
          <h1
            style={{
              marginTop: '160px',
              display: 'grid',
              width: '100%',
              height: '500px',
              placeContent: 'center',
              textAlign: 'center',
            }}
          >
            {t('noResultFound')}
          </h1>
        ) : null}
        {searchFilteredResults.routes &&
        searchFilteredResults.routes.length === 1 ? (
          <SearchResultsAircraft
            data={searchFilteredResults}
            modelId={selectedModelId}
            onSelectAircraft={handleSelectAircraft}
          />
        ) : (
          <>
            {searchFilteredResults.routes &&
              searchFilteredResults.routes.map((route, index) => (
                <FlightResultSection
                  key={index}
                  initiallyOpened={searchFilteredResults.routes.length === 1}
                  originSpot={route.originSpot}
                  destinationSpot={route.destinationSpot}
                  flightsList={route.aircraftModels || [route.aircraft]}
                  mappedRouteIndex={route.mappedIndex}
                  onSelect={selectRoute}
                />
              ))}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <SearchResultsHeader {...searchHeaderProps} />

      <h1
        style={{
          marginTop: '160px',
          display: 'grid',
          width: '100%',
          height: '500px',
          placeContent: 'center',
          textAlign: 'center',
        }}
      >
        {t('noResultFound')}
      </h1>
    </>
  );
};

export default Search;
