import styled, { keyframes } from 'styled-components';

const fadeDown = keyframes`
  from{
    opacity: 0;
    transform: translateY(100%);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Aircraft = styled.div`
  cursor: auto;
  text-align: left;
  /* opacity: 0; */

  /* animation: ${fadeDown} 0.3s 0.2s ease-in-out forwards; */

  .tabsHeader {
    padding-bottom: 0;
    margin-bottom: 21px;
  }

  .tabsHeader > div {
    font-size: 16px;
    border-bottom-width: 4px;
    font-weight: 600;
  }

  @media (max-width: 1350px) {
    padding: 16px 0 0;
    padding: 39px 52px 44px;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

  > div > div {
    margin-right: 34px;
    max-width: 230px;
    overflow-x: hidden !important;
  }

  @media (max-width: 1350px) {
    flex-wrap: wrap;

    > div > div {
      margin-right: 0%;
      max-width: 100%;
      overflow-x: hidden !important;
    }
  }
`;

export const PriceContainer = styled.div`
  display: flex;

  @media (max-width: 1350px) {
    display: block;
  }
`;

export const AutonomyContainer = styled.div`
  display: flex;

  @media (max-width: 1350px) {
    display: block;
  }
`;

export const SeatsContainer = styled.div`
  display: flex;

  @media (max-width: 1350px) {
    display: block;
  }
`;
