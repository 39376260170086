// Third party libs/components
import React, { useEffect, useState, useLayoutEffect } from 'react';

// Styles
import * as Styled from './SearchSalesMainContent.style';

// Utils
import { translate as t, useCurrentLang } from '@utils/translate';

// Components
import Toggle from '@components/Toggle';
import TextToggle from '@components/TextToggle';

const SearchSalesMainContent = () => {
  const [size, setSize] = useState(window.innerWidth);
  const [leftListContent, setLeftListContext] = useState();
  const [rightListContent, setRightListContext] = useState();

  const lang = useCurrentLang();

  useEffect(() => {
    const [list1, list2] = [
      <ol className="dropdown-flex-list">
        {t('aircraftSalePageLeftListContent')
          .split(';')
          .map(item => (
            <li className="base-font-1">{item}</li>
          ))}
      </ol>,
      <ol className="dropdown-flex-list">
        {t('aircraftSalePageRightListContent')
          .split(';')
          .map(item => (
            <li className="base-font-1">{item}</li>
          ))}
      </ol>,
    ];

    setLeftListContext(list1);
    setRightListContext(list2);

    return () => {
      setLeftListContext(null);
      setRightListContext(null);
    };
  }, [lang]);

  useLayoutEffect(() => {
    window.addEventListener('resize', () => setSize(window.innerWidth));
    return () => {
      setSize(null);
      window.removeEventListener('resize', () => setSize(window.innerWidth));
    };
  }, []);

  return (
    <Styled.InfoHeaderContainer>
      {size > 768 ? (
        <p className="main-title">{t('aircraftSalePageTitle')}</p>
      ) : null}
      <p className="base-font-2 main-subtitle">
        {t('aircraftSalePageSubtitle')}
      </p>
      <p className="main-suport-text">{t('aircraftSalePageSuptext')}</p>

      <Toggle title={t('aircraftSalePageToggleTitle')}>
        <div className="toogle-view-container">
          <p className="base-font-1 toogle-view-title">
            {t('aircraftSalePageToggleContentTitle')}
          </p>
          <p className="base-font-2 toogle-view-subtitle">
            {t('aircraftSalePageToggleContentSubtitle')}
          </p>
          <p className="base-font-2 toogle-view-suport-text">
            {t('aircraftSalePageToggleContentSuptext')}
          </p>
          {size > 768 ? (
            <div className="dropdown-flex-box">
              <div>
                <p className="base-font-1 dropdown-flex-title left">
                  {t('aircraftSalePageLeftListTitle')}
                </p>
                {leftListContent}
              </div>
              <div>
                <p className="dropdown-flex-title right">
                  {t('aircraftSalePageRightListTitle')}
                </p>
                {rightListContent}
              </div>
            </div>
          ) : (
            <div className="dropdown-flex-box">
              <TextToggle title={t('aircraftSalePageLeftListTitle')}>
                {leftListContent}
              </TextToggle>
              <div>
                <TextToggle title={t('aircraftSalePageRightListTitle')}>
                  {rightListContent}
                </TextToggle>
              </div>
            </div>
          )}
        </div>
      </Toggle>
    </Styled.InfoHeaderContainer>
  );
};

export default SearchSalesMainContent;
