// TODO: REFACTORING
import React, { useState, useEffect, useMemo } from 'react';
import Style from './style.module.css';
import FlightCancelation from '../FlightCancelation';
import FlightPolicies from '../FlightPolicies';

import { useCurrentLang } from '../../utils/translate';
import { useFlightPoliciesData } from './use-flight-policies-data';

const SectionFlightPolicies = ({
  cancelations,
  policies,
  totalPrice,
  currency,
}) => {
  const flightPolicies = {
    policiesGroups: useFlightPoliciesData(useCurrentLang()),
  };

  const cancellationFeeInfo = useMemo(() => {
    return (
      <FlightCancelation
        {...cancelations}
        totalPrice={totalPrice}
        currency={currency}
      />
    );
  }, [totalPrice, currency, cancelations]);

  return (
    <div className={Style.sectionFlightPolicies}>
      {cancellationFeeInfo}
      {/* <FlightPolicies {...flightPolicies} /> */}
    </div>
  );
};

export default SectionFlightPolicies;
