// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';

// utils
import { translate as t } from '@utils/translate';

const UserTermsModal = ({ handleQuotation, closeModal }) => {
  const TEXTS = {
    TERMS_TITLE: t('quotationTermsTitle'),
    TERMS_POLICY_P1: t('quotationTermsPolicyP1'),
    TERMS_POLICY_P2: t('quotationTermsPolicyP2'),
    TERMS_INFO_TITLE: t('quotationTermsInfoTitle'),
    TERMS_INFO_TEXT: t('quotationTermsInfoText'),
    TERMS_PERSONAL_INFO_TITLE: t('quotationTermsPersonalInfoTitle'),
    TERMS_PERSONAL_INFO_TEXT: t('quotationTermsPersonalInfoText'),
    TERMS_PAYMENT_TITLE: t('quotationTermsPaymentTitle'),
    TERMS_PAYMENT_TEXT: t('quotationTermsPaymentText'),
    TERMS_SUPPORT_TITLE: t('quotationTermsSupportTitle'),
    TERMS_SUPPORT_TEXT: t('quotationTermsSupportText'),
    TERMS_COOKIES_TITLE: t('quotationTermsCookiesTitle'),
    TERMS_COOKIES_TEXT: t('quotationTermsCookiesText'),
    TERMS_ACCPET_CHECKBOX: t('quotationTermsAcceptCheckbox'),
    TERMS_PROCEED: t('proceed'),
    BACK: t('back'),
  };
  const [acceptTerms, setAcceptTerms] = useState(false);
  const modalData = {
    title: TEXTS.TERMS_TITLE,
    privacyPolicyP1: TEXTS.TERMS_POLICY_P1,
    privacyPolicyP2: TEXTS.TERMS_POLICY_P2,
    informationsTitle: TEXTS.TERMS_INFO_TITLE,
    informationText: TEXTS.TERMS_INFO_TEXT,
    personalInformationTitle: TEXTS.TERMS_PERSONAL_INFO_TITLE,
    personalInformationText: TEXTS.TERMS_PERSONAL_INFO_TEXT,
    paymentsTitle: TEXTS.TERMS_PAYMENT_TITLE,
    paymentsText: TEXTS.TERMS_PAYMENT_TEXT,
    supportTitle: TEXTS.TERMS_SUPPORT_TITLE,
    supportText: TEXTS.TERMS_SUPPORT_TEXT,
    cookiesTitle: TEXTS.TERMS_COOKIES_TITLE,
    cookiesText: TEXTS.TERMS_COOKIES_TEXT,
    cookiesText: TEXTS.TERMS_COOKIES_TEXT,
    acceptTermsButtonText: TEXTS.TERMS_PROCEED,
  };

  const createProposal = () => {
    setAcceptTerms(false);
    handleQuotation();
  };

  return (
    <div className={Style.modalContainer}>
      <div className={Style.modal}>
        <div className={Style.modalTitle}>
          <span onClick={closeModal} className={Style.modalClose}>
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#727272"
              viewBox="0 0 492.004 492.004"
            >
              {' '}
              <polygon points="512,236.011 76.526,236.011 248.43,64.106 220.162,35.838 0,256 220.162,476.162 248.43,447.894 76.526,275.989 512,275.989" />
            </svg>
            {TEXTS.BACK}
          </span>
          <span className={Style.title}>{modalData.title}</span>
        </div>
        <div className={Style.modalContent}>
          <p className={Style.privacyPolicy}>{modalData.privacyPolicyP1}</p>
          <p className={Style.privacyPolicy}>{modalData.privacyPolicyP2}</p>

          <h4>{modalData.informationsTitle}</h4>

          <p className={Style.modalText}>{modalData.informationText}</p>
          <h4 className={Style.subTitle}>
            {modalData.personalInformationTitle}
          </h4>
          <p className={Style.modalText}>{modalData.personalInformationText}</p>

          <h4 className={Style.subTitle}>{modalData.paymentsTitle}</h4>
          <p className={Style.modalText}>{modalData.paymentsText}</p>

          <h4 className={Style.subTitle}>{modalData.supportTitle}</h4>
          <p className={Style.modalText}>{modalData.supportText}</p>

          <h4 className={Style.subTitle}>{modalData.cookiesTitle}</h4>
          <p className={Style.modalText}>{modalData.cookiesText}</p>
        </div>
        <div className={Style.modalFooter}>
          <div className={Style.footerCheck}>
            <input
              type="checkbox"
              id="checkbox"
              onChange={() => setAcceptTerms(!acceptTerms)}
            />
            <label htmlFor="checkbox">{TEXTS.TERMS_ACCPET_CHECKBOX}</label>
          </div>
          <button
            className={Style.footerButton}
            disabled={!acceptTerms}
            type="button"
            onClick={createProposal}
          >
            {modalData.acceptTermsButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserTermsModal;
