import React from 'react';
import ArrowButton from './ArrowButton';

/**
 * Returns the settings based on its name
 *
 * @param {string} name Settings name
 */
const getSliderSettings = (name = 'small') => {
  let settingsFunction = null;

  switch (name) {
    case 'small':
      settingsFunction = getSmallSliderSeetings;
      break;
    case 'medium':
      settingsFunction = getMediumSliderSettings;
      break;
    case 'sales':
      settingsFunction = getSalesSliderSettings;
      break;

    default:
      settingsFunction = getSmallSliderSeetings;
      break;
  }

  return settingsFunction();
};

//Returns slick slider settings for small cards (4 per row over 1445px)
const getSmallSliderSeetings = () => {
  const sliderSettings = {
    slidesToShow: 4,
    infinite: false,
    arrows: true,
    variableWidth: true,
    nextArrow: <ArrowButton type={'next'} />,
    prevArrow: <ArrowButton type={'prev'} />,
    responsive: [
      {
        breakpoint: 1445,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  return sliderSettings;
};

//Returns slick slider settings for medium cards (2 per row over 1024px)
const getMediumSliderSettings = () => {
  const sliderSettings = {
    slidesToShow: 2,
    infinite: false,
    arrows: true,
    variableWidth: true,
    nextArrow: <ArrowButton type={'next'} />,
    prevArrow: <ArrowButton type={'prev'} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return sliderSettings;
};

//Returns slick slider settings for sales cards (4 per row over 1024px)
const getSalesSliderSettings = () => {
  const sliderSettings = {
    slidesToShow: 4,
    infinite: false,
    arrows: true,
    variableWidth: true,
    nextArrow: <ArrowButton type={'next'} />,
    prevArrow: <ArrowButton type={'prev'} />,
    responsive: [
      {
        breakpoint: 1445,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  return sliderSettings;
};

export default getSliderSettings;
