import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as Styled from './SharedFlightPassengers.styles';

const SharedFlightPassengers = ({
  onIncrement = () => {},
  onDecrement = () => {},
  initialValue,
  currentValue,
  maxPassengers,
  minPassengers,
  theme,
}) => {
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [value, setValue] = useState(initialValue || 0);

  useEffect(() => {
    if (currentValue) setValue(currentValue);
  }, [currentValue]);

  useEffect(() => {
    setSelectedTheme(theme);
  }, [theme]);

  const handleIncrement = () => {
    if (maxPassengers > 0 && value + 1 > maxPassengers) return;

    if (!maxPassengers && value >= 10) {
      setValue(value + 10);
      onIncrement(value + 10);
    } else {
      setValue(value + 1);
      onIncrement(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value <= 0 || (minPassengers && value <= minPassengers)) return;

    if (!maxPassengers && value - 10 >= 10) {
      setValue(value - 10);
      onDecrement(value - 10);
    } else {
      setValue(value - 1);
      onDecrement(value - 1);
    }
  };

  return (
    <Styled.Container theme={selectedTheme}>
      <Styled.Wrapper>
        <Styled.Span>{value} passageiros</Styled.Span>
        <Styled.Buttons>
          <button
            onClick={handleDecrement}
            disabled={value <= 0 || (minPassengers && value <= minPassengers)}
          >
            {' '}
            -{' '}
          </button>
          <button
            onClick={handleIncrement}
            disabled={maxPassengers && value >= maxPassengers}
          >
            {' '}
            +{' '}
          </button>
        </Styled.Buttons>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

SharedFlightPassengers.propTypes = {
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  initialValue: PropTypes.number.isRequired,
  currentValue: PropTypes.number.isRequired,
  minPassengers: PropTypes.number.isRequired,
  maxPassengers: PropTypes.number.isRequired,
  theme: PropTypes.string.isRequired,
};

export default SharedFlightPassengers;
