import styled from 'styled-components';

export const FlightResultSection = styled.section`
  margin-bottom: 26px;

  &:first-of-type {
    margin-top: calc(var(--search-results-header-height) + 32px);
  }

  @media (max-width: 1023px) {
    &:first-of-type {
      margin-top: calc(var(--search-results-header-height) + 23px);
    }
  }
`;

export const LabelsContainer = styled.div``;

export const OriginLabelWrapper = styled.div`
  color: #39383d;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 9px;

  @media (max-width: 1023px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export const DestinationLabelWrapper = styled.div`
  color: #00897b;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;

  @media (max-width: 1023px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const Label = styled.span`
  font-weight: 700;
`;

export const GridSlideContainer = styled.div``;
