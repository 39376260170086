import { Creators } from './actions';

export const reset = Creators.reset;
export const updateFilters = Creators.updateFilters;
export const updateGroupBy = Creators.updateGroupBy;
export const updateCurrentTab = Creators.updateCurrentTab;
export const setInitialAutonomy = Creators.setInitialAutonomy;
const initFilters = Creators.initFilters;

import { distinctArrayFromObjectProperty } from '@utils/arrays';
import IAircraftFilterOptions from '@utils/interfaces/aircraft-filter-options.interface';

const AIRPLANES = 'airplanes';
const HELICOPTERS = 'helicopters';
const GROUPS_LIST = 'groupsList';
const MODELS_LIST = 'modelsList';

export const mapInitialFilters = (
  data,
  selectedGroupBy,
  dispatchInitFilters = true
) => {
  return (dispatch, getState) => {
    const filterValues = structuredClone({
      ...IAircraftFilterOptions,
      airplanes: {
        ...IAircraftFilterOptions.airplanes,
        producersList: getState().filters.sales.airplanes.producersList,
      },
      helicopters: {
        ...IAircraftFilterOptions.helicopters,
        producersList: getState().filters.sales.helicopters.producersList,
      },
    });
    filterValues.groupBy = selectedGroupBy;

    if (data.aircrafts) {
      const airplanes = data.aircrafts[0];
      const helicopters = data.aircrafts[1];

      const mapGroupsList = (value, group) => {
        filterValues[group].groupsList.push({
          id: value,
          text: value,
          selected: true,
          enabled: true,
        });
      };

      const mapProducers = (value, group) => {
        if (filterValues.groupBy === 'producers') {
          filterValues[group].producersList.push({
            id: value,
            text: value,
            selected: true,
            enabled: true,
          });

          filterValues[group].producersList = filterValues[
            group
          ].producersList.sort((a, b) => (a.text > b.text ? 1 : -1));
        }
      };

      const mapModels = (item, group) => {
        item.aircrafts.forEach(aircraft => {
          const model =
            aircraft.model.manufacturer + ' ' + aircraft.model.model;

          filterValues[group].modelsList.push({
            id: aircraft.model.id,
            text: model,
            selected: true,
            producer: aircraft.model.producer.name,
            autonomy: aircraft.autonomy,
            seats: aircraft.seats,
          });
        });

        filterValues[group].modelsList = filterValues[
          group
        ].modelsList.sort((a, b) => (a.text > b.text ? 1 : -1));
      };

      const mapLists = (items, group) => {
        if (filterValues.groupBy === 'producers') {
          filterValues[group].producersList = [];
        }
        Object.keys(items).map(value => {
          const item = items[value];

          mapProducers(value, group);
          mapGroupsList(value, group);
          mapModels(item, group);
        });
      };

      const mapPrices = (items, group) => {
        Object.keys(items).map(value => {
          const item = items[value];

          item.aircrafts.forEach(aircraft => {
            const price = aircraft.unformattedPrice;

            if (price < filterValues[group].price.min)
              filterValues[group].price.min = price;

            if (price > filterValues[group].price.max)
              filterValues[group].price.max = price;
          });
        });

        filterValues[group].price.value.min = filterValues[group].price.min;
        filterValues[group].price.value.max = filterValues[group].price.max;
      };

      const mapAutonomy = (items, group) => {
        Object.keys(items).map(value => {
          const item = items[value];

          item.aircrafts.forEach(aircraft => {
            const autonomy = aircraft.autonomy;

            if (autonomy < filterValues[group].autonomy.min)
              filterValues[group].autonomy.min = autonomy;

            if (autonomy > filterValues[group].autonomy.max)
              filterValues[group].autonomy.max = autonomy;
          });
        });

        if (dispatchInitFilters) {
          filterValues[group].originalAutonomy.min =
            filterValues[group].autonomy.min;
          filterValues[group].originalAutonomy.max =
            filterValues[group].autonomy.max;
        }

        filterValues[group].autonomy.value.min =
          filterValues[group].autonomy.min;
        filterValues[group].autonomy.value.max =
          filterValues[group].autonomy.max;
      };

      const mapSeats = (items, group) => {
        Object.keys(items).map(value => {
          const item = items[value];

          item.aircrafts.forEach(aircraft => {
            const seats = Number(aircraft.seats);

            if (seats < filterValues[group].seats.min) {
              filterValues[group].seats.min = seats;
            }

            if (seats > filterValues[group].seats.max) {
              filterValues[group].seats.max = seats;
            }
          });
        });

        if (dispatchInitFilters) {
          filterValues[group].originalSeats.min = filterValues[group].seats.min;
          filterValues[group].originalSeats.max = filterValues[group].seats.max;
        }

        filterValues[group].seats.value.min = filterValues[group].seats.min;
        filterValues[group].seats.value.max = filterValues[group].seats.max;
      };

      const distinctValues = (group, subgroup) => {
        filterValues[group][subgroup] = distinctArrayFromObjectProperty(
          'id',
          filterValues[group][subgroup]
        );
      };

      mapLists(airplanes.items, AIRPLANES);
      mapPrices(airplanes.items, AIRPLANES);
      mapAutonomy(airplanes.items, AIRPLANES);
      mapSeats(airplanes.items, AIRPLANES);

      mapLists(helicopters.items, HELICOPTERS);
      mapPrices(helicopters.items, HELICOPTERS);
      mapAutonomy(helicopters.items, HELICOPTERS);
      mapSeats(helicopters.items, HELICOPTERS);

      // get distincted arrays
      distinctValues(AIRPLANES, GROUPS_LIST);
      distinctValues(AIRPLANES, MODELS_LIST);
      distinctValues(HELICOPTERS, GROUPS_LIST);
      distinctValues(HELICOPTERS, MODELS_LIST);
    }

    if (dispatchInitFilters) {
      dispatch(initFilters(filterValues));
      return true;
    } else {
      return filterValues;
    }
  };
};

export const applyFilters = (data, newFilterValues = null) => {
  // TODO quando o groupBy for categoria, filtrar também por fabricante
  return (dispatch, getState) => {
    const filterValues = newFilterValues || getState().filters.sales;
    const currentTab = getState().filters.sales.currentTab;
    const groupBy = filterValues.groupBy;
    let aircraftGroups = data.aircrafts;
    let filteredGroups = data.aircrafts;

    let groupIds =
      currentTab === 'airplanes'
        ? [...filterValues.airplanes.producersList]
        : [...filterValues.helicopters.producersList];

    groupIds = groupIds
      .filter(catg => catg?.selected)
      .map(catg => {
        return catg.id;
      });

    const modelsIds = [
      ...filterValues.airplanes.modelsList,
      ...filterValues.helicopters.modelsList,
    ]
      .filter(modl => modl?.selected)
      .map(modl => {
        return modl.id;
      });

    const filterItems = (groupItems, groupName) => {
      const filteredItems = {};

      const checkPrice = price => {
        return (
          filterValues[groupName].price.value.min <= price &&
          filterValues[groupName].price.value.max >= price
        );
      };

      const checkAutonomy = autonomy => {
        return (
          filterValues[groupName].autonomy.value.min <= autonomy &&
          filterValues[groupName].autonomy.value.max >= autonomy
        );
      };

      const checkSeats = (minSeats, maxSeats) => {
        return (
          (filterValues[groupName].seats.value.min <= minSeats &&
            filterValues[groupName].seats.value.max >= minSeats) ||
          (filterValues[groupName].seats.value.min <= maxSeats &&
            filterValues[groupName].seats.value.max >= maxSeats)
        );
      };

      const checkGroups = groupId => {
        return groupIds.includes(groupId);
      };

      const checkModels = modelId => {
        return modelsIds.includes(modelId);
      };

      const getPropName = () => {
        let propName = '';
        switch (groupBy) {
          case 'producers':
            propName = 'producer';
            break;
          case 'categories':
            propName = 'producer';
            break;
          default:
            propName = 'producer';
            break;
        }

        return propName;
      };

      Object.keys(groupItems).forEach(key => {
        const items = [];

        groupItems[key].aircrafts.forEach(aircraft => {
          let validAircraft = true;
          const price = aircraft.unformattedPrice;
          const autonomy = aircraft.model.maxRange;
          const groupId = aircraft.model[getPropName()].name.replace(
            ' helicopter',
            ''
          );
          const modelId = aircraft.model.id;
          const minSeats = aircraft.model.minmax_seats[0];
          const maxSeats = aircraft.model.minmax_seats[1];

          validAircraft =
            checkPrice(price) &&
            checkAutonomy(autonomy) &&
            checkSeats(minSeats, maxSeats) &&
            checkGroups(groupId) &&
            checkModels(modelId);

          if (validAircraft) items.push(aircraft);
        });

        if (items.length) filteredItems[key] = { aircrafts: items };
      });

      return filteredItems;
    };

    filteredGroups[0] = {
      ...filteredGroups[0],
      items: filterItems(aircraftGroups[0].items, AIRPLANES),
    };

    filteredGroups[1] = {
      ...filteredGroups[1],
      items: filterItems(aircraftGroups[1].items, HELICOPTERS),
    };

    return filteredGroups;
  };
};

export const updateFilterOptions = (filterValues, data) => {
  const currentTab = filterValues.currentTab;
  const handleBySelect = filterValues.handleBySelect;

  delete filterValues.handleBySelect;

  return dispatch => {
    if (data.aircrafts) {
      const updatedFilterValues = dispatch(
        mapInitialFilters(data, null, false)
      );

      const deactivateAirplaneModels = groupName => {
        const currentModelIds = [...filterValues[groupName].modelsList].map(
          modl => {
            return modl.id;
          }
        );
        const newModelIds = [...updatedFilterValues[groupName].modelsList].map(
          modl => {
            return modl.id;
          }
        );
        const diff = currentModelIds.filter(model => {
          return !newModelIds.includes(model);
        });

        filterValues[groupName].modelsList.forEach(model => {
          model.enabled = false;
        });
      };

      const aircraftsSelected = filterValues[currentTab].modelsList.filter(
        aircraft => {
          const producer = filterValues[currentTab].producersList.find(
            group => group.text === aircraft.producer
          );

          if (producer) {
            return !!producer.selected && aircraft.selected;
          } else {
            return true;
          }
        }
      );

      const hasOneAircraftSelected = aircraftsSelected.length === 1;

      const handleAutonomy = () => {
        const aircraftsAutonomy = aircraftsSelected.map(
          aircraft => aircraft.autonomy
        );

        const maxAircraftAutonomy = aircraftsAutonomy.reduce(function(a, b) {
          return Math.max(a, b);
        }, 0);
        const minAircraftAutonomy = aircraftsAutonomy.reduce(function(a, b) {
          return Math.min(a, b);
        }, maxAircraftAutonomy);

        let valueMin = filterValues[currentTab].autonomy.value.min;
        let valueMax = filterValues[currentTab].autonomy.value.max;

        valueMax =
          valueMax > maxAircraftAutonomy ? maxAircraftAutonomy : valueMax;
        valueMin =
          valueMin < minAircraftAutonomy ? minAircraftAutonomy : valueMin;

        if (!hasOneAircraftSelected) {
          filterValues[currentTab].autonomy = {
            max: maxAircraftAutonomy,
            min: minAircraftAutonomy,
            value: {
              max: handleBySelect ? maxAircraftAutonomy : valueMax,
              min: handleBySelect ? minAircraftAutonomy : valueMin,
            },
          };
        } else {
          const originalMaxAutonomy =
            filterValues[currentTab].originalAutonomy.max;
          const originalMinAutonomy =
            filterValues[currentTab].originalAutonomy.min;

          filterValues[currentTab].autonomy = {
            max: originalMaxAutonomy,
            min: originalMinAutonomy,
            value: {
              max: originalMaxAutonomy,
              min: originalMinAutonomy,
            },
          };
        }
      };

      const handleSeats = () => {
        const aircraftsSeats = aircraftsSelected.map(
          aircraft => aircraft.seats
        );

        const maxAircraftSeats = aircraftsSeats.reduce(function(a, b) {
          return Math.max(a, b);
        }, 0);
        const minAircraftSeats = aircraftsSeats.reduce(function(a, b) {
          return Math.min(a, b);
        }, maxAircraftSeats);

        let valueMin = filterValues[currentTab].seats.value.min;
        let valueMax = filterValues[currentTab].seats.value.max;

        valueMax = valueMax > maxAircraftSeats ? maxAircraftSeats : valueMax;
        valueMin = valueMin < minAircraftSeats ? minAircraftSeats : valueMin;

        const setMinValue = handleBySelect ? minAircraftSeats : valueMin;
        const setMaxValue = handleBySelect ? maxAircraftSeats : valueMax;

        if (setMinValue !== setMaxValue) {
          filterValues[currentTab].seats = {
            max: maxAircraftSeats,
            min: minAircraftSeats,
            value: {
              max: setMaxValue,
              min: setMinValue,
            },
          };
        } else {
          const minOriginalSeats = filterValues[currentTab].seats.min;
          const maxOriginalSeats = filterValues[currentTab].seats.max;

          filterValues[currentTab].seats = {
            max: maxOriginalSeats,
            min: minOriginalSeats,
            value: {
              max: maxOriginalSeats,
              min: minOriginalSeats,
            },
          };
        }
      };

      handleAutonomy();
      handleSeats();

      deactivateAirplaneModels('airplanes');

      dispatch(updateFilters(filterValues));
    }

    return;
  };
};
