import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useParams, navigate } from '@reach/router';

// Utils
import * as Helpers from './utils/Search.helper';
import * as Filters from './utils/filter';
import * as Sort from './utils/sort';
import { gtmDispatcher } from '@utils/gtm.helper';
import { encodeObjectToken } from '@utils/token';
import { flightSearchSecret } from '@utils/secrets';

// Services
import * as Services from '@services/search';
import { analyticsGTAG } from '@utils/ga.helper';
import { getFilterValues } from './utils/filter/index';

const useViewModel = () => {
  const path = location.pathname;
  const [searchResults, setSearchResults] = useState({});
  const [searchFilteredResults, setSearchFilteredResults] = useState([]);
  const [searchParams, setSearchParams] = useState();
  const [selectedModelId, setSelectedModelId] = useState();
  const routeParams = useParams();
  const store = useStore();
  const selectedCurrency = useSelector(state => state.general.currency);

  // Getting URL params
  Helpers.decodeParams(routeParams);

  routeParams.origin = Helpers.reduceLocationParams(
    routeParams.origin.split('&')
  );
  routeParams.destination = Helpers.reduceLocationParams(
    routeParams.destination.split('&')
  );

  const queryParams = new URLSearchParams(location.search);

  const aircraftModelId = queryParams.get('aircraftModelId');

  const fetchData = async () => {
    let responseData = null;

    if (routeParams.flightType === 'charter') {
      const body = {
        quotation: {
          currency: selectedCurrency.code,
          segments: [searchParams],
          aggregateBy: 'model',
        },
      };
      responseData = await Services.fetchCharteredFlights(body);
    } else {
      const range = Helpers.formatMinMaxDates({ ...routeParams });

      responseData = await Services.fetchSharedFlights({
        ...routeParams,
        ...range,
      });
    }

    if (responseData.status === 200) {
      responseData = Helpers.formatResponseForSearchResults(responseData.data);
      setSearchResults(responseData);
      setSearchFilteredResults(responseData);
    }

    return responseData;
  };

  const selectRoute = (aircraftID, routeID) => {
    analyticsGTAG('click', {
      event_category: 'Quotation',
      event_action: 'SelectAircraftModel',
      event_label: 'User select an aircraft model on search results',
    });

    gtmDispatcher('booking', {
      event_category: 'quotation',
      event_action: 'select_aircraft',
      event_label: 'User select an aircraft model on search results',
    });

    setSearchFilteredResults(prevState => {
      const findRouteByMappedIndex = r => {
        return r.mappedIndex === routeID;
      };

      const route = searchFilteredResults.routes.find(findRouteByMappedIndex);
      return {
        ...prevState,
        routes: [route],
      };
    });
    setSelectedModelId(aircraftID);
  };

  const handleBackAction = () => {
    if (selectedModelId) {
      setSearchFilteredResults(prevState => {
        return {
          ...prevState,
          routes: searchResults.routes,
        };
      });
      setSelectedModelId(null);
    } else {
      navigate('/');
    }
  };

  // Filter results
  const execFiltering = () => {
    let filteredData = [];
    filteredData = Filters.applyFilters(searchResults.routes);
    setSearchFilteredResults(prevState => {
      return {
        ...prevState,
        routes: filteredData,
      };
    });
  };

  // Sort results
  const execSortering = (sortMethodName, sortVariantName) => {
    let sortedData = [];
    sortedData = Sort.applySortering({
      methodName: sortMethodName,
      variantName: sortVariantName,
      data: searchFilteredResults.routes,
      flightType: routeParams.flightType,
    });
    setSearchFilteredResults(prevState => {
      return {
        ...prevState,
        routes: sortedData,
      };
    });
  };

  const handleSetFilterValue = (filterMethodName, values) => {
    Filters.updateFilterValues(filterMethodName, values);
    execFiltering();
  };

  useEffect(() => {
    setSearchParams(Helpers.prepareSearchParams(routeParams));
  }, []);

  const handleSelectAircraft = async aircraft => {
    const quotationParams = {
      ...Helpers.prepareSearchParams(routeParams, {
        originSpotId: searchFilteredResults.routes[0].originSpot.id,
        destinationSpotId: searchFilteredResults.routes[0].destinationSpot.id,
      }),
      aircraftId: aircraft.aircraft.id,
    };
    const body = {
      quotation: {
        currency: selectedCurrency.code,
        segments: [quotationParams],
        displayOnlyQuoted: true,
      },
    };
    let segmentDetails = {};

    let responseData = await Services.fetchCharteredFlights(body);
    responseData = Helpers.formatResponseForSearchResults(responseData.data);

    segmentDetails = {
      origin: {
        spotId: searchFilteredResults.routes[0].originSpot.id,
      },
      destination: {
        spotId: searchFilteredResults.routes[0].destinationSpot.id,
      },
      aircraftId: aircraft.aircraft.id,
      departureDatetime: searchFilteredResults.routes[0].departureDatetime,
      routes: [...responseData.routes],
    };
    setSelectedModelId(aircraft.aircraft.id);

    const token = encodeObjectToken([segmentDetails], flightSearchSecret);
    store.dispatch(Helpers.setQuotationsData([segmentDetails]));

    analyticsGTAG('click', {
      event_category: 'Quotation',
      event_action: 'Book',
      event_label: 'User click on the button book to select the aircraft',
    });

    gtmDispatcher('booking', {
      event_category: 'charter',
      event_action: 'book',
      event_label: 'User clicks to select an aircraft',
      value: aircraft.aircraft.id,
    });

    navigate(`/p/trip-detail?st=${token}`, { replace: false });
  };

  useMemo(() => {
    if (searchParams) fetchData();
  }, [searchParams, selectedCurrency]);

  useMemo(() => {
    // apply aircraftModelId filter here
    if (searchResults.routes) {
      Filters.initFilterValues({
        data: searchResults.routes,
        flightType: routeParams.flightType,
      });

      if (aircraftModelId) {
        let filterValues = getFilterValues('byAircraft');
        filterValues.airplanes.modelsList =
          filterValues?.airplanes?.modelsList.map(model => ({
            ...model,
            selected: aircraftModelId == model.id,
          })) ?? [];
        filterValues.helicopters.modelsList =
          filterValues?.airplanes?.modelsList.map(model => ({
            ...model,
            selected: aircraftModelId == model.id,
          })) ?? [];

        Filters.updateFilterValues('byAircraft', filterValues);
        execFiltering();
      }
    }
  }, [searchResults]);

  // Creating Search Header Props
  const searchHeaderProps = {
    ...Helpers.getHeaderPropsForMultipleRoutes(routeParams, path),
    onGetFilterValues: Filters.getFilterValues,
    onUpdateFilterValues: handleSetFilterValue,
    onSortData: execSortering,
    onBackAction: handleBackAction,
    routeParams,
  };

  window.scrollTo({ top: 0 });

  return {
    searchResults,
    searchFilteredResults,
    searchHeaderProps,
    routeParams,
    selectedModelId,
    handleSelectAircraft,
    selectRoute,
  };
};

export default useViewModel;
