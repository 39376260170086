import React from 'react';
import P from 'prop-types';
import * as Styled from './CreditCardItem.styles.js';
import { FaRegTrashAlt } from 'react-icons/fa';

function CreditCardItem({ paymentMethod, onDelete }) {
  const firstDigits = paymentMethod.card?.firstDigits?.slice(0, -2);
  const lastDigits = paymentMethod.card?.lastDigits?.slice(-4);

  return (
    <Styled.Container>
      <Styled.Group>
        <Styled.Icon src={paymentMethod.icon.url} />
        <Styled.CardNumber>
          {firstDigits || '****'}&nbsp;****&nbsp;****&nbsp;
          {lastDigits || '****'}
        </Styled.CardNumber>
      </Styled.Group>

      <Styled.IconDelete onClick={() => onDelete(paymentMethod.card.id)}>
        <FaRegTrashAlt size="1.5em" color="#E0E0E0" />
      </Styled.IconDelete>
    </Styled.Container>
  );
}

CreditCardItem.propTypes = {
  paymentMethod: P.object,
  onDelete: P.func,
};

export default CreditCardItem;
