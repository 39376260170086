import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  padding: 13px 14px 10px 13px;
  padding: 15px 15px 13px 20px;

  font-size: 16px;
  color: #91939b;
  background-color: #ffffff;
  border-radius: 38px;
  box-shadow: 10px 7px 30px #1f282d24;
  font-weight: 500;
  border: 0;
  overflow-anchor: none;

  z-index: 2;
`;
