import filterByAerodrome from './filter-by-aerodrome';
import filterByAircraft from './filter-by-aircraft';
import filterBySeats from './filter-by-seats';

// Instances (objects) of each filter
const filterMethods = {
  byAerodrome: filterByAerodrome,
  byAircraft: filterByAircraft,
  bySeats: filterBySeats,
};

/**
 * Initializes each filter passing the data to be used as initial values
 *
 * @param {object} data
 */
const initFilterValues = ({ data, flightType }) => {
  for (let filterMethod in filterMethods) {
    if (
      typeof filterMethods[filterMethod] === 'object' &&
      typeof filterMethods[filterMethod].initFilterValues === 'function'
    ) {
      filterMethods[filterMethod].initFilterValues({
        data: data,
        flightType: flightType,
      });
    }
  }
};

/**
 * Returns the filter's values
 *
 * @param {string} filterMethodName
 */
const getFilterValues = filterMethodName => {
  const options = filterMethods[filterMethodName].getFilterValues();
  return options;
};

/**
 * Updates the filter's values
 *
 * @param {string} filterMethodName
 * @param {any} values
 */
const updateFilterValues = (filterMethodName, values) => {
  filterMethods[filterMethodName].updateFilterValues(values);
};

/**
 * Invoke each filter's method
 *
 * @param {object} data
 */
const applyFilters = data => {
  let filteredData = [...data];

  for (let filterMethod in filterMethods) {
    if (
      typeof filterMethods[filterMethod] === 'object' &&
      typeof filterMethods[filterMethod].applyFilter === 'function'
    ) {
      filteredData = filterMethods[filterMethod].applyFilter(filteredData);
    }
  }

  return filteredData;
};

export { initFilterValues, getFilterValues, updateFilterValues, applyFilters };
