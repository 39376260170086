import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Arrow = ({ rotate }) => {
  return (
    <S.ArrowWrap rotate={rotate}>
      <svg
        width="22"
        height="13"
        viewBox="0 0 22 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.66663L10.2929 10.9595C10.6834 11.35 11.3166 11.35 11.7071 10.9595L21 1.66663"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </S.ArrowWrap>
  );
};

Arrow.propTypes = {
  color: PropTypes.string,
  rotate: PropTypes.number,
};

Arrow.defaultProps = {
  color: '#201d20',
};

export default Arrow;
