const IFilterOptions = {
  numberOfSeats: 1,
  minSeatsAvailable: 0,
  maxSeatsAvailable: 0,
};

let filterValues = { ...IFilterOptions };

/**
 * Initializes the filter passing the data to be used as initial values
 *
 * @param {object} data
 */
const initFilterValues = ({ data }) => {
  data.forEach(route => {
    route.aircraftModels.forEach(aircraftModel => {
      aircraftModel.aircrafts.forEach(aircraft => {
        const seats = parseInt(aircraft.aircraft.quantityOfPassengers);
        if (filterValues.minSeatsAvailable > seats)
          filterValues.minSeatsAvailable = seats;
        if (filterValues.maxSeatsAvailable < seats)
          filterValues.maxSeatsAvailable = seats;
      });
    });
  });
};

/**
 * Returns the filter's values
 *
 */
const getFilterValues = () => {
  return filterValues;
};

/**
 * Updates the filter's values
 *
 * @param {any} values
 */
const updateFilterValues = values => {
  filterValues = { ...filterValues, ...values };
};

/**
 * Executes the filter's method
 *
 * @param {object} data
 */
const applyFilter = data => {
  let routes = [...data];

  routes = routes.reduce((reducedRoutes, route) => {
    let models = [];

    models = route.aircraftModels.reduce((reducedModels, model) => {
      let aircrafts = [];

      aircrafts = model.aircrafts.filter(aircraft => {
        return (
          aircraft.aircraft.quantityOfPassengers >= filterValues.numberOfSeats
        );
      }, []);

      if (aircrafts.length) reducedModels.push({ ...model, aircrafts });

      return reducedModels;
    }, []);

    if (models.length) reducedRoutes.push({ ...route, aircraftModels: models });

    return reducedRoutes;
  }, []);

  return routes;
};

const filterBySeats = {
  initFilterValues,
  getFilterValues,
  updateFilterValues,
  applyFilter,
};

export default filterBySeats;
