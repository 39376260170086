// Third party libs/components
import React from 'react';
import { useSelector } from 'react-redux';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

// Components
import * as Styled from './AircraftAutonomy.style';

// Utils
import { translate as t } from '@utils/translate';

// Assets
import InputStyle from '../inputStyle.module.css';

const AircraftAutonomy = ({ min = 0, max = 1000000000, value, onChange }) => {
  if (!(min < max)) return null;

  const inputRangeClasses = {
    activeTrack: InputStyle.activeTrack,
    disabledInputRange: InputStyle.disabledInputRange,
    inputRange: InputStyle.inputRange,
    labelContainer: InputStyle.labelContainer,
    maxLabel: InputStyle.maxLabel,
    minLabel: InputStyle.minLabel,
    slider: InputStyle.slider,
    sliderContainer: InputStyle.sliderContainer,
    track: InputStyle.track,
    valueLabel: InputStyle.valueLabel,
  };

  const TEXTS = {
    AUTONOMY: t('range'),
  };

  return (
    <Styled.AircraftAutonomy>
      <Styled.AircraftAutonomyTitle>
        {TEXTS.AUTONOMY}
      </Styled.AircraftAutonomyTitle>
      {
        <InputRange
          classNames={inputRangeClasses}
          minValue={min}
          step={1}
          maxValue={max}
          value={value}
          onChange={onChange}
          formatLabel={value => value + 'KM'}
        />
      }
    </Styled.AircraftAutonomy>
  );
};

export default AircraftAutonomy;
