import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

export const MenuModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  @media (max-width: 1280px) {
    top: 14px;
  }

  .languageOptions {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 14px;
  }

  .languageIcon {
    display: inline-block;
    width: 24px;
    height: 24px;
  }

  .languagesListContainer {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 32px;
  }

  .languagesList {
    list-style: none;
    right: 0;
    background-color: #fff;
    padding: 7px 20px 7px 7px;
    border-radius: 5px;
    border-top-right-radius: 0;
    width: max-content;
    top: calc(100% + 6px);
  }

  .languagesList li:last-of-type {
    margin-bottom: 0;
  }

  .languagesList .languageIcon {
    margin-right: 18px;
    width: 24px;
    height: 24px;
  }

  .languagesList a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .languageOptions:hover .languagesListContainer {
    display: block;
  }

  .languageLabel {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: #363636;
    text-decoration: none;
  }
`;

export const CloseMenu = styled.div`
  display: block;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  padding: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z'/%3E%3C/svg%3E");
  margin-bottom: 32px;
  margin-left: auto;
  background-repeat: no-repeat;
  background-position: 0 50%;
  cursor: pointer;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  margin-left: 34px;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: flex-end;
    margin-left: 0;
    margin-bottom: 26px;

    ${({ isLogged }) =>
      isLogged &&
      css`
        flex-direction: column-reverse;
        margin-bottom: 0;
        & > div:first-of-type {
          margin-top: 10px;
        }
      `}
  }
`;

export const LocaleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 14px;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: flex-end;

    ${({ isLogged }) =>
      isLogged &&
      css`
        display: block;
        margin-right: 0px;
      `}
  }
`;

export const LocaleContainer = styled.div``;

export const LoginOption = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: flex-end;

  > a {
    text-decoration: none;
  }

  .loginLabel {
    font-size: 14px;
    line-height: 17px;
    color: #707070;
    display: none;
  }
`;

export const UserInfosContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #00bfa5;
  padding: 4px 5px 4px 12px;
  border-radius: 20px;
`;

export const UserFirstName = styled.span`
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-right: 9px;
  margin-bottom: -2px;
`;

export const UserImageContainer = styled.div`
  width: 24px;
  height: 24px;
`;

export const LoginIconWrapper = styled.div`
  padding: 5px;
  background-color: #e4e4e4;
  line-height: 0;
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const MenuOverlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #000000cc;
  transition: background-color 0.1s ease-out;
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style: none;
  padding-left: 14px;
  align-items: flex-end;
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  padding: 12px 0 12px;
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  box-sizing: content-box;
  text-align: right;

  &:first-of-type {
    margin-left: 0;
  }

  .featured span {
    padding: 7px 17px 7px;
    border: 2px solid #00bfa5;
    border-radius: 20px;
  }

  .active {
  }
`;
