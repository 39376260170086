import React, { useState, useEffect } from 'react';
import Style from './style.module.css';
import { useSelector } from 'react-redux';
import { sprintf } from 'sprintf-js';
import { useInView } from 'react-intersection-observer';

// Assets
import RightArrow from './right.svg';
import RightArrowColor from './right-color.svg';

// Services
import { getSession } from '@services/auth';

// Components
import UserTermsModal from './UserTermsModal';
import DialogUserDecision from '@components/DialogUserDecision';
import BackButton from '@components/BackButton';
import PathCard from '@components/PathCard';
import Button from '@components/Button';

// Utils
import useRequest from '@utils/useRequest';
import { translate as t } from '@utils/translate';
import { apiHost, createProposal } from '@utils/constants';
import { dateToHour, dateToCardFormat, stringToDate } from '@utils/dates';
import { formatCurrency } from '@utils/currency';
import { analyticsGTAG } from '@utils/ga.helper';
import { gtmDispatcher } from '@utils/gtm.helper';
import { getGclidFromLS } from '@utils/gads.helper';
import { MSG_TYPE_CHECK_AVAILABILITY } from '@utils/constants';

import InfoIcon from '@images/info.png';
import { getShortLink } from '@services/getShortLink';

const Flights = ({ paths, refs }) => {
  const TEXTS = {
    FLIGHT_LABEL: t('flight'),
    DESTINATION_LABEL: t('toPlace'),
  };
  let flightNumber = 0;
  return (
    <div className={Style.flights}>
      {paths.map((flight, index) => {
        if (refs[index].pathInView) {
          flightNumber = index;
        }
      })}
      {paths.map((flight, index) => {
        const displayFlight = showFlights(index, paths.length, flightNumber);
        const displayDotsPrev = hiddenDotsPrev(
          index,
          paths.length,
          flightNumber
        );
        const displayDotsNext = hiddenDotsNext(
          index,
          paths.length,
          flightNumber
        );

        return (
          <div className={Style.flightDots} key={index}>
            <span
              className={[
                displayDotsNext ? Style.dotsFlight : Style.dotsNone,
              ].join(' ')}
            >
              ...
            </span>
            <div
              className={[
                Style.flight,
                displayFlight ? '' : Style.flightNone,
                index + 1 == paths.length ? Style.flightEnd : '',
                refs[index].pathInView ? Style.activeFlight : '',
              ].join(' ')}
            >
              <p className={Style.flightText}>
                {TEXTS.FLIGHT_LABEL} {index + 1}
              </p>
              <div className={Style.airports}>
                <p>
                  {flight.originSpot.airport
                    ? flight.originSpot.airport.acronym
                      ? flight.originSpot.airport.acronym
                      : flight.originSpot.airport.oaci
                    : flight.originSpot.helipad.oaci}
                </p>
                <img
                  src={refs[index].pathInView ? RightArrowColor : RightArrow}
                  alt={TEXTS.DESTINATION_LABEL}
                  className={Style.arrow}
                />
                <p>
                  {flight.destinationSpot.airport
                    ? flight.destinationSpot.airport.acronym
                      ? flight.destinationSpot.airport.acronym
                      : flight.destinationSpot.airport.oaci
                    : flight.destinationSpot.helipad.oaci}
                </p>
              </div>
            </div>
            <span
              className={[
                displayDotsPrev ? Style.dotsFlight : Style.dotsNone,
              ].join(' ')}
            >
              ...
            </span>
          </div>
        );
      })}
    </div>
  );

  function showFlights(index, length, flightNumber) {
    if (
      length <= 3 ||
      index == 0 ||
      index + 1 == length ||
      index == flightNumber ||
      index == (flightNumber == 0) ||
      (index + 2 == length && flightNumber + 1 == length)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function hiddenDotsPrev(index, length, flightNumber) {
    if (length > 3 && flightNumber > 1 && index == 0) {
      return true;
    } else {
      return false;
    }
  }

  function hiddenDotsNext(index, length, flightNumber) {
    if (length > 3 && index + 1 == length && flightNumber < length - 2) {
      return true;
    } else {
      return false;
    }
  }
};

const Menu = ({
  currency,
  price,
  paths,
  visibility,
  refs,
  openModal,
  disabledButton,
}) => {
  const TEXTS = {
    PRICE_LABEL: t('estimatedPrice'),
    NEW_FLIGHT_LABEL: t('newFlight'),
    AVAILABILITY_BUTTON: t('inquireAvailability'),
  };
  return (
    <div className={Style.menuBottom}>
      <div className={Style.flightsAndHook}>
        <Flights paths={paths} refs={refs} />
        <a
          href="#new-path-anchor"
          className={[
            Style.buttonHook,
            visibility ? Style.buttonHookActive : '',
          ].join(' ')}
        >
          {TEXTS.NEW_FLIGHT_LABEL}
        </a>
      </div>
      <div className={Style.priceAndButton}>
        <div className={Style.priceWraper}>
          <p className={Style.priceText}>{TEXTS.PRICE_LABEL}</p>
          <p className={Style.price}>
            {formatCurrency({
              value: price,
              currency: currency,
            })}
          </p>
        </div>
        <button
          type="button"
          onClick={openModal}
          className={[Style.button, disabledButton ? Style.disabled : ''].join(
            ' '
          )}
          disabled={disabledButton}
        >
          {TEXTS.AVAILABILITY_BUTTON}
        </button>
      </div>
    </div>
  );
};

//onOpenLogin activates the login modal
const PathSection = ({
  link,
  visibility,
  routes,
  onOpenLogin,
  onOptionalLogin,
  openUserDecision,
  setOpenUserDecision,
}) => {
  const TEXTS = {
    BACK_BUTTON: t('back'),
    PATH_LABEL: t('pathInfo'),
    OWNERS_APPROVAL: t('ownersApproval'),
    COPY_LINK: t('copyLink'),
    COPIED: t('copied'),
  };
  const [openModal, setOpenModal] = useState(false);

  const [eror, setIsError] = useState(false);
  const [hasChat, setHasChat] = useState(false);
  const [copyButtonLoading, setCopyButtonLoading] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState(t('copyLink'));
  const currency = useSelector(state => state.general.currency);
  const segments = useSelector(state => state.quotations.segments);

  var arrayRefs = [];
  const userSession = getSession();

  const handleEventOpenModal = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    window.addEventListener('skip-login', handleEventOpenModal, false);

    return () => {
      window.removeEventListener('skip-login', handleEventOpenModal);
    };
  }, []);

  const mountQuotationInfo = (quotationSegments = [], currency) => {
    let proposalInfo = {
      segments: [],
      strInfo: '',
    };

    if (quotationSegments.length) {
      proposalInfo.strInfo += `${t('currency')}: ${currency} \n`;
      quotationSegments.forEach((segment, index) => {
        const segmentData = [];

        if (segment?.routes?.length) {
          const flights = segment.routes[0].aircrafts[0].priceSummary.flights;
          const totalPrice = segment.routes[0].aircrafts[0].price;
          proposalInfo.strInfo += `${t('estimatedPrice')}: ${formatCurrency({
            value: totalPrice,
            currency: currency,
          })} \n\n`;

          flights.forEach(flight => {
            const flightData = {
              aircraftName: '',
              origin: '',
              destination: '',
              date: '',
            };
            const origin = flight?.originSpot?.address?.city?.name;
            const destination = flight?.destinationSpot?.address?.city?.name;
            const date = dateToCardFormat(flight?.departureDatetime, 'en-US');
            const hour = dateToHour(
              stringToDate(flight?.departureDatetime),
              'en-US'
            );
            const aircraft = segment.routes[0]?.aircrafts[0]?.aircraft?.name;
            const price = flight?.price;

            proposalInfo.strInfo += `${origin} ${t('to')} ${destination} \n`;
            proposalInfo.strInfo += `${date} \n`;
            proposalInfo.strInfo += `${hour} \n`;
            proposalInfo.strInfo += `${aircraft} \n`;
            proposalInfo.strInfo += `${formatCurrency({
              value: price,
              currency: currency,
            })}\n\n`;

            flightData.aircraftName = aircraft;
            flightData.origin = origin;
            flightData.destination = destination;
            flightData.date = `${date} ${hour}`;

            segmentData.push(flightData);
          });
        }

        proposalInfo.segments.push(segmentData);
      });
    }

    return proposalInfo;
  };

  function handleOpenChat(proposal, proposalInfo) {
    const proposalData = {
      ...proposalInfo,
      proposalUrl: proposal
        ? `https://backoffice.flyflapper.com/quotationproposal/details/${proposal.id}`
        : null,
    };

    postMessage(
      {
        show: true,
        type: MSG_TYPE_CHECK_AVAILABILITY,
        ...proposalData,
      },
      window.location.origin
    );
  }

  async function handleQuotation() {
    analyticsGTAG('click', {
      event_category: 'Quotation',
      event_action: 'Inquiry',
      event_label: 'User makes an inquiry',
    });

    gtmDispatcher('booking', {
      event_category: 'quotation',
      event_action: 'book',
      event_label: 'User sends a quotation',
      value: routes[0].aircrafts[0].price,
      quotation: {
        estimatedValue: routes[0].aircrafts[0].price,
        currency: currency.code,
      },
    });

    if (userSession?.publicKey) {
      const body = {
        quotationProposal: {
          currency: currency.code,
          items: [
            {
              action: 'add',
              segments: segments,
            },
          ],
          gclid: getGclidFromLS(),
        },
      };

      try {
        const { data, status } = await useRequest({
          url: apiHost + createProposal,
          method: 'post',
          useSession: true,
          data: JSON.stringify(body),
        });

        if (status === 200) {
          setHasChat(true);
          handleOpenChat(
            data.quotationProposal,
            mountQuotationInfo(segments, currency.code)
          );
        } else {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
      }
      setOpenModal(false);
    } else {
      setHasChat(true);
      handleOpenChat(null, mountQuotationInfo(segments, currency.code));
      setOpenModal(false);
    }
  }

  const handleWithLargeUrl = async () => {
    try {
      setCopyButtonLoading(true);
      const { link: shortLink } = await getShortLink(location.href);
      navigator.clipboard.writeText(shortLink);
      setCopyButtonLabel(TEXTS.COPIED + '!');

      setTimeout(() => {
        setCopyButtonLabel(TEXTS.COPY_LINK);
      }, 1500);
    } finally {
      setCopyButtonLoading(false);
    }
  };

  function handleOpenModal() {
    analyticsGTAG('click', {
      event_category: 'Quotation',
      event_action: 'CheckAvailability',
      event_label: "User clicks on 'check availability'",
    });

    if (userSession?.publicKey) {
      setOpenModal(true);
    } else {
      setOpenUserDecision(true);
    }
  }

  function handleLogin() {
    onOptionalLogin(false);
    onOpenLogin(true);
  }

  const handleOpenModalOrChat = () => {
    if (!hasChat) {
      if (!openModal) handleOpenModal();
    }
  };

  return (
    <section className={[Style.section, 'container'].join(' ')}>
      <div className={Style.topContainer}>
        <BackButton className={Style.backButton} text={TEXTS.BACK_BUTTON} />
        <div className={Style.headerActionsContainer}>
          {routes[0].aircrafts[0].aircraft.ownersApproval ? (
            <div className={Style.infoContainer}>
              <img className={Style.infoIcon} src={InfoIcon} />
              {TEXTS.OWNERS_APPROVAL}
            </div>
          ) : null}
          <Button
            version="v2"
            label={copyButtonLabel}
            onClick={handleWithLargeUrl}
            className={Style.actionButton}
            loading={copyButtonLoading}
          />
        </div>
      </div>
      <div className={Style.paths}>
        {routes[0].aircrafts[0].priceSummary.flights.map((route, index) => {
          const [pathRef, pathInView] = useInView({ threshold: 0.2 });
          const ref = {
            pathRef: pathRef,
            pathInView: pathInView,
          };
          arrayRefs.push(ref);
          return (
            <div className={Style.path} key={index} ref={pathRef}>
              <p className={Style.pathText}>
                {sprintf(
                  TEXTS.PATH_LABEL,
                  index + 1,
                  routes[0].aircrafts[0].priceSummary.flights.length
                )}
              </p>
              <PathCard route={route} />
            </div>
          );
        })}
      </div>
      <Menu
        currency={routes[0].aircrafts[0].currency}
        price={routes[0].aircrafts[0].price}
        paths={routes[0].aircrafts[0].priceSummary.flights}
        visibility={visibility}
        refs={arrayRefs}
        openModal={handleOpenModalOrChat}
        disabledButton={hasChat}
      />
      {openModal && (
        <UserTermsModal
          handleQuotation={handleQuotation}
          closeModal={() => setOpenModal(false)}
        />
      )}
      {openUserDecision ? (
        <DialogUserDecision
          open={openUserDecision}
          setOpen={setOpenUserDecision}
          onOpenLogin={value => {
            setOpenUserDecision(false);
            handleLogin(value);
          }}
          onOpenChat={() => {
            gtmDispatcher('gtm.click', {
              event_category: 'quotation',
              event_action: 'quote_without_signin',
              event_label:
                'User clicks in a check availability button, at trip detail screen',
            });
            setOpenUserDecision(false);
            handleQuotation();
          }}
        />
      ) : null}
    </section>
  );
};

export default PathSection;
