import styled, { css, keyframes } from 'styled-components';

export const InfoHeaderContainer = styled.div`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  padding: 32px 40px 0;
  margin-top: calc(var(--search-sales-results-header-height) * 1.5);
  color: black;

  .base-font-1 {
    font-size: 16px;
    color: #9f9f9f;
  }

  .base-font-2 {
    font-size: 18px;
    font-weight: 600;
    color: #9f9f9f;
  }

  .main-title {
    font-size: 26px;
    font-weight: 700;
    color: #444444;
    padding-bottom: 24px;
    text-transform: uppercase;
  }
  .main-subtitle {
    font-size: 21px;
    color: #444444;
    padding-bottom: 8px;
  }

  .main-suport-text {
    font-size: 18px;
    color: #9f9f9f;
    padding-bottom: 8px;
  }

  .toogle-view-container {
    padding-top: 16px;
  }

  .toogle-view-subtitle {
    padding: 0;
  }

  .toogle-view-title {
    font-weight: 500;
    color: #9f9f9f;
    padding-bottom: 8px;
  }

  .toogle-view-suport-text {
    font-size: 18px;
    font-weight: 600;
    color: #444444;
    padding: 48px 0 24px;
  }

  .dropdown-flex-box {
    display: flex;
    justify-content: space-between;
    gap: 33px;
  }

  .dropdown-flex-title {
    font-weight: 500;
    color: #444444;
    padding: 0 0 16px;
    line-height: 24px;
  }
  .dropdown-flex-list {
    max-width: 654px;
    list-style-type: inherit;
    padding-left: 25px;
  }
  .dropdown-flex-list li {
    padding-bottom: 15px;
    line-height: 27px;
  }
  .dropdown-flex-list li::marker {
    font-size: 9px;
  }
  .dropdown-flex-list li:last-child {
    padding: 0;
  }
  @media screen and (max-width: 768px) {
    & {
      padding: 32px 16px 0;
    }

    .dropdown-flex-box {
      flex-direction: column;
    }
    .dropdown-flex-title {
      padding: 0;
      line-height: 33px;
    }
    .dropdown-flex-box div {
    }
  }
`;
