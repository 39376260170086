import React from 'react';
import P from 'prop-types';
import * as Styles from './InputToggle.styles.js';

const InputToggle = ({
  value = false,
  setValue,
  test = '',
  color = '#429488',
}) => {
  return (
    <Styles.ContainerInput
      color={color}
      onClick={() => setValue(!value)}
      value={value}
      data-test={test}
    >
      <Styles.Ball value={value} />
    </Styles.ContainerInput>
  );
};

InputToggle.propTypes = {
  value: P.bool,
  setValue: P.func,
  test: P.string,
};

export default InputToggle;
