// Third party libs/components
import React, { useReducer } from 'react';

// Components
import * as Styled from './FlightResultSection.style';
import GridComponent from './GridComponent';
import SlideComponent from './SlideComponent';
import ShowMoreButton from './ShowMoreButton';

// Utils
import { translate as t } from '@utils/translate';

// Assets
import '../slick.css';

const reducer = (state, action) => {
  switch (action.type) {
    case 'showMore':
      return { showGrid: true, shownFlights: state.shownFlights + 8 };
  }
};

const FlightResultSection = ({
  initiallyOpened,
  originSpot,
  destinationSpot,
  flightsList,
  mappedRouteIndex,
  onSelect,
}) => {
  const TEXTS = {
    ORIGIN_LABEL: t('fromPlace'),
    DESTINATION_LABEL: t('toPlace'),
  };
  const initialShownFlights = initiallyOpened ? 12 : 8;
  const initialState = {
    showGrid: initiallyOpened ? true : false,
    shownFlights: initialShownFlights,
  };

  const totalFlightsNumber = flightsList.length;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Styled.FlightResultSection className={'container'}>
      <Styled.LabelsContainer>
        <Styled.OriginLabelWrapper>
          {TEXTS.ORIGIN_LABEL} <Styled.Label>{originSpot.name}</Styled.Label>{' '}
          <Styled.Label>
            {originSpot.airport === undefined &&
            originSpot.helipad === undefined
              ? '(ZZZZ)'
              : `(${
                  originSpot.airport
                    ? originSpot.airport.acronym
                      ? originSpot.airport.acronym
                      : originSpot.airport.oaci
                    : originSpot.helipad.oaci
                }${
                  originSpot.airport !== undefined &&
                  originSpot.airport.acronym !== null &&
                  originSpot.airport.oaci !== null
                    ? `, ${originSpot.airport.oaci || originSpot.helipad.oaci}`
                    : ''
                })`}
          </Styled.Label>
        </Styled.OriginLabelWrapper>
        <Styled.DestinationLabelWrapper>
          {TEXTS.DESTINATION_LABEL}{' '}
          <Styled.Label>{destinationSpot.name}</Styled.Label>{' '}
          <Styled.Label>
            {destinationSpot.airport === undefined &&
            destinationSpot.helipad === undefined
              ? '(ZZZZ)'
              : `(${
                  destinationSpot.airport
                    ? destinationSpot.airport.acronym
                      ? destinationSpot.airport.acronym
                      : destinationSpot.airport.oaci
                    : destinationSpot.helipad.oaci
                }${
                  destinationSpot.airport !== undefined &&
                  destinationSpot.airport.acronym !== null &&
                  destinationSpot.airport.oaci !== null
                    ? `, ${destinationSpot.airport.oaci ||
                        destinationSpot.helipad.oaci}`
                    : ''
                })`}
          </Styled.Label>
        </Styled.DestinationLabelWrapper>
      </Styled.LabelsContainer>
      <Styled.GridSlideContainer>
        {state.showGrid ? (
          <GridComponent
            flightsList={flightsList}
            flightsNumber={state.shownFlights}
            route={mappedRouteIndex}
            onSelect={onSelect}
          />
        ) : (
          <SlideComponent
            flightsList={flightsList}
            flightsNumber={state.shownFlights}
            route={mappedRouteIndex}
            onSelect={onSelect}
          />
        )}
        {state.shownFlights < totalFlightsNumber && (
          <ShowMoreButton onClick={() => dispatch({ type: 'showMore' })} />
        )}
      </Styled.GridSlideContainer>
    </Styled.FlightResultSection>
  );
};

export default FlightResultSection;
