// Third party libs/components
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import * as Styled from './Aircraft.style';
import TabSection from '@components/TabSection';
import CheckboxList from '@components/CheckboxList';
import ConfirmButton from '@components/ConfirmButton';
import AircraftAutonomy from './AircraftAutonomy';
import AircraftPrice from './AircraftPrice';
import AircraftSeats from './AircraftSeats';

// Utils
import { translate as t } from '@utils/translate';
import { array } from 'prop-types';

const Aircraft = ({
  onChangeFilters = () => null,
  onSubmit = () => null,
  closeFilter,
  showTabs = ['airplanes', 'helicopters'],
}) => {
  const data = useSelector(state => state.filters.sales);
  const TEXTS = {
    GROUP_TITLE: t('manufacturer'),
    MODEL_TITLE: t('model'),
  };
  const [prices, setPrices] = useState(() => getPrices());
  const [autonomyRange, setAutonomyRange] = useState(() => getAutonomyRange());
  const [seats, setSeats] = useState(() => getSeats());
  const [lists, setLists] = useState(() => getLists());
  const [wasSelected, setWasSelected] = useState(false);

  const aircraftTabs = [];

  const originalAutonomy = {
    airplanes: { ...data.airplanes.originalAutonomy },
    helicopters: { ...data.helicopters.originalAutonomy },
  };

  const originalSeats = {
    airplanes: { ...data.airplanes.originalSeats },
    helicopters: { ...data.helicopters.originalSeats },
  };

  const currentTab = data.currentTab;

  function getAircraftList(allData, type) {
    return (
      allData[type]?.modelsList.filter(aircraft => {
        const producer = allData[type].producersList.find(
          group => group.text === aircraft.producer
        );

        if (producer) {
          return !!producer.selected;
        } else {
          return true;
        }
      }) || []
    );
  }

  function getMinMax(arr) {
    const max = Math.max.apply(Math, arr) || 0;
    const min = Math.min.apply(Math, arr) || 0;

    return { min, max };
  }

  const airplaneModelsList = getAircraftList(data, 'airplanes');

  const helicopterModelsList = getAircraftList(data, 'helicopters');

  function getPrices() {
    return {
      airplanes: {
        ...data.airplanes.price,
      },
      helicopters: {
        ...data.helicopters.price,
      },
    };
  }

  function getAutonomyMinMax() {
    const airplaneList = getAircraftList(data, 'airplanes') || [];
    const helicopterList = getAircraftList(data, 'helicopters') || [];

    let airplaneAutonomy = null;
    let helicopterAutonomy = null;

    if (airplaneList || helicopterList) {
      const airplanesAutonomy =
        airplaneList
          .filter(airplane => airplane.selected)
          .map(airplane => airplane.autonomy) || [];
      const helicoptersAutonomy =
        helicopterList
          .filter(helicopter => helicopter.selected)
          .map(helicopter => helicopter.autonomy) || [];

      airplaneAutonomy = getMinMax(airplanesAutonomy);
      helicopterAutonomy = getMinMax(helicoptersAutonomy);
    }

    return {
      airplaneAutonomy,
      helicopterAutonomy,
    };
  }

  function getAutonomyRange() {
    return {
      airplanes: { ...data.airplanes.autonomy },
      helicopters: { ...data.helicopters.autonomy },
    };
  }

  function getSeats() {
    return {
      airplanes: {
        ...data.airplanes.seats,
      },
      helicopters: {
        ...data.helicopters.seats,
      },
    };
  }

  function getLists() {
    return {
      airplanes: {
        groupsList: [...data.airplanes.groupsList],
        producersList: [...data.airplanes.producersList],
        modelsList: [...data.airplanes.modelsList],
      },
      helicopters: {
        groupsList: [...data.helicopters.groupsList],
        producersList: [...data.helicopters.producersList],
        modelsList: [...data.helicopters.modelsList],
      },
    };
  }

  const handleSetSlider = useCallback((targetGroup, target, values, range) => {
    const rangeData = range || data[targetGroup][target];
    const valuesData = values || data[targetGroup][target].values;

    const newData = {
      ...data,
      [targetGroup]: {
        ...data[targetGroup],
        [target]: {
          ...rangeData,
          value: { ...valuesData },
        },
      },
    };
    onChangeFilters(newData);
  });

  const handleSelect = useCallback(
    (targetGroup, targetList, index, selected) => {
      let updatedList = [...lists[targetGroup][targetList]];
      updatedList[index] = { ...updatedList[index], selected };

      const newData = {
        ...data,
        handleBySelect: true,
        [targetGroup]: {
          ...data[targetGroup],
          [targetList]: updatedList,
        },
      };
      onChangeFilters(newData);
    }
  );

  const handleSelectAll = useCallback((targetGroup, targetList, selected) => {
    let updatedList = [...lists[targetGroup][targetList]];

    updatedList = updatedList.map(item => {
      return { ...item, selected };
    });

    const newData = {
      ...data,
      handleBySelect: true,
      [targetGroup]: {
        ...data[targetGroup],
        [targetList]: updatedList,
      },
    };
    onChangeFilters(newData);
  });

  useEffect(() => {
    if (data) {
      setPrices(() => getPrices());
      setAutonomyRange(() => getAutonomyRange());
      setSeats(() => getSeats());
      setLists(() => getLists());
    }
  }, [data]);

  const handleSubmit = () => {
    onSubmit();
    closeFilter();
  };

  const renderPriceComponent = ({ type, values }) => {
    return (
      <Styled.PriceContainer>
        {type === 'airplane' ? (
          <AircraftPrice
            {...values}
            onChange={values => handleSetSlider('airplanes', 'price', values)}
          />
        ) : (
          <AircraftPrice
            {...values}
            onChange={values => handleSetSlider('helicopters', 'price', values)}
          />
        )}
      </Styled.PriceContainer>
    );
  };

  const renderAutonomyComponent = ({ type, values }) => {
    return (
      <Styled.AutonomyContainer>
        {type === 'airplane' ? (
          <AircraftAutonomy
            min={values.min || originalAutonomy[currentTab].min}
            max={values.max || originalAutonomy[currentTab].max}
            value={{
              min: values.min
                ? values.value.min
                : originalAutonomy[currentTab].min,
              max: values.max
                ? values.value.max
                : originalAutonomy[currentTab].max,
            }}
            onChange={values => {
              setWasSelected(false);
              handleSetSlider('airplanes', 'autonomy', values);
            }}
          />
        ) : (
          <AircraftAutonomy
            min={values.min || originalAutonomy[currentTab].min}
            max={values.max || originalAutonomy[currentTab].max}
            value={{
              min: values.min
                ? values.value.min
                : originalAutonomy[currentTab].min,
              max: values.max
                ? values.value.max
                : originalAutonomy[currentTab].max,
            }}
            onChange={values => {
              handleSetSlider('helicopters', 'autonomy', values);
            }}
          />
        )}
      </Styled.AutonomyContainer>
    );
  };

  const renderSeatsComponent = ({ type, values }) => {
    return (
      <Styled.SeatsContainer>
        {type === 'airplane' ? (
          <AircraftSeats
            min={values.min || originalSeats[currentTab].min}
            max={values.max || originalSeats[currentTab].max}
            value={{
              min: values.min
                ? values.value.min
                : originalSeats[currentTab].min,
              max: values.max
                ? values.value.max
                : originalSeats[currentTab].max,
            }}
            onChange={values => handleSetSlider('airplanes', 'seats', values)}
          />
        ) : (
          <AircraftSeats
            min={values.min || originalSeats[currentTab].min}
            max={values.max || originalSeats[currentTab].max}
            value={{
              min: values.min
                ? values.value.min
                : originalSeats[currentTab].min,
              max: values.max
                ? values.value.max
                : originalSeats[currentTab].max,
            }}
            onChange={values => handleSetSlider('helicopters', 'seats', values)}
          />
        )}
      </Styled.SeatsContainer>
    );
  };

  const helicopterTab = (
    <Fragment>
      <Styled.OptionsContainer>
        {lists.helicopters.producersList &&
        lists.helicopters.producersList.length ? (
          <CheckboxList
            title={TEXTS.GROUP_TITLE}
            items={lists.helicopters.producersList}
            onClick={(index, checked) =>
              handleSelect('helicopters', 'producersList', index, checked)
            }
            onToggleCheckAll={checked =>
              handleSelectAll('helicopters', 'producersList', checked)
            }
          />
        ) : null}

        {lists.helicopters.modelsList && lists.helicopters.modelsList.length ? (
          <CheckboxList
            title={TEXTS.MODEL_TITLE}
            items={helicopterModelsList}
            onClick={(index, checked) => {
              const aircraftToggled = helicopterModelsList[index];
              const aircraftIndex = lists.helicopters.modelsList.findIndex(
                aircraft => aircraft.id === aircraftToggled.id
              );
              handleSelect('helicopters', 'modelsList', aircraftIndex, checked);
            }}
            onToggleCheckAll={checked =>
              handleSelectAll('helicopters', 'modelsList', checked)
            }
          />
        ) : null}
      </Styled.OptionsContainer>

      {autonomyRange &&
        renderAutonomyComponent({
          type: 'helicopter',
          values: autonomyRange.helicopters,
        })}

      {seats &&
        renderSeatsComponent({
          type: 'helicopter',
          values: seats.helicopters,
        })}
    </Fragment>
  );

  const airplaneTab = (
    <Fragment>
      <Styled.OptionsContainer>
        {lists.airplanes.producersList &&
        lists.airplanes.producersList.length ? (
          <CheckboxList
            title={TEXTS.GROUP_TITLE}
            items={lists.airplanes.producersList}
            onClick={(index, checked) => {
              handleSelect('airplanes', 'producersList', index, checked);
            }}
            onToggleCheckAll={checked =>
              handleSelectAll('airplanes', 'producersList', checked)
            }
          />
        ) : null}

        {lists.airplanes.modelsList && lists.airplanes.modelsList.length ? (
          <CheckboxList
            title={TEXTS.MODEL_TITLE}
            items={airplaneModelsList}
            onClick={(index, checked) => {
              const aircraftToggled = airplaneModelsList[index];
              const aircraftIndex = lists.airplanes.modelsList.findIndex(
                aircraft => aircraft.id === aircraftToggled.id
              );

              handleSelect('airplanes', 'modelsList', aircraftIndex, checked);
            }}
            onToggleCheckAll={checked =>
              handleSelectAll('airplanes', 'modelsList', checked)
            }
          />
        ) : null}
      </Styled.OptionsContainer>

      {autonomyRange.airplanes &&
        renderAutonomyComponent({
          type: 'airplane',
          values: autonomyRange.airplanes,
        })}

      {seats.airplanes &&
        renderSeatsComponent({
          type: 'airplane',
          values: seats.airplanes,
        })}
    </Fragment>
  );

  if (lists.airplanes.groupsList.length && showTabs.includes('airplanes')) {
    aircraftTabs.push({
      //title: t(''),
      content: airplaneTab,
    });
  }

  if (lists.helicopters.groupsList.length && showTabs.includes('helicopters')) {
    aircraftTabs.push({
      //title: t(''),
      content: helicopterTab,
    });
  }

  if (!aircraftTabs.length) return null;

  return (
    <Styled.Aircraft>
      <TabSection
        /* headerClassName={'tabsHeader'} */ sections={aircraftTabs}
      />
      <ConfirmButton onClick={handleSubmit} />
    </Styled.Aircraft>
  );
};

export default Aircraft;
