// Third party libs/components
import React, { useState, useEffect, useMemo } from 'react';
import Style from './style.module.css';

// Components
import ModelsResults from '@components/ModelsResults';
import AircraftsResults from '@components/AircraftsResults';
import SpinningWheel from '@components/SpinningWheel';

// Utils
import { translate as t } from '@utils/translate';
import { apiHost, getAircraftsByModel } from '@utils/constants';
import useRequest from '@utils/useRequest';

const SearchResultsAircraft = ({ data, onSelectAircraft, modelId }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [aircrafts, setAircrafts] = useState();
  const [selectedModelId, setSelectedModelId] = useState(modelId);
  const [modelSelected, setModelSelected] = useState();
  let ids = [];

  const TEXTS = {
    LOADING_RESULTS: t('loadingResults'),
    AVAILABLE_AIRCRAFT: t('availableAircraft'),
  };

  modelSelected &&
    modelSelected.map(aircrafts => {
      ids.push(aircrafts.aircraft.id);
    });

  //TODO: We should refactor this! It doesnt cache the results
  // Save in map object the list of aircraft fetched and reuse this values
  // Some routes such like SP-RJ has 200 aircrafts per route.
  //
  // Also we can add lazy load to retrive the aircraft data while the user scroll

  useMemo(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const query = `${apiHost}${getAircraftsByModel}${ids.toString()}&bringAmenities=1&bringCertifications=1`;
        const { data } = await useRequest({
          url: query,
          method: 'get',
          useSession: false,
        });

        setAircrafts(data.aircrafts);
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    }

    if (modelSelected !== undefined) fetchData();
  }, [modelSelected]);

  useEffect(() => {
    ids = [];

    if (data.routes && data.routes.length) {
      modelSelected &&
        modelSelected.map(aircrafts => {
          ids.push(aircrafts.aircraft.id);
        });

      if (data.routes[0].aircraftModels) {
        setSelectedModelId(
          modelId || data.routes[0].aircraftModels[0].aircraftModel.id
        );

        let models = data.routes[0].aircraftModels.filter(model => {
          return model.aircraftModel.id === modelId;
        });

        handleSelectModel(models || data.routes[0].aircraftModels);
      }
    }
  }, [data.routes]);

  const handleSelectModel = modelSelected => {
    if (modelSelected[0]) setModelSelected(modelSelected[0].aircrafts);
  };

  if (!data.routes) return null;

  return (
    <>
      {data.routes.map((singleRoute, index) => (
        <div className={[Style.content, 'container'].join(' ')} key={index}>
          <div className={Style.models}>
            <ModelsResults
              models={singleRoute.aircraftModels}
              currency={singleRoute.aircraftModels[0].aircrafts[0].currency}
              modelIdActive={selectedModelId}
              onSelected={handleSelectModel}
            />
          </div>
          <div className={Style.aircrafts}>
            {aircrafts === undefined || loading ? (
              <div className={Style.loadingAircrafts}>
                <div className={Style.mainMessage}>{TEXTS.LOADING_RESULTS}</div>
                <div className={Style.load}>
                  <SpinningWheel />
                </div>
              </div>
            ) : (
              <>
                <h2 className={Style.title}>{TEXTS.AVAILABLE_AIRCRAFT}</h2>
                <AircraftsResults
                  aircraftsDetails={aircrafts}
                  aircrafts={modelSelected}
                  onSelectAircraft={onSelectAircraft}
                />
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default SearchResultsAircraft;
