import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layout';
import PrivateRoute from '../components/PrivateRoute';
import Profile from '../app/Profile';
import Search from '../app/Search';
import SearchSales from '../app/SearchSales';
import FlightDetail from '../app/FlightDetail';
import ResetPassword from '../app/ResetPassword';
import SearchResultsContextProvider from '../context/SearchResultsContextProvider';
import FiltersContextProvider from '../context/FiltersContextProvider';
import TripDetail from '../app/TripDetail';

const Plataform = ({ location }) => {
  const path = location.pathname;
  const urlParams = new URLSearchParams(location.search);
  /* const [mustLogIn, setMustLogIn] = useState({
    state: false,
    callback: () => console.warn(`There's no callback for login`),
  }); */

  /* useEffect(() => {
    console.log(mustLogIn);
  }, [mustLogIn]); */

  const translations = [
    { path: path, polylang_current_lang: 'en_US' },
    { path: path, polylang_current_lang: 'es_ES' },
    { path: path, polylang_current_lang: 'pt_BR' },
    // { path: path, polylang_current_lang: 'it_IT' },
  ];
  const isSearchResultsPage =
    !!location.pathname.match(/^\/p\/search/) ||
    !!location.pathname.match(/^\/p\/aircraft-sales/);

  /* const handleMustLogIn = () => {
    console.log('MUST LOGIN!');
    setMustLogIn(prevState => {
      return {
        state: true,
        callback: prevState.callback,
      };
    });
  };

  const handleCloseLogin = callback => {
    console.log('CLOSED LOGIN!');
    setMustLogIn(prevState => {
      return {
        state: false,
        callback: callback,
      };
    });
  }; */

  return (
    <FiltersContextProvider>
      <SearchResultsContextProvider>
        <Layout
          SearchHeader={isSearchResultsPage}
          translations={translations}
          solidHeader="white"
          showNewsletter={false}
          useTranslationsPath={false}
          /* onOpenLogin={mustLogIn.state}
          closeLogin={mustLogIn.callback} */
        >
          <Router basepath="/p">
            <PrivateRoute path="/account" component={Profile} />
            <Search path="/search/:origin/:destination/:departureDate/:returnDate/:departureTime/:returnTime/:adults/:children/:flightType" />
            <SearchSales path="/aircraft-sales" />
            <FlightDetail
              path="/flight/:flightId/:aircraftId"
              /* onMustLogIn={handleMustLogIn}
              onLogIn={handleCloseLogin} */
            />
            <TripDetail path="/trip-detail" />
            {/* <ResetPassword path="/reset-password" token={urlParams.get('token')} /> */}
            <ResetPassword path="/reset-password/:hash" />
          </Router>
        </Layout>
      </SearchResultsContextProvider>
    </FiltersContextProvider>
  );
};

export default Plataform;
