import styled, { keyframes } from 'styled-components';

const showMsg = keyframes`
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const ProfileContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
`;

const Group = styled.div``;

const Title = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
`;

const ContainerInput = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const InputLabel = styled.label`
  font-size: 1.2rem;
  font-weight: 600;
  margin-right: 20px;
`;

const Button = styled.button`
  font-size: 15px;
  color: #ffffff;
  border: none;
  background-color: #009688;
  padding: 14px 0;
  font-weight: 600;
  border-radius: 8px;
  display: block;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  max-width: 490px;
  &:active {
    transform: translateY(10px);
  }
`;

const ErrorMesssage = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 10px 0 0 0;
  text-align: center;
  color: white;
  background-color: #ca5050;
  padding: 5px 0;
  border-radius: 10px;
  font-weight: 500;
  animation: ${showMsg} 0.3s ease-in 1 forwards;
`;

const SuccessMesssage = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 10px 0 0 0;
  text-align: center;
  color: white;
  background-color: #01887b;
  padding: 5px 0;
  border-radius: 10px;
  font-weight: 500;
  animation: ${showMsg} 0.3s ease-in 1 forwards;
`;

export {
  ProfileContainer,
  Group,
  Title,
  ContainerInput,
  InputLabel,
  Button,
  ErrorMesssage,
  SuccessMesssage,
};
