import { createGlobalStyle, css } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  ${({ name, parentClassName, itemClassName }) => {
    return getSliderStyles(name, parentClassName, itemClassName);
  }}
`;

/**
 * Returns the styles based on its name
 *
 * @param {string} name Styles name
 * @param {string} parentClassName Class name of the parent container
 */
const getSliderStyles = (name = 'normal', parentClassName, itemClassName) => {
  let stylesFunction = null;

  switch (name) {
    case 'normal':
      stylesFunction = getNormalStyles;
      break;

    case 'sales':
      stylesFunction = getSalesStyles;
      break;

    default:
      stylesFunction = getNormalStyles;
      break;
  }

  return stylesFunction(`.${parentClassName}`, `.${itemClassName}`);
};

//Returns slick styles for simple cards
const getNormalStyles = parentClassName => {
  const styles = css`
    ${parentClassName} .slick-list {
      padding-left: 25px;
      width: 100%;
    }

    ${parentClassName} .slick-slide {
      transition: all ease 0.7s;
    }

    ${parentClassName} .slick-arrow {
      position: absolute;
      top: 30%;
      z-index: 10;
    }

    ${parentClassName} .slick-arrow.slick-disabled {
      opacity: 0;
    }

    ${parentClassName} .slick-arrow.slick-prev {
      left: 30px;
    }

    ${parentClassName} .slick-arrow.slick-next {
      right: 30px;
    }

    ${parentClassName} div[aria-hidden='true'] {
      opacity: 0.2;
    }

    @media (min-width: 1024px) {
      ${parentClassName} .slick-list {
        padding-left: calc(var(--container-size) / 2);
        width: 100%;
      }
    }
  `;

  return styles;
};

//Returns slick styles for sales cards
const getSalesStyles = parentClassName => {
  const styles = css`
    ${parentClassName} .slick-list {
      padding-left: 25px;
      width: 100%;
    }

    ${parentClassName} .slick-slide {
      transition: all ease 0.7s;
    }

    ${parentClassName} .slick-arrow {
      position: absolute;
      top: 30%;
      z-index: 10;
    }

    ${parentClassName} .slick-arrow.slick-disabled {
      opacity: 0;
    }

    ${parentClassName} .slick-arrow.slick-prev {
      left: 30px;
    }

    ${parentClassName} .slick-arrow.slick-next {
      right: 30px;
    }

    ${parentClassName} div[aria-hidden='true'] {
      opacity: 0.2;
    }

    @media (min-width: 1024px) {
      ${parentClassName} .slick-list {
        padding-left: calc(var(--container-size) / 2);
        width: 100%;
      }
    }
  `;

  return styles;
};

export default GlobalStyles;
