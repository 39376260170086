import styled, { css } from 'styled-components';

export const SectionGroupContainer = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
`;

export const Title = styled.h2`
  background-color: #f1f1f1;
  line-height: 60px;
  bottom: 34px;
  font-size: 21px;
  font-weight: 600;
  color: #212121;
  padding-left: calc(var(--container-size) / 2);
  padding-right: 30px;

  @media (min-width: 1024px) {
    font-size: 24px;
    margin-bottom: 0px;
  }
`;
