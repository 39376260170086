// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';
import Alert from './alert.svg';
import { translate as t } from '@utils/translate';
import { formatCurrency } from '@utils/currency';
import { sprintf } from 'sprintf-js';

const ListItems = ({ items, totalPrice, currency }) => {
  const calcCancelationValue = percentage => {
    return formatCurrency({
      value: totalPrice * percentage,
      currency: currency,
    });
  };

  return items.map(({ title, costInfo, description, feePercentage }, index) => {
    const TEXTS = {
      CANCELATION_FREE: t('cancellationFee'),
    };
    return (
      <li className={Style.item} key={index}>
        <h3 className={Style.condicionTitle}>{title}</h3>
        <p className={Style.condicionDescription}>
          {typeof description === 'string' &&
            description.replace('%s', `${feePercentage}%`)}
        </p>
        <div className={Style.condicionCost}>
          <img src={Alert} className={Style.svg} />
          <p className={Style.condicionCostText}>
            {`${TEXTS.CANCELATION_FREE}: ${calcCancelationValue(
              feePercentage
            )}`}
          </p>
        </div>
      </li>
    );
  });
};

const FlightCancelation = ({ title, items, totalPrice, currency }) => {
  if (!items) return null;

  return (
    <section className={Style.flightCancelation}>
      <h2 className={Style.title}>{title}</h2>
      <ul className={Style.list}>
        <ListItems items={items} totalPrice={totalPrice} currency={currency} />
      </ul>
    </section>
  );
};

export default FlightCancelation;
