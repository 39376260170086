import React, { useState, useEffect } from 'react';
import * as S from './ManageCard.styles.js';
import {
  fetchPaymentMethods,
  setPaymentMethodsList,
} from '@store/reducers/cart/operations';
import { useStore } from 'react-redux';

// Components
import CreditCardCreator from '@components/CreditCardCreator/CreditCardCreator.jsx';
import CreditCardList from '@components/CreditCardList';

const ManageCard = () => {
  const store = useStore();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const getPaymentMethods = await fetchPaymentMethods();
      const { paymentMethods } = await getPaymentMethods();
      store.dispatch(setPaymentMethodsList(paymentMethods));
    })();
  }, []);

  return (
    <>
      <S.CreditCardContainer>
        <CreditCardList onAddNewCard={() => setOpen(true)} />
      </S.CreditCardContainer>

      {open && (
        <S.Overlay>
          <CreditCardCreator onClose={() => setOpen(false)} manageCard />
        </S.Overlay>
      )}
    </>
  );
};

export default ManageCard;
