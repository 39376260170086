// Third party libs/components
import React, { useState } from 'react';
import P from 'prop-types';
import * as Styled from './AircraftToSaleCard.style';

const AircraftToSaleCard = ({
  imageUrl,
  imageAlt,
  link,
  price,
  priceLabel,
  subtitle,
  title,
}) => {
  const [isHover, setIsHover] = useState(false);

  const handleHover = hover => {
    setIsHover(hover);
  };

  return (
    <Styled.CardWrapper href={link}>
      <Styled.Card
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        isHover={isHover}
      >
        <Styled.CardImageWrapper isHover={isHover}>
          <Styled.Image
            width={364}
            height={260}
            src={imageUrl || ''}
            alt={imageAlt || ''}
            isHover={isHover}
          />

          <Styled.InfoWrapper isHover={isHover}>
            <Styled.SubtitleHidden isHover={isHover}>
              {title || ''}
            </Styled.SubtitleHidden>
            <Styled.PriceWrapper>
              {/* <Styled.PriceLabel>{priceLabel || ''}</Styled.PriceLabel> */}
              <Styled.PriceValue>{price}</Styled.PriceValue>
            </Styled.PriceWrapper>
          </Styled.InfoWrapper>
        </Styled.CardImageWrapper>

        <Styled.Title>{subtitle || ''}</Styled.Title>
      </Styled.Card>
    </Styled.CardWrapper>
  );
};

AircraftToSaleCard.propTypes = {
  imageUrl: P.string,
  imageAlt: P.string,
  link: P.string,
  price: P.string,
  priceLabel: P.string,
  subtitle: P.string,
  title: P.string,
};

export default AircraftToSaleCard;
