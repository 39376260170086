// TODO: REFACTORING
import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './SharedFlightNoReturn.styles';

import { translate as t } from '@utils/translate';

function SharedFlightNoReturn({ seats, onProceed, onCancel }) {
  const TEXTS = {
    NO_RETURN_AVAILABLE: t('noReturnFlightsAvailable'),
    BUY_ONE_WAY_ONLY: t('buyOnlyOneWay'),
    BACK_AND_CHANGE_SEATS: t('backAndChangeSeats'),
  };

  return (
    <Styled.SharedFlightNoReturn>
      <div className="passengers-info">
        <h2 className="passengers-info__main-message">
          {TEXTS.NO_RETURN_AVAILABLE}
          <br />
          {`com ${seats.asked} assentos disponíveis`}
        </h2>
        <p className="passengers-info__seats-available">{`Apenas ${seats.available} restantes`}</p>
      </div>
      <div className="action-buttons">
        <button
          className="action-buttons__proceed"
          type="button"
          onClick={onProceed}
        >
          {TEXTS.BUY_ONE_WAY_ONLY}
        </button>
        <button
          className="action-buttons__cancel"
          type="button"
          onClick={onCancel}
        >
          {TEXTS.BACK_AND_CHANGE_SEATS}
        </button>
      </div>
    </Styled.SharedFlightNoReturn>
  );
}

SharedFlightNoReturn.propTypes = {
  seats: PropTypes.shape({
    asked: PropTypes.number,
    available: PropTypes.available,
  }).isRequired,
  onProceed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SharedFlightNoReturn;
