// Third party libs/components
import React from 'react';

// Components
import FlightCard from '../../FlightCard';
import * as Styled from './GridComponent.style';

const GridComponent = ({ flightsList, flightsNumber, route, onSelect }) => {
  const flightsToShow = flightsList.slice(0, flightsNumber);

  return (
    <Styled.Grid>
      {flightsToShow.map((flight, index) => {
        return (
          <Styled.FlightCardContainer key={flight.aircraftModel.id}>
            <FlightCard
              flight={flight}
              onClick={(e, idModel) => {
                onSelect(idModel, route);
              }}
            />
          </Styled.FlightCardContainer>
        );
      })}
    </Styled.Grid>
  );
};

export default GridComponent;
