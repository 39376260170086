/* eslint-disable react/display-name */
// TODO: REFACTORING
import SharedCarousel from '@components/SharedCarousel/SharedCarousel';
import SharedFlightInfo from '@components/SharedFlightInfo';
import SharedFlightNoReturn from '@components/SharedFlightNoReturn/SharedFlightNoReturn';
import SharedFlightPassengers from '@components/SharedFlightPassengers';
import { navigate, useParams } from '@reach/router';
import parseSharedFlightData from '@utils/parseSharedFlightData';
import { encodeObjectToken } from '@utils/token';
import { flightSearchSecret } from '@utils/secrets';
import { translate as t, useCurrentLang } from '@utils/translate';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import PassengersCountSelector from '../PassengersCountSelector/index';
import * as Styled from './SharedFlightTag.styles';
import { sprintf } from 'sprintf-js';

const SharedFlightTag = forwardRef(
  (
    {
      isActive,
      flightData,
      index,
      flightStats,
      onSelect,
      onBook,
      returningFLights,
      isLookingForReturn,
      shouldHide,
      selectedFlights,
      initialSeats,
    },
    ref
  ) => {
    const TEXTS = {
      INBOUND_PRICE: t('inboundPrice'),
      OUTBOUND_PRICE: t('inboundAndOutboundPrice'),
      BOOK_INBOUND: t('bookInbound'),
      BOOK_OUTBOUND: t('bookOutbound'),
      DETAILS: t('details'),
      EMPTY_LEG_TITLE: t('sharedEmptyLegTitle'),
      EMPTY_LEG_SUBTITLE: t('sharedEmptyLegSubtitle'),
      EMPTY_LEG_DESCRIPTION: t('sharedEmptyLegDescription'),
    };
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const searchParams = useParams();
    const [passengers, setPassengers] = useState(1);
    const [returningSeatsPrice, setReturningSeatsPrice] = useState(0);
    const [step, setStep] = useState('origin');
    const [parsedData, setParsedData] = useState(null);
    const [returningFlightData, setReturningFlightData] = useState(null);
    const currentLang = useCurrentLang();

    const isReturnFlight = () => {
      const { maxAvailableSeats } = flightStats;
      const numberOfPassengers = passengers;
      if (numberOfPassengers <= maxAvailableSeats) return true;
      return false;
    };

    useEffect(() => {
      if (isActive && initialSeats && returningFLights) {
        setPassengers(initialSeats);
        setStep('destination');
      }
    }, [isActive, initialSeats, returningFLights]);

    useEffect(() => {
      if (flightData) {
        setParsedData(parseSharedFlightData(flightData));
      }
    }, [flightData]);

    useEffect(() => {
      if (!isActive) {
        setStep('origin');
        setReturningSeatsPrice(0);
      }
    }, [isActive]);

    useImperativeHandle(ref, () => ({
      resetFromParent() {
        reseTag();
      },
    }));

    function handleOnBook() {
      const { id, origin, destination, isShared, seats } = parsedData;
      let selectedPassengers = passengers;

      if (isLookingForReturn) {
        if (isShared == '0') {
          return onBook(seats.available, {
            originFlightId: id,
          });
        }

        if (step === 'origin') {
          setStep('destination');
          onSelect({
            originFlightId: id,
            originFlightPrice: seats.price,
            originFlightPath: `${origin.code} ➔ ${destination.code}`,
          });
        } else {
          onBook(selectedPassengers);
        }
      } else {
        if (isShared === '0') {
          selectedPassengers = seats.available;
        }

        onBook(selectedPassengers, {
          originFlightId: id,
        });
      }
    }

    function onPassegengerChange(action) {
      setPassengers(oldState => {
        return action === 'increment' ? (oldState += 1) : (oldState -= 1);
      });
    }

    function formatPrice(price, currency) {
      const locale = {
        BRL: 'pt-BR',
        USD: 'en-US',
      };

      return price.toLocaleString(locale[currency], {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    function getTripType() {
      if (!isLookingForReturn) {
        return 'one-way';
      } else {
        return {
          origin: 'round-trip--going',
          destination: 'round-trip--returning',
        }[step];
      }
    }

    function getFlightType() {
      return parsedData.isShared == '0' ? 'empty-leg' : 'flights';
    }

    function showDetails() {
      let flightId;
      let aircraftId;
      let params = new URLSearchParams();
      const currentState = {
        selectedSeats: passengers,
        flightType: getFlightType(),
        flights: selectedFlights,
        tripType: getTripType(),
        searchParams,
      };
      const token = encodeObjectToken(currentState, flightSearchSecret);

      params.set('st', token);
      params.set('lang', currentLang);

      if (step === 'origin') {
        flightId = parsedData.id;
        aircraftId = parsedData.aircraft.id;
      } else {
        flightId = returningFlightData.id;
        aircraftId = returningFlightData.aircraft.id;
      }

      navigate(`/p/flight/${flightId}/${aircraftId}?${params.toString()}`);
    }

    function switchDestinationInfo() {
      if (isReturnFlight) {
        return (
          <SharedCarousel
            onSelect={handleSelectReturningFLight}
            flights={returningFLights}
          />
        );
      }

      return (
        <SharedFlightNoReturn
          seats={{
            available: maxAvailableSeats,
            asked: numberOfPassengers,
          }}
          onProceed={handleOnBook}
          onCancel={reseTag}
        />
      );
    }

    function handleSelectReturningFLight(id) {
      let price = 0;
      const path = {};

      if (id && returningFLights) {
        const result = returningFLights.find(flight => flight.id === id);

        if (result) {
          setReturningFlightData(result);
          setReturningSeatsPrice(price);

          price = result.seats[0].price;
          path.originCode = result.originSpot.airport
            ? result.originSpot.airport.acronym ||
              result.originSpot.airport.oaci
            : result.originSpot.helipad.oaci;
          path.destinationCode = result.destinationSpot.airport
            ? result.destinationSpot.airport.acronym ||
              result.destinationSpot.airport.oaci
            : result.destinationSpot.helipad.oaci;
        }
      }

      onSelect({
        destinationFlightId: id,
        destinationFlightPrice: price,
        destinationFlightPath: `${path.originCode ||
          ''} ➔ ${path.destinationCode || ''}`,
      });
    }

    function reseTag() {
      setStep('origin');
      onSelect({
        originFlightId: null,
        originFlightPrice: 0,
        originFlightPath: '',
        destinationFlightId: null,
        destinationFlightPrice: 0,
        destinationFlightPath: '',
      });
    }

    function calcFlightPrice() {
      const { isShared, seats } = parsedData;
      const isEmptyLeg = isShared === '0';
      const totalPrice = seats.seats.reduce(
        (acc, seat) => Number.parseFloat(seat.price) + Number.parseFloat(acc),
        0
      );

      if (isEmptyLeg) {
        return formatPrice(totalPrice, parsedData.currency);
      }

      return formatPrice(
        (parseFloat(parsedData.seats.price) + parseFloat(returningSeatsPrice)) *
          passengers,
        parsedData.currency
      );
    }

    function renderOriginStep() {
      const { isShared, seats } = parsedData;
      const isEmptyLeg = isShared === '0';
      const totalSeats = seats.available;
      const Component = isMobile
        ? SharedFlightPassengers
        : PassengersCountSelector;

      if (isEmptyLeg) {
        return (
          <Styled.EmptyLegMessage>
            <div className="message">
              <h1>{sprintf(TEXTS.EMPTY_LEG_TITLE, totalSeats)}</h1>
              <h2>{TEXTS.EMPTY_LEG_SUBTITLE}</h2>
              <p>{TEXTS.EMPTY_LEG_DESCRIPTION}</p>
            </div>
            {/* <p className="seats-counter">{totalSeats}</p> */}
          </Styled.EmptyLegMessage>
        );
      }

      return (
        <Component
          onIncrement={() => onPassegengerChange('increment')}
          onDecrement={() => onPassegengerChange('decrement')}
          initialValue={passengers}
          theme={'primary'}
          minPassengers={1}
          maxPassengers={parsedData.seats.available}
          showMaxPassengers={true}
          showTitle={true}
        />
      );
    }

    return (
      <div id={`shared_${(parsedData && parsedData.id) || ''}`}>
        {parsedData && (
          <Styled.SharedTag
            isMobile={isMobile}
            shouldExpand={step === 'destination'}
            shouldHide={shouldHide}
          >
            {/* PRICE AND ACTION CONTAINER */}
            <Styled.PriceAndAction isMobile={isMobile}>
              <div className="priceContainer">
                <p>
                  <span className="price-label">
                    {step === 'origin'
                      ? TEXTS.INBOUND_PRICE
                      : TEXTS.OUTBOUND_PRICE}
                  </span>
                  <span>{calcFlightPrice()}</span>
                </p>
              </div>
              <section className="actionButtons">
                <div className="buttonContainer book">
                  <button
                    type="button"
                    onClick={handleOnBook}
                    data-test={`SharedFlightTag.button-book-${index}`}
                  >
                    {step === 'origin'
                      ? TEXTS.BOOK_INBOUND
                      : TEXTS.BOOK_OUTBOUND}
                  </button>
                </div>
                <div className="buttonContainer details">
                  <button type="button" onClick={showDetails}>
                    {TEXTS.DETAILS}
                  </button>
                </div>
              </section>
            </Styled.PriceAndAction>

            {/* FLIGHT DETAILS CONTAINER */}
            <Styled.FlightDetails
              isMobile={isMobile}
              theme={step}
              isReturnFlight={step === 'origin' ? false : isReturnFlight}
            >
              <div className="originBox">
                <SharedFlightInfo data={parsedData} theme={step} />
              </div>

              <div className="passangersAndCarousel">
                {step === 'origin'
                  ? renderOriginStep()
                  : switchDestinationInfo()}
              </div>
            </Styled.FlightDetails>
          </Styled.SharedTag>
        )}
      </div>
    );
  }
);

SharedFlightTag.propTypes = {
  index: PropTypes.number,
  isActive: PropTypes.bool.isRequired,
  flightData: PropTypes.shape().isRequired,
  flightStats: PropTypes.shape(),
  returningFLights: PropTypes.arrayOf(PropTypes.shape()),
  isLookingForReturn: PropTypes.bool.isRequired,
  shouldHide: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onBook: PropTypes.func.isRequired,
  selectedFlights: PropTypes.shape(),
  initialSeats: PropTypes.number.isRequired,
};

SharedFlightTag.defaultProps = {
  flightStats: null,
  shouldHide: false,
  returningFLights: null,
};

export default SharedFlightTag;
