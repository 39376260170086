// TODO: TRANSLATION PATTERN
// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import * as Styled from './PassengersCountSelector.style';
import { sprintf } from 'sprintf-js';

import { translate as t } from '@utils/translate';

const PassengersCountSelector = ({
  onIncrement = () => {},
  onDecrement = () => {},
  initialValue,
  currentValue,
  maxPassengers,
  minPassengers,
  showTitle,
  showMaxPassengers,
  theme,
  enabled = true,
}) => {
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [value, setValue] = useState(initialValue || 0);
  const TEXTS = {
    TITLE: t('passengers'),
    SUB_TITLE: t('nSeatsRemaining'),
  };

  useEffect(() => {
    if (currentValue) setValue(currentValue);
  }, [currentValue]);

  useEffect(() => {
    setSelectedTheme(theme);
  }, [theme]);

  const handleIncrement = () => {
    if (maxPassengers > 0 && value + 1 > maxPassengers) return;

    if (!maxPassengers && value >= 10) {
      setValue(value + 10);
      onIncrement(value + 10);
    } else {
      setValue(value + 1);
      onIncrement(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value <= 0 || (minPassengers && value <= minPassengers)) return;

    if (!maxPassengers && value - 10 >= 10) {
      setValue(value - 10);
      onDecrement(value - 10);
    } else {
      setValue(value - 1);
      onDecrement(value - 1);
    }
  };

  return (
    <Styled.Container theme={selectedTheme}>
      {showTitle && <Styled.Title>{TEXTS.TITLE}</Styled.Title>}
      {showMaxPassengers && maxPassengers && (
        <Styled.SubTitle>
          {sprintf(TEXTS.SUB_TITLE, (maxPassengers || 0) - value)}
        </Styled.SubTitle>
      )}
      <Styled.Wrapper>
        <Styled.Button
          theme={selectedTheme}
          size={'small'}
          onClick={handleDecrement}
          disabled={
            !enabled || value <= 0 || (minPassengers && value <= minPassengers)
          }
        >
          -
        </Styled.Button>
        <Styled.Span>{value}</Styled.Span>
        <Styled.Button
          theme={selectedTheme}
          onClick={handleIncrement}
          disabled={!enabled || (maxPassengers && value >= maxPassengers)}
        >
          +
        </Styled.Button>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default PassengersCountSelector;
