import sortByDate from './sort-by-date';
import sortByPrice from './sort-by-price';

const sortMethods = {
  byDate: sortByDate,
  byPrice: sortByPrice,
};

/**
 * Invoke the sortering method
 *
 * @param {string} methodName
 * @param {string} variantName
 * @param {object} data
 */
const applySortering = ({ methodName, variantName, data, flightType }) => {
  let sortedData = [...data];

  if (
    typeof sortMethods[methodName] === 'object' &&
    typeof sortMethods[methodName].applySort === 'function'
  ) {
    sortedData = sortMethods[methodName].applySort({
      variant: variantName,
      data: sortedData,
      flightType: flightType,
    });
  }

  return sortedData;
};

export { applySortering };
