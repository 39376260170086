// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const SliderNextButton = ({ onClick, className }) => {
  return (
    <button
      className={[Style.nextButton, className].join(' ')}
      onClick={onClick}
    >
      <div className={Style.nextArrow}></div>
    </button>
  );
};

export default SliderNextButton;
