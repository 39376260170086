// Third party libs/components
import React from 'react';

// Components
import * as Styled from './ShowMoreButton.style';
import Button from '@components/Button';

// Utils
import { translate as t } from '@utils/translate';

const ShowMoreButton = ({ onClick }) => {
  const TEXTS = {
    SEE_MORE: t('seeMore'),
  };
  return (
    <Styled.ButtonContainer>
      <Styled.ButtonWrapper onClick={onClick}>
        <Button text={TEXTS.SEE_MORE} />
      </Styled.ButtonWrapper>
    </Styled.ButtonContainer>
  );
};

export default ShowMoreButton;
