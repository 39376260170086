import styled, { keyframes, css } from 'styled-components';
import { COLORS } from '@utils/styles/colors';

const buttonDefaultTheme = css`
  cursor: pointer;
  height: 57px;
  width: 100%;
  max-width: 300px;

  background-color: transparent;
  border: 3px solid ${COLORS.PERSIAN_GREEN};
  border-radius: 8px;

  font-size: 18px;
  color: ${COLORS.PERSIAN_GREEN};
  font-weight: 600;
`;

const buttonDisabledTheme = css`
  cursor: pointer;
  height: 57px;
  width: 100%;
  max-width: 300px;

  background-color: #d6d6d6;
  border: 0;
  border-radius: 8px;

  font-size: 18px;
  color: #fff;
  font-weight: 600;
`;

const buttonMessageTheme = css`
  cursor: unset;
  height: 57px;
  width: 100%;
  max-width: 300px;

  background-color: transparent;
  border: 0;
  border-radius: 8px;

  font-size: 18px;
  color: ${COLORS.CELADON_GREEN};
  font-weight: 600;

  transition: all 0.15s ease-in-out;
`;

const buttonActiveTheme = css`
  cursor: pointer;
  height: 57px;
  width: 100%;
  max-width: 300px;

  background-color: ${COLORS.PERSIAN_GREEN};
  border: 0;
  border-radius: 8px;

  font-size: 18px;
  color: #fff;
  font-weight: 600;

  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: ${COLORS.KEPPEL_01};
    border-color: ${COLORS.KEPPEL_01};
  }
`;

const getButtonTheme = theme => {
  return {
    default: buttonDefaultTheme,
    disabled: buttonDisabledTheme,
    message: buttonMessageTheme,
    active: buttonActiveTheme,
  }[theme];
};

export const Button = styled.button`
  ${({ theme }) => getButtonTheme(theme)};
`;

export const ButtonLoading = styled.div`
  ${({ theme }) => getButtonTheme(theme)};
  position: relative;
  box-shadow: none;
`;
