const { default: styled, css, keyframes } = require('styled-components');

const CreditCardSelectorContainer = styled.div`
  width: 480px;
  overflow: visible;

  ${props => {
    if (props.align === 'center') {
      return css`
        display: flex;
        align-items: center;
        justify-content: center;
      `;
    }
  }}
`;

const CreditCardContainer = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const CreditCardFormContainer = styled.div`
  width: calc(100% - 550px);
  max-width: 500px;
  min-width: 400px;

  @media screen and (max-width: 600px) {
    width: 90%;
    min-width: 300px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0004;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  animation: show 0.3s ease-in-out forwards;
`;

const AddCreditCard = styled.button`
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: #ffffff;
  border: none;
  background-color: ${props => props.color || '#009688'};
  padding: 14px 0;
  font-weight: 600;
  border-radius: 8px;
  width: 100%;
  max-width: 230px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 40px 0;

  &:active {
    transform: translateY(10px);
  }
`;

export {
  CreditCardContainer,
  CreditCardSelectorContainer,
  CreditCardFormContainer,
  Overlay,
  AddCreditCard,
};
