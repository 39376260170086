import styled from 'styled-components';

export const AircraftPrice = styled.div`
  flex-basis: 100%;
  margin-bottom: 30px;
`;

export const AircraftPriceTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  font-weight: 600;
  color: #010101;
  margin-bottom: 39px;
`;
