// Third party libs/components
import React, { Fragment } from 'react';
import { Link } from 'gatsby';

// Components
import * as Styled from './MenuModal.style';
import LocaleOptions from '@components/LocaleOptions';
import UserImage from '@components/UserImage';

// Utils
import { getFirstName } from '@utils/names';

// Services
import { isLoggedIn, getUser } from '@services/auth';

const MenuModal = ({
  translations,
  queryLanguages,
  loginContent,
  closeMenu,
  openLogin,
  menuItems,
  blockLocaleOptions = {
    currency: false,
    language: false,
  },
}) => {
  const menuListItems = menuItems.map((item, index) => {
    const url = item.url.replace(/^.*\/\/[^/]+\//, '');
    return (
      <Styled.StyledLink
        className={item.classes}
        key={index}
        activeClassName={'active'}
        to={`/${url}`}
      >
        <span>{item.title}</span>
      </Styled.StyledLink>
    );
  });

  return (
    <Fragment>
      <Styled.MenuModalContainer>
        <Styled.CloseMenu onClick={closeMenu} />
        <Styled.OptionsWrapper menuOpened={true} isLogged={isLoggedIn()}>
          {translations && (
            <Styled.LocaleWrapper isLogged={isLoggedIn()}>
              <Styled.LocaleContainer>
                <LocaleOptions
                  translations={translations}
                  queryLanguages={queryLanguages}
                  onSubmit={closeMenu}
                  blockLocaleOptions={blockLocaleOptions}
                ></LocaleOptions>
              </Styled.LocaleContainer>
            </Styled.LocaleWrapper>
          )}
          <Styled.LoginOption>
            {isLoggedIn() ? (
              <Link to="/p/account">
                <Styled.UserInfosContainer>
                  <Styled.UserFirstName>
                    {getFirstName(getUser().fullName)}
                  </Styled.UserFirstName>
                  <Styled.UserImageContainer>
                    <UserImage
                      imgSrc={getUser().imageUrl}
                      userName={getUser().fullName}
                    />
                  </Styled.UserImageContainer>
                </Styled.UserInfosContainer>
              </Link>
            ) : (
              <Styled.LoginIconWrapper
                onClick={openLogin}
                dangerouslySetInnerHTML={{
                  __html: loginContent.header_login_icon,
                }}
              ></Styled.LoginIconWrapper>
            )}
          </Styled.LoginOption>
        </Styled.OptionsWrapper>
        <Styled.MenuList>{menuListItems}</Styled.MenuList>
      </Styled.MenuModalContainer>
      {
        <Styled.MenuOverlay
          role="overlay"
          onClick={closeMenu}
        ></Styled.MenuOverlay>
      }
    </Fragment>
  );
};

export default MenuModal;
