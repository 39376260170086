// Third party libs/components
import React, { useEffect, useRef, useState } from 'react';
// import { navigate } from '@reach/router';
import { navigate } from 'gatsby';

// Components
import * as Styled from './AircraftCard.style';
import Certifications from '@components/Certifications';
import Amenities from '@components/Amenities';

// Utils
import { useCurrentLang } from '@utils/translate';
import { translate as t } from '@utils/translate';
import makeAircraftLink from '@utils/makeAircraftLink';
import { formatCurrency } from '@utils/currency';

const AircraftCard = ({
  id,
  pictures,
  amenities,
  price,
  currency,
  certifications,
  onClick,
  aircraft,
}) => {
  const [limitToShow, setLimitToShow] = useState(3);
  const language = useCurrentLang();
  const TEXTS = {
    ENTIRE_AIRCRAFT: t('entireAircraft'),
    BOOK: t('book'),
  };

  const amenitiesRef = useRef();
  const amenityRef = useRef();
  const bookNowRef = useRef();

  const calculateLimitToShowAmenities = () => {
    const amenitiesContainerSize = amenitiesRef?.current?.clientWidth - 50;
    const bookNowSize = bookNowRef?.current?.offsetWidth;
    const amenitySize = amenityRef?.current?.offsetWidth || 45;

    const numberAmenitiesToShow = Math.floor(
      (amenitiesContainerSize - bookNowSize) / amenitySize
    );

    setLimitToShow(numberAmenitiesToShow);
  };

  useEffect(() => {
    window.addEventListener('resize', calculateLimitToShowAmenities);
    setTimeout(() => {
      calculateLimitToShowAmenities();
    }, 200);

    return () => {
      window.removeEventListener('resize', calculateLimitToShowAmenities);
    };
  }, []);

  return (
    <Styled.Container>
      <Styled.Card>
        <Styled.CardImages
          onClick={() => navigate(makeAircraftLink(aircraft, language))}
        >
          <Styled.CardMainImage src={pictures[0]?.url} />
          <Styled.CardGallery responsive={pictures?.length <= 4}>
            {pictures.slice(1, 5).map(item => {
              return (
                <Styled.ImgResponsive
                  t={pictures.length === 3}
                  key={item.id}
                  src={item.url}
                />
              );
            })}
          </Styled.CardGallery>
        </Styled.CardImages>
        <Styled.CardInformation>
          {certifications && (
            <Certifications
              certifications={certifications}
              governmentOnly={true}
            />
          )}
          <Styled.ContentInformationPrice>
            <Styled.CardInformationPrice>
              {' '}
              {TEXTS.ENTIRE_AIRCRAFT}{' '}
            </Styled.CardInformationPrice>
            <Styled.CardPrice>
              {formatCurrency({ value: price, currency })}
            </Styled.CardPrice>
          </Styled.ContentInformationPrice>
        </Styled.CardInformation>
      </Styled.Card>
      <Styled.SubCard ref={amenitiesRef}>
        <Amenities
          showTitle={false}
          amenities={amenities}
          limitToShow={limitToShow}
          imgSize={2}
          theme="green"
          ref={amenityRef}
        />
        <Styled.BookNow
          ref={bookNowRef}
          onClick={() => {
            onClick(id);
          }}
        >
          {' '}
          {TEXTS.BOOK}{' '}
        </Styled.BookNow>
      </Styled.SubCard>
    </Styled.Container>
  );
};

export default AircraftCard;
