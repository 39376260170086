// Third party libs/components
import React, { useMemo, useState, useEffect } from 'react';
import P from 'prop-types';
import * as Styled from './Slider.style';
import { sprintf } from 'sprintf-js';
import Slider from 'react-slick';

// Components
import ButtonShowMore from './ButtonShowMore';

// Helpers
import getSliderSettings from './sliderSettingsHelper';
import GlobalStyles from './sliderStylesHelper';

// Utils
import { translate as t } from '@utils/translate';

const SliderContainer = ({
  title = '',
  Card,
  items,
  settingsName = 'small',
  settings = {},
  stylesName = 'normal',
  hasGrid = false,
  GridButton,
  gridStep = 12,
}) => {
  const [counterVisibleItems, setCounterVisibleItems] = useState(4);
  const [visibleItems, setVisibleItems] = useState(items);
  const [gridPage, setGridPage] = useState(0);
  const sliderSettings = {
    ...getSliderSettings(settingsName),
    ...settings,
  };
  const randomClassName = useMemo(
    () => `slider-container-wrapper-${Math.floor(Math.random() * 10000)}`,
    [stylesName]
  );
  const TEXTS = {
    GRID_BUTTON: t('moreFlights'),
  };

  useEffect(() => {
    setVisibleItems(items);
  }, [items]);

  const renderCards = mode => {
    if (mode === 'grid') {
      return visibleItems.map((itemProps, index) => {
        return (
          <Styled.GridItem
            data-test={`card-${title.trim()}-${index}`}
            animationIndex={index - (gridPage - 1) * gridStep}
            key={index}
          >
            <Card {...itemProps} key={index} />
          </Styled.GridItem>
        );
      });
    } else {
      const slicedVisibleItems = items.slice(0, gridStep);

      return slicedVisibleItems.map((itemProps, index) => {
        return (
          <Styled.SliderItem
            data-test={`card-${title.replaceAll(' ', '')}-${index}`}
            key={index}
          >
            <Card {...itemProps} key={index} />
          </Styled.SliderItem>
        );
      });
    }
  };

  const renderGridButton = () => {
    if (GridButton) return GridButton;

    const label = sprintf(TEXTS.GRID_BUTTON, calcItemsForNextPage());
    return <ButtonShowMore label={label} />;
  };

  const calcItemsForNextPage = () => {
    return counterVisibleItems + gridStep > items.length
      ? items.length - counterVisibleItems
      : gridStep;
  };

  const handleMoreItems = () => {
    setGridPage(gridPage + 1);
    setCounterVisibleItems(counterVisibleItems + calcItemsForNextPage());
  };

  useEffect(() => {
    if (counterVisibleItems !== 0)
      setVisibleItems(items.slice(0, counterVisibleItems));
  }, [counterVisibleItems]);

  if (!items) return null;

  return (
    <div className={randomClassName}>
      <GlobalStyles name={stylesName} parentClassName={randomClassName} />
      <Styled.SliderContainer>
        {hasGrid && gridPage > 0 ? (
          <Styled.Grid>{renderCards('grid')}</Styled.Grid>
        ) : (
          <div>
            <Slider {...sliderSettings} className={'section-slider'}>
              {renderCards('slider')}
            </Slider>
          </div>
        )}
        {hasGrid && counterVisibleItems < items.length && (
          <Styled.ButtonWrapper onClick={handleMoreItems}>
            {renderGridButton()}
          </Styled.ButtonWrapper>
        )}
      </Styled.SliderContainer>
    </div>
  );
};

SliderContainer.propTypes = {
  title: P.string,
  Card: P.func,
  items: P.array,
  settingsName: P.string,
  settings: P.object,
  stylesName: P.string,
  hasGrid: P.bool,
  GridButton: P.element,
  gridStep: P.number,
};

export default SliderContainer;
