import styled, { keyframes } from 'styled-components';

const animationInDefault = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const COLORS = {
  white: '#ffffff',
  buttonGreen: '#009688',
  buttonHoverGreen: '#01BEA4',
};

const SharedFlightNoReturn = styled.div`
  animation: ${animationInDefault} 0.5s 0.3s forwards;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 125px;
  opacity: 0;
  padding: 10px;
  width: 495px;

  .passengers-info {
    align-items: center;
    display: flex;
    flex-direction: row;

    &__main-message {
      font-size: 18px;
      margin: 0;
      padding: 5px;
      width: 65%;
    }

    &__seats-available {
      width: 35%;
      margin: 0;
    }
  }

  .action-buttons {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;

    button {
      background-color: transparent;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 13px;
      padding: 10px 20px;
      transition: all 0.2s ease-in-out;

      &:first-of-type {
        margin-right: 20px;
      }
    }

    &__cancel {
      background-color: ${COLORS.buttonGreen} !important;
      color: white;
      font-weight: 700;

      &:hover {
        background-color: ${COLORS.buttonHoverGreen} !important;
      }
    }

    &__proceed {
      color: ${COLORS.buttonGreen};
      font-weight: 700;
    }
  }
`;

export { SharedFlightNoReturn };
