// TODO: REFACTORING
import React, { useState, useEffect, useMemo } from 'react';
import Style from './style.module.css';
import { useStore, useSelector } from 'react-redux';
import { withBreakpoints } from 'gatsby-plugin-breakpoints';
import { navigate } from 'gatsby';
import { sprintf } from 'sprintf-js';

import PassengersCountSelector from '@components/PassengersCountSelector';
import Button from '@components/ProposalCard/ProposalCardDetail/Button';
import Modal from '@components/Modal';

import { useCurrentLang } from '@utils/translate';
import { translate as t } from '@utils/translate';
import { getFlightSearchUrl } from '@utils/search';
import { encodeObjectToken } from '@utils/token';
import { setSeats } from '@store/reducers/cart/operations';
import { flightSearchSecret } from '@utils/secrets';
import { formatDateString, dateToString } from '@utils/dates';
import { formatCurrency } from '@utils/currency';
import Snackbar from '@components/Snackbar';

const FlightInquire = ({
  show = true,
  canChangeSeats = true,
  availableSeats,
  selectedSeats,
  isSharedFlight,
  breakpoints,
  onGoToCheckout,
  onChangePrice,
  searchParams,
  flightId,
  returningFlightsStats,
  selectedOriginFlight,
  tripType = 'one-way',
  flightType = 'flights',
  currency,
}) => {
  const seats = useSelector(state => state.cart.seats);
  const [tripTypeState, setTripTypeState] = useState(tripType);
  const numberOfSeats = availableSeats.length;
  const store = useStore();
  const [isLargeScreen, setIsLargeScreen] = useState(!breakpoints.md);
  const [minSeatsFilled, setMinSeatsFilled] = useState(false);
  const [originFlight, setOriginFlight] = useState(null);
  const [canShowSeatsAlert, setCanShowSeatsAlert] = useState({
    allowed: false,
    times: 0,
  });
  const [canShowFlightsBackAlert, setCanShowFlightsBackAlert] = useState({
    allowed: false,
    times: 0,
  });
  const currentLang = useCurrentLang();
  const TEXTS = {
    RESERVE_SEATS_LABEL: t('seatsToReserve'),
    VALUE: selectedOriginFlight.id ? t('returnFlightPrice') : t('flightPrice'),
    VALUE_SEAT: t('pricePerSeat'),
    NUMBER_OF_SEATS: t('numberOfSeats'),

    SEATS_ALERT_TITLE: t('noFlightBackForNSeats'),
    SEATS_ALERT_SUBTITLE: t('onlyFlightsBackForNSeats'),
    NO_SEATS_ALERT_TITLE: t('noFlightsBackForThisDate'),

    SEATS_ALERT_CANCEL_BUTTON: t('back'),
    SEATS_ALERT_CONFIRM_BUTTON: t('idLikeToProceed'),

    FLIGHTS_BACK_ALERT_TITLE: t('flightsBackFound'),
    FLIGHTS_BACK_ALERT_SUBTITLE: t('wouldYouLikeToSeeThem'),
    FLIGHTS_BACK_ALERT_CANCEL_BUTTON: t('chooseReturnFlight'),
    FLIGHTS_BACK_ALERT_CONFIRM_BUTTON: t('bookOneWay'),

    UNAVAILABLE_FLIGHT: t('unavailableFlight'),

    CHECKOUT_BUTTON: {
      GOING_FLIGHT: t('bookGoingFlight'),
      RETURNING_FLIGHT: t('bookReturningFlight'),
      PAYMENT: t('goToPayment'),
    },
  };

  useEffect(() => {
    if (flightType === 'empty-leg' || isSharedFlight == '0') {
      store.dispatch(setSeats(numberOfSeats, numberOfSeats, 1));
    } else {
      store.dispatch(setSeats(numberOfSeats, selectedSeats, 1));
    }
  }, [numberOfSeats]);

  const calcPrice = (seats, selected) => {
    if (!seats.length) return 0;

    let totalPrice = 0;
    for (let i = 0; i < selected; i++) {
      totalPrice += seats[i].price;
    }
    return totalPrice;
  };

  const calcOriginFlightPrice = (seats, seatPrice) => {
    return seats * seatPrice;
  };

  useEffect(() => {
    setIsLargeScreen(!breakpoints.md);
  }, [breakpoints]);

  useEffect(() => {
    let isFilled = seats.filled > 0;

    if (onChangePrice) onChangePrice(calcPrice(availableSeats, seats.filled));
    setMinSeatsFilled(isFilled);
  }, [numberOfSeats, seats.filled]);

  const controlAlertForNumberOfSeats = numberOfSeats => {
    if (
      returningFlightsStats.maxAvailableSeats < numberOfSeats &&
      tripTypeState === 'round-trip--going' &&
      !canShowSeatsAlert.times
    )
      setCanShowSeatsAlert({
        allowed: true,
        times: 0,
      });
  };

  const handleIncreaseReservedSeats = amount => {
    store.dispatch(setSeats(numberOfSeats, amount, seats.minimum));
    controlAlertForNumberOfSeats(amount);
  };

  const handleDecreaseReservedSeats = amount => {
    store.dispatch(setSeats(numberOfSeats, amount, seats.minimum));
    controlAlertForNumberOfSeats(amount);
  };

  const formatPrice = price => {
    //currency
    return price.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const goToSearch = (returnDate, returnTime) => {
    const currentState = {
      flightId,
      selectedSeats: seats.filled,
      tripType: tripTypeState,
      searchParams: { ...searchParams },
    };
    const params = { ...searchParams };
    const token = encodeObjectToken(currentState, flightSearchSecret);

    if (returnDate && returnTime) {
      params.returnDate = returnDate;
      params.returnTime = returnTime;
    }

    const url = getFlightSearchUrl(
      params,
      'flights',
      currentLang,
      `st=${token}`
    );

    navigate(url);
  };

  const goToCheckout = validateReturningFlights => {
    if (
      validateReturningFlights &&
      tripTypeState === 'one-way' &&
      returningFlightsStats.countFlights &&
      returningFlightsStats.maxAvailableSeats >= seats.filled
    ) {
      setCanShowFlightsBackAlert(prevState => {
        return {
          allowed: true,
          times: prevState.times + 1,
        };
      });
    } else onGoToCheckout(seats.filled, flightId);
  };

  const acceptNoReturning = () => {
    setTripTypeState('one-way');
    setCanShowSeatsAlert(prevState => {
      return {
        allowed: false,
        times: prevState.times + 1,
      };
    });
  };

  const cancelNoReturning = () => {
    handleDecreaseReservedSeats(returningFlightsStats.maxAvailableSeats);
    setCanShowSeatsAlert(prevState => {
      return {
        allowed: false,
        times: prevState.times + 1,
      };
    });
  };

  const searchFlightsBack = () => {
    const returnMonthsLimit = 3;
    //let currentDateString = null;

    const currentDateString = formatDateString(
      decodeURIComponent(searchParams.departureDate),
      decodeURIComponent(searchParams.departureTime)
    );

    const currentDate = new Date(currentDateString);
    const returnDateLimit = new Date(
      currentDate.setMonth(currentDate.getMonth() + returnMonthsLimit)
    );
    const returnDateParam = encodeURIComponent(dateToString(returnDateLimit));
    const returnTimeParam = searchParams.departureTime;

    goToSearch(returnDateParam, returnTimeParam);
  };

  const renderFlightUnavailable = () => {
    return (
      <div className={Style.flightUnavailable}>
        <Snackbar type="info-white" icon="warning">
          {TEXTS.UNAVAILABLE_FLIGHT}
        </Snackbar>
      </div>
    );
  };

  const renderSeatsAlert = isActive => {
    if (!isActive) return null;

    if (!returningFlightsStats.maxAvailableSeats) {
      return (
        <div className={Style.alertContainer}>
          <h2 className={Style.alertTitle}>
            {sprintf(TEXTS.NO_SEATS_ALERT_TITLE, seats.filled)}
          </h2>
          <hr className={Style.alertDivider} />
          <div className={Style.alertActions}>
            <Button
              theme={'active'}
              label={TEXTS.SEATS_ALERT_CONFIRM_BUTTON}
              onClick={acceptNoReturning}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={Style.alertContainer}>
        <h2 className={Style.alertTitle}>
          {sprintf(TEXTS.SEATS_ALERT_TITLE, seats.filled)}
        </h2>
        <span className={Style.alertSubtitle}>
          {sprintf(
            TEXTS.SEATS_ALERT_SUBTITLE,
            returningFlightsStats.maxAvailableSeats
          )}
        </span>
        <hr className={Style.alertDivider} />
        <div className={Style.alertActions}>
          <Button
            theme={'default'}
            label={TEXTS.SEATS_ALERT_CANCEL_BUTTON}
            onClick={cancelNoReturning}
          />
          <Button
            theme={'active'}
            label={TEXTS.SEATS_ALERT_CONFIRM_BUTTON}
            onClick={acceptNoReturning}
          />
        </div>
      </div>
    );
  };

  const renderFlightsBackAlert = isActive => {
    if (!isActive) return <h1>FLIGHTS BACK</h1>;

    return (
      <div className={Style.alertContainer}>
        <h2 className={Style.alertTitle}>{TEXTS.FLIGHTS_BACK_ALERT_TITLE}</h2>
        <span className={Style.alertSubtitle}>
          {TEXTS.FLIGHTS_BACK_ALERT_SUBTITLE}
        </span>
        <hr className={Style.alertDivider} />
        <div className={Style.alertActions}>
          <Button
            theme={'default'}
            label={TEXTS.FLIGHTS_BACK_ALERT_CANCEL_BUTTON}
            onClick={searchFlightsBack}
          />
          <Button
            theme={'active'}
            label={TEXTS.CHECKOUT_BUTTON.PAYMENT}
            onClick={() => goToCheckout(false)}
          />
        </div>
      </div>
    );
  };

  const renderContinueButton = () => {
    let buttonData = {
      label: '',
      onClick: () => {},
    };

    switch (tripTypeState) {
      case 'one-way':
      case 'round-trip--returning':
        buttonData.label = TEXTS.CHECKOUT_BUTTON.PAYMENT;
        buttonData.onClick = () => {
          goToCheckout(true);
        };
        break;

      case 'round-trip--going':
        buttonData.label = TEXTS.CHECKOUT_BUTTON.GOING_FLIGHT;
        buttonData.onClick = () => {
          returningFlightsStats.countFlights
            ? goToSearch()
            : goToCheckout(true);
        };
        break;

      default:
        buttonData.label = TEXTS.CHECKOUT_BUTTON.PAYMENT;
        buttonData.onClick = () => {
          goToCheckout(true);
        };
        break;
    }

    return (
      <Button
        theme={minSeatsFilled ? 'active' : 'disabled'}
        label={buttonData.label}
        onClick={buttonData.onClick}
        disabled={!minSeatsFilled}
      />
    );
  };

  const renderTripDetails = () => {
    const TEXTS = {
      RESERVATION_SUMMARY_TITLE: t('reservationSummary'),
    };
    const destinationFlightPrice = calcPrice(availableSeats, seats.filled);
    const originFlightPrice = calcOriginFlightPrice(
      seats.filled,
      parseFloat(selectedOriginFlight.seatPrice)
    );
    return (
      <div className={Style.tripDetailsInfoContainer}>
        <div
          className={[
            Style.tripDetailsInfoBlock,
            Style.tripDetailsInfoTitle,
          ].join(' ')}
        >
          <strong>{TEXTS.RESERVATION_SUMMARY_TITLE}</strong>
        </div>
        <div className={Style.tripDetailsInfoBlock}>
          <span>
            {t('flight')} {selectedOriginFlight.path} ({seats.filled}{' '}
            {t('seats').toLowerCase()})
          </span>
          <strong>
            {formatCurrency({
              value: originFlightPrice,
              currency: selectedOriginFlight.originCurrency,
            })}
          </strong>
        </div>
        <div className={Style.tripDetailsInfoBlock}>
          <span>
            {t('flight')} {selectedOriginFlight.returningPath} ({seats.filled}{' '}
            {t('seats').toLowerCase()})
          </span>
          <strong>
            {formatCurrency({
              value: destinationFlightPrice,
              currency: currency,
            })}
          </strong>
        </div>
        <div
          className={[
            Style.tripDetailsInfoBlock,
            Style.tripDetailsInfoTotalizer,
          ].join(' ')}
        >
          <span>{t('totalPrice')}</span>
          <strong className={Style.tripDetailsInfoTotalPrice}>
            {formatCurrency({
              value: originFlightPrice + destinationFlightPrice,
              currency: currency,
            })}
          </strong>
        </div>
        <div className={[Style.actions, Style.summary].join(' ')}>
          {renderContinueButton()}
        </div>
      </div>
    );
  };

  if (!show) return null;

  if (!numberOfSeats) return renderFlightUnavailable();

  return (
    <section className={[Style.FlightInquire].join(' ')}>
      <Modal
        opened={canShowSeatsAlert.allowed && !canShowSeatsAlert.times}
        closeModal={() => {}}
        type={'center'}
        hasCloseButton={false}
      >
        {renderSeatsAlert(
          canShowSeatsAlert.allowed && !canShowSeatsAlert.times
        )}
      </Modal>
      <Modal
        opened={canShowFlightsBackAlert.allowed}
        closeModal={() => {}}
        type={'center'}
        hasCloseButton={false}
      >
        {renderFlightsBackAlert(canShowFlightsBackAlert.allowed)}
      </Modal>
      <div className={Style.info}>
        <div className={Style.infoFlightPrice}>
          <strong>{TEXTS.VALUE}: </strong>
          <span>
            {numberOfSeats &&
              formatCurrency({
                value: calcPrice(availableSeats, seats.filled),
                currency: currency,
              })}
          </span>
        </div>

        {isSharedFlight != '0' ? (
          <div className={Style.infoSeatPrice}>
            <span>{TEXTS.VALUE_SEAT}: </span>
            <span>
              {numberOfSeats &&
                formatCurrency({
                  value: availableSeats[0].price,
                  currency: currency,
                })}
            </span>
          </div>
        ) : (
          <div className={Style.infoSeatPrice}>
            <span>{TEXTS.NUMBER_OF_SEATS}: </span>
            <span>{availableSeats.length}</span>
          </div>
        )}
      </div>
      {selectedOriginFlight.id ? (
        renderTripDetails()
      ) : (
        <>
          {flightType !== 'empty-leg' && isSharedFlight != '0' && (
            <div className={Style.reservedSeatsWrapper}>
              <span>{TEXTS.RESERVE_SEATS_LABEL} </span>

              <div className={Style.passengersCountSelectorWrapper}>
                <PassengersCountSelector
                  onIncrement={handleIncreaseReservedSeats}
                  onDecrement={handleDecreaseReservedSeats}
                  initialValue={selectedSeats}
                  currentValue={seats.filled}
                  theme={'default'}
                  minPassengers={1}
                  maxPassengers={numberOfSeats}
                  showMaxPassengers={false}
                  showTitle={false}
                  enabled={canChangeSeats}
                />
              </div>
            </div>
          )}
          <div className={Style.actions}>{renderContinueButton()}</div>
        </>
      )}
    </section>
  );
};

export default withBreakpoints(FlightInquire);
