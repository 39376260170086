import { getLangParam } from '@utils/translate';
import { reduceLocationParams } from '@utils/search';
import {
  dateToString,
  dateToDay,
  dateToMonthName,
  stringToDate,
} from '@utils/dates';
import { getFlightTypeId, getFlightTypeString } from '@utils/search';
import headerQuery from './graphql/headerQuery';
import useOutsideClick from '@utils/useOutsideClick';
import { useCurrentLang } from '@utils/translate';
import { translate as t } from '@utils/translate';

/**
 * Forces a page reload with new params
 *
 * @param {object} newParams
 */
const redefineUrlAndReload = newParams => {
  const lang = getLangParam();
  // Mount the new path
  const searchLink =
    '/p/search/' +
    Object.values(newParams)
      .map(param => (param ? encodeURIComponent(param) : '-'))
      .join('/');

  window.location.replace(`${searchLink}`);
};

// Calls a new request to reload the components with new params
/**
 * Forces a page reload with inverted origin/destination
 *
 * @param {object} newParams
 */
const redefineSearchAndReload = ({ newParams, oldParams }) => {
  const lang = getLangParam();
  // Mount the new path
  let searchLink =
    '/p/search/' +
    Object.values(newParams)
      .map(param => (param ? encodeURIComponent(param) : '-'))
      .join('/');

  if (newParams.origin && newParams.destination) {
    newParams.origin = reduceLocationParams(newParams.origin.split('&'));
    newParams.destination = reduceLocationParams(
      newParams.destination.split('&')
    );
  } else {
    newParams = {
      ...newParams,
      origin: {
        type: oldParams.originType,
        id: oldParams.originId,
      },
      destination: {
        type: oldParams.destinationType,
        id: oldParams.destinationId,
      },
    };
  }

  let searchParams = '';
  if (typeof window === 'object') {
    searchParams = window.location.search;
    searchLink += searchParams ? searchParams : '';
  }

  window.location.replace(`${searchLink}`);
};

const mountMenuItems = ({ query, pageLang }) => {
  const langMenus = {
    pt_BR: 'header-menu',
    en_US: 'header-menu-english',
    es_ES: 'header-menu-espanol',
    // it_IT: 'header-menu-italiano',
  };
  const items = query.allWordpressWpApiMenusMenusItems.nodes.reduce(
    (obj, { slug, items }) => {
      obj[slug] = items;
      return obj;
    },
    {}
  );
  const menuItems = pageLang
    ? items[langMenus[pageLang]]
    : items['header-menu'];
  return menuItems;
};

export {
  redefineUrlAndReload,
  redefineSearchAndReload,
  mountMenuItems,
  // external helpers
  dateToString,
  dateToDay,
  dateToMonthName,
  stringToDate,
  getFlightTypeId,
  getFlightTypeString,
  headerQuery,
  useOutsideClick,
  t,
  useCurrentLang,
};
