// TODO: REFACTORING
import React from 'react';
import { useSelector } from 'react-redux';
import Style from './style.module.css';
import { translate as t } from '../../utils/translate';
import aircraftModelNameHelper from '@utils/aircraftModelNameHelper';
import { formatCurrency } from '@utils/currency';
import { sprintf } from 'sprintf-js';

const FlightCard = ({ flight, onClick, onMouseDown }) => {
  const TEXTS = {
    TRIP_TIME_LABEL: t('timeOfTravel'),
    VALUE_LABEL: t('startingAt'),
  };
  const selectedCurrency = useSelector(state => state.general.currency);

  function formatedTime(time) {
    const hour = Math.floor(time / 60);
    const minutes = time % 60;

    if (minutes <= 9) {
      return `${hour}:0${minutes}h`;
    }
    return `${hour}:${minutes}h`;
  }

  return (
    <div
      onClick={e => {
        onClick(e, flight.aircraftModel.id);
      }}
      onMouseDown={e => {
        if (onMouseDown) onMouseDown(e);
      }}
    >
      <div className={Style.flightCard}>
        <div className={Style.imageContainer}>
          <div className={Style.priceContainer}>
            <div className={Style.priceContent}>
              <span className={Style.price}>
                {TEXTS.VALUE_LABEL} <br />
                {formatCurrency({
                  value: flight.startingPrice,
                  currency: selectedCurrency.code,
                })}
              </span>
            </div>
          </div>
          <img
            className={Style.image}
            src={flight.aircraftModel.highlightImageUrl}
            alt={
              flight.aircraftModel.size
                ? flight.aircraftModel.size.name
                : 'name'
            }
          />
        </div>
        <div className={Style.cardContent}>
          <div className={Style.nameContainer}>
            <div
              className={[Style.aircraftCategory, Style.hideOverflowText].join(
                ' '
              )}
            >
              <span>
                {flight.aircraftModel.size
                  ? flight.aircraftModel.size.name
                  : ''}
              </span>
            </div>
            <div
              className={[Style.aircraftName, Style.hideOverflowText].join(' ')}
            >
              <span>{`${aircraftModelNameHelper(flight.aircraftModel)}`}</span>
            </div>
            <div
              className={[Style.flightDuration, Style.hideOverflowText].join(
                ' '
              )}
            >
              <span>
                {sprintf(
                  TEXTS.TRIP_TIME_LABEL,
                  flight.aircrafts[0].estimatedTime >= 60
                    ? formatedTime(flight.aircrafts[0].estimatedTime)
                    : `${flight.aircrafts[0].estimatedTime}min`
                )}
              </span>
            </div>
          </div>
          <div className={Style.buttonContainer}>
            <button className={Style.button}>{t('selectAircraftCard')}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightCard;
