import React, { useReducer, createContext } from 'react';
import aircraftModelNameHelper from '@utils/aircraftModelNameHelper';

export const FiltersStateContext = createContext();
export const FiltersDispatchContext = createContext();

const IFilterOptions = {
  maxNumberOfPassengers: 0,
  flightType: 'charter',
  originAirports: {},
  originHelipads: {},
  destinationAirports: {},
  destinationHelipads: {},
  aircrafts: {
    airplanes: {
      price: {
        min: 9999999,
        max: 0,
      },
      categoriesList: null,
      modelsList: null,
    },
    helicopters: {
      price: {
        min: 9999999,
        max: 0,
      },
      categoriesList: null,
      modelsList: null,
    },
  },
};

const initialState = {
  openedFilterId: '',
  filters: {
    numberOfPassengers: 1,
    flightType: 'charter',
    destinationSpots: [],
    originSpots: [],
    airplanes: {
      price: {
        min: 0,
        max: 9999999,
      },
      categoriesList: [],
      modelsList: [],
    },
    helicopters: {
      price: {
        min: 0,
        max: 9999999,
      },
      categoriesList: [],
      modelsList: [],
    },
  },
  originalData: null,
  filteredData: null,
  originalFilterOptions: {},
  //filteredFilterOptions: {}
};

const getSeatPrice = (seats = [], edge = 'min') => {
  let value = 0;

  if (edge === 'min') {
    seats.forEach(seat => {
      let price = parseInt(seat.price);
      if (price > value) value = price;
    });
  } else {
    value = 9999999999999;
    seats.forEach(seat => {
      let price = parseInt(seat.price);
      if (price < value) value = price;
    });
  }

  return value;
};

const filtersReducer = (state, action) => {
  const newFilteredState = reducedData => {
    return {
      ...state,
      filters: {
        ...state.filters,
        ...(action.filters ? action.filters : {}),
      },
      filteredData: reducedData,
    };
  };

  switch (action.type) {
    // handles the filter's UI 'opened' state
    case 'setOpenedId': {
      return {
        ...state,
        openedFilterId: action.openedFilterId,
      };
    }
    // set new data that will be filtered later on
    case 'setData': {
      return {
        ...state,
        originalData: [...action.data],
        filteredData: [...action.data],
      };
    }
    // filtering action
    /**
     * process:
     *  reduces the original data as routes, models and aircrafts, that passes in the selected filters
     *  return the result as a state: filteredData
     * */

    // ***********************************************************************
    // * OBS: NEEDS ADJUSTMENTS AFTER THE AIRCRAFT TYPE ID BECOMES AVAILABLE *
    // ***********************************************************************
    case 'filterData': {
      let routes = null;

      if (state.originalData) {
        const originalData = [...state.originalData];
        const currentFilters = { ...state.filters, ...action.filters };
        const filtersToApply = action.filtersToApply || ['all'];
        const flightType = state.originalFilterOptions.flightType;

        // routes reducer
        routes = originalData.reduce((reducedRoutes, route) => {
          let validRoute = true;
          let models = [];

          // FILTER RULE: by aerodrome/spots
          // ---------------
          if (
            filtersToApply[0] === 'all' ||
            filtersToApply.includes('aerodromes')
          ) {
            validRoute &&
              (validRoute = currentFilters.originSpots.includes(
                parseInt(route.originSpot.id)
              ));
            validRoute &&
              (validRoute = currentFilters.destinationSpots.includes(
                parseInt(route.destinationSpot.id)
              ));
          }
          // ---------------

          // models reducer
          if (validRoute) {
            models = route.aircraftModels.reduce((reducedModels, model) => {
              let validModel = true;
              let aircrafts = [];
              let category = model.aircraftModel.size
                ? model.aircraftModel.size.name
                : '';
              let aircraftType = category.includes('helicopt')
                ? 'helicopters'
                : 'airplanes';

              // FILTER RULE: by aircraft/flight price
              // ---------------
              if (
                filtersToApply[0] === 'all' ||
                filtersToApply.includes('aircrafts')
              ) {
                let lowerPrice =
                  flightType === 'charter'
                    ? model.startingPrice
                    : getSeatPrice(route.seats, 'min');
                let higherPrice =
                  flightType === 'charter'
                    ? model.startingPrice
                    : getSeatPrice(route.seats, 'max');

                validModel &&
                  (validModel =
                    // higher than minimum
                    currentFilters[aircraftType].price.min <= lowerPrice &&
                    // lower than maximum
                    currentFilters[aircraftType].price.max >= higherPrice);
              }
              // ---------------

              // FILTER RULE: by aircraft model/aircraft category
              // ---------------
              if (
                filtersToApply[0] === 'all' ||
                filtersToApply.includes('aircrafts')
              ) {
                // models
                validModel &&
                  (validModel = currentFilters[
                    aircraftType
                  ].modelsList.includes(
                    aircraftModelNameHelper(model.aircraftModel)
                  ));
                // categories
                validModel &&
                  (validModel = currentFilters[
                    aircraftType
                  ].categoriesList.includes(model.aircraftModel.size.name));
              }
              // ---------------

              // aircrafts reducer
              if (validModel) {
                aircrafts = model.aircrafts.reduce(
                  (reducedAircrafts, aircraft) => {
                    let validAircraft = true;

                    // FILTER RULE: by aircraft year
                    // ---------------
                    // aircraft.aircraft.paintingYear
                    // ---------------

                    // FILTER RULE: by quantity of passengers
                    // ---------------
                    if (
                      filtersToApply[0] === 'all' ||
                      filtersToApply.includes('passengers')
                    ) {
                      validAircraft &&
                        (validAircraft =
                          parseInt(aircraft.aircraft.quantityOfPassengers) >=
                          currentFilters.numberOfPassengers);
                    }
                    // ---------------

                    if (validAircraft) reducedAircrafts.push(aircraft);

                    return reducedAircrafts;
                  },
                  []
                );
              }

              // if it is a valid model, but there are no valid aircrafts, then invalidate the model
              if (!aircrafts.length) validModel = false;

              if (validModel)
                reducedModels.push({ ...model, aircrafts: aircrafts });

              return reducedModels;
            }, []);
          }

          // if it is a valid route, but there are no valid models, then invalidate the route
          if (!models.length) validRoute = false;

          if (validRoute)
            reducedRoutes.push({ ...route, aircraftModels: models });

          return reducedRoutes;
        }, []);
      }

      return newFilteredState(routes);
    }
    case 'getOriginalFilterOptions': {
      const originalData = [...state.originalData];
      const originalFilterOptions = JSON.parse(JSON.stringify(IFilterOptions));
      const flightType = action.flightType;

      originalFilterOptions.aircrafts.helicopters.categoriesList = new Set();
      originalFilterOptions.aircrafts.helicopters.modelsList = new Set();
      originalFilterOptions.aircrafts.airplanes.categoriesList = new Set();
      originalFilterOptions.aircrafts.airplanes.modelsList = new Set();

      originalData.forEach(route => {
        // get origins
        if (route.originSpot['helipad'])
          originalFilterOptions.originHelipads[route.originSpot.id] =
            route.originSpot.name;
        else
          originalFilterOptions.originAirports[route.originSpot.id] =
            route.originSpot.name;

        // get destinations
        if (route.destinationSpot['helipad'])
          originalFilterOptions.destinationHelipads[route.destinationSpot.id] =
            route.destinationSpot.name;
        else
          originalFilterOptions.destinationAirports[route.destinationSpot.id] =
            route.destinationSpot.name;

        // iterate through models to get prices, categories and models
        route.aircraftModels.forEach(aircraftModel => {
          let category = aircraftModel.aircraftModel.size
            ? aircraftModel.aircraftModel.size.name
            : '';
          let model = aircraftModel.aircraftModel.size
            ? aircraftModelNameHelper(aircraftModel.aircraftModel)
            : '';
          let startingPrice =
            flightType === 'charter'
              ? aircraftModel.startingPrice
              : getSeatPrice(route.seats);

          // this should be changed to a better check after the id is available
          let aircraftType = category.includes('helicopt')
            ? 'helicopters'
            : 'airplanes';

          // get category
          if (category)
            originalFilterOptions.aircrafts[aircraftType].categoriesList.add(
              category
            );

          // get model
          if (model)
            originalFilterOptions.aircrafts[aircraftType].modelsList.add(model);

          // get prices
          if (
            startingPrice <
            originalFilterOptions.aircrafts[aircraftType].price.min
          )
            originalFilterOptions.aircrafts[
              aircraftType
            ].price.min = startingPrice;

          if (
            startingPrice >
            originalFilterOptions.aircrafts[aircraftType].price.max
          )
            originalFilterOptions.aircrafts[
              aircraftType
            ].price.max = startingPrice;
        });
      });

      // convert the 'distincted' objects to arrays
      originalFilterOptions.aircrafts.helicopters.categoriesList = Array.from(
        originalFilterOptions.aircrafts.helicopters.categoriesList
      );
      originalFilterOptions.aircrafts.helicopters.modelsList = Array.from(
        originalFilterOptions.aircrafts.helicopters.modelsList
      );
      originalFilterOptions.aircrafts.airplanes.categoriesList = Array.from(
        originalFilterOptions.aircrafts.airplanes.categoriesList
      );
      originalFilterOptions.aircrafts.airplanes.modelsList = Array.from(
        originalFilterOptions.aircrafts.airplanes.modelsList
      );
      originalFilterOptions.originAirports = Object.entries(
        originalFilterOptions.originAirports
      );
      originalFilterOptions.originHelipads = Object.entries(
        originalFilterOptions.originHelipads
      );
      originalFilterOptions.destinationAirports = Object.entries(
        originalFilterOptions.destinationAirports
      );
      originalFilterOptions.destinationHelipads = Object.entries(
        originalFilterOptions.destinationHelipads
      );

      originalFilterOptions.flightType = action.flightType;

      return {
        ...state,
        originalFilterOptions: { ...originalFilterOptions },
      };
    }
    case 'getFilteredFilterOptions': {
      return {
        ...state,
        openedFilterId: action.openedFilterId,
      };
    }
    case 'orderByPrice': {
      const flightType = state.originalFilterOptions.flightType;
      let sortedData = [];

      const sortByLowerPrice = (a, b) => {
        if (flightType === 'flights')
          return getSeatPrice(a.seats) - getSeatPrice(b.seats);
        else return a.startingPrice - b.startingPrice;
      };

      const sortByHigherPrice = (a, b) => {
        if (flightType === 'flights')
          return getSeatPrice(b.seats) - getSeatPrice(a.seats);
        else return b.startingPrice - a.startingPrice;
      };

      const sort = sortFunction => {
        sortedData = [...state.filteredData];

        if (flightType === 'flights') {
          sortedData.sort(sortFunction);
        } else {
          sortedData.forEach(route => {
            route.aircraftModels.sort(sortFunction);
          });
        }

        return 0;
      };

      if (state.filteredData) {
        // lower price
        if (action.orderBy === 'lower-price') sort(sortByLowerPrice);
        // higher price
        else if (action.orderBy === 'higher-price') sort(sortByHigherPrice);
      }

      return newFilteredState(sortedData);
    }
    case 'orderByDate': {
      const flightType = state.originalFilterOptions.flightType;
      let sortedData = [];

      const sortByDate = (a, b) => {
        return new Date(a.flightDatetime) - new Date(b.flightDatetime);
      };

      const sort = sortFunction => {
        sortedData = [...state.filteredData];

        if (flightType === 'flights') sortedData.sort(sortFunction);

        return 0;
      };

      if (state.filteredData) {
        sort(sortByDate);
      }

      return newFilteredState(sortedData);
    }
    default:
      throw new Error('Bad action type');
  }
};

const FiltersContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filtersReducer, initialState);
  return (
    <FiltersStateContext.Provider value={state}>
      <FiltersDispatchContext.Provider value={dispatch}>
        {children}
      </FiltersDispatchContext.Provider>
    </FiltersStateContext.Provider>
  );
};

export default FiltersContextProvider;
