import styled, { keyframes } from 'styled-components';

const show = keyframes`
  from {
    opacity: 0;
    transform:translateY(-20px) ;
  } to {
    opacity: 1;
    transform:translateY(0px) ;
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;

  > div {
    flex-basis: 25%;
    padding: 0 8px;
    text-align: center;
    margin-bottom: 32px;
  }

  > div:nth-child(4n) {
    padding-right: 0;
  }

  > div:nth-child(4n-3) {
    padding-left: 0;
  }

  @media (max-width: 1023px) {
    padding-top: 10px;

    > div {
      flex-basis: 50%;
      padding: 0;
    }
  }

  @media (max-width: 767px) {
    > div {
      flex-basis: 100%;
    }
  }
`;

export const FlightCardContainer = styled.div`
  animation: ${show} 0.4s 0s 1 forwards;
`;
