// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import * as Styled from './CheckboxList.style';

import Checkbox from './Checkbox';

import { translate as t } from '@utils/translate';

const CheckboxList = ({ title, items, onClick, onToggleCheckAll }) => {
  const [allChecked, setAllChecked] = useState(true);
  const TEXTS = {
    CHECK_ALL: t('checkAll'),
    UNCHECK_ALL: t('uncheckAll'),
  };

  const handleClick = (index, selected) => {
    onClick(index, selected);
  };

  useEffect(() => {
    let counter = items.length;
    let allSelected = false;

    allSelected = true;
    while (counter--) {
      // if at least 1 is false then stop
      if (!items[counter].selected) {
        allSelected = false;
        break;
      }
    }

    setAllChecked(allSelected);
  }, [items, setAllChecked]);

  const handleToggleSelected = checkAll => {
    onToggleCheckAll(checkAll);
  };

  if (!items.length) return null;

  return (
    <Styled.CheckboxList>
      <Styled.ListHeader>
        <Styled.ListTitle>{title}</Styled.ListTitle>
        <Styled.ToggleSelectWrapper>
          {allChecked ? (
            <Styled.ToggleSelect onClick={() => handleToggleSelected(false)}>
              {TEXTS.UNCHECK_ALL}
            </Styled.ToggleSelect>
          ) : (
            <Styled.ToggleSelect onClick={() => handleToggleSelected(true)}>
              {TEXTS.CHECK_ALL}
            </Styled.ToggleSelect>
          )}
        </Styled.ToggleSelectWrapper>
      </Styled.ListHeader>
      <Styled.List>
        {items.map(({ text, selected }, index) => {
          return (
            <Checkbox
              key={index}
              selected={selected}
              onClick={() => handleClick(index, !selected)}
              text={text}
            />
          );
        })}
      </Styled.List>
    </Styled.CheckboxList>
  );
};

export default CheckboxList;
