// TODO: REFACTORING
import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn, isBrowser } from '../../services/auth';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && isBrowser()) {
    navigate('/');
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
