import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  position: fixed;
  top: calc(var(--search-sales-results-header-height) * 1.5);
  left: 0;
  width: 100%;
  height: 100vh;

  z-index: 9999999999;
`;

export const Title = styled.p`
  font-size: 21px;
  font-weight: 600;
  color: #444444;
  padding: 0 40px 8px;
  text-transform: capitalize;
`;

export const SalesResultSectionWrapper = styled.section`
  position: relative;
  margin-top: 40px;
  padding-top: 10px;
  margin-bottom: 0px;
  min-height: 300px;

  .title {
    font-size: 21px;
    font-weight: 600;
    color: #444444;
    padding: 0 40px 8px;
  }

  @media screen and (max-width: 768px) {
    .title {
      padding: 0 16px;
    }
  }
`;
