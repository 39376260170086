import React from 'react';
import * as Styled from './DialogUserDecision.styles';

// Assets
import LogoFull from '@images/logo-full.svg';
import ArrowIcon from '@images/arrow-2.svg';

// Components
import Dialog from '@components/Dialog';
import Button from '@components/Button';

// Utils
import { translate as t } from '@utils/translate';

const DialogUserDecision = ({ open, setOpen, onOpenLogin, onOpenChat }) => {
  const TEXTS = {
    GET_A_QUOTE_NOW: t('getaQuoteNowChat'),
    CHARTER_FLOW_LOGIN: t('chaterFlowLogin'),
    CHOOSE_ONE_OPTIONS_BELOW: t('chooseOneOptionsBelow'),
  };

  return (
    <Dialog open={open} setOpen={setOpen} persistent>
      <Styled.Container>
        <Styled.Logo src={LogoFull} alt="flapper logo" />
        <Styled.CloseButton onClick={() => setOpen(false)} />
        <Styled.DialogTitle>
          {TEXTS.CHOOSE_ONE_OPTIONS_BELOW}:
        </Styled.DialogTitle>
        <Button
          version="v2"
          label={TEXTS.GET_A_QUOTE_NOW}
          onClick={onOpenChat}
        />
        <Styled.ButtonOutlined
          onClick={onOpenLogin}
          label={TEXTS.CHARTER_FLOW_LOGIN}
          version="v2"
        />
      </Styled.Container>
    </Dialog>
  );
};

export default DialogUserDecision;
