import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from '@images/arrows/right-arrow.png';
import Amenities from '@components/Amenities';
import { useCurrentLocale } from '@utils/translate';
import { format, parseISO } from 'date-fns';
import { translate as t } from '@utils/translate';
import { sprintf } from 'sprintf-js';
import { capitalizeFirstLetter } from '@utils/functions';

import * as Styled from './SharedFlightInfo.styles';

function SharedFlightInfo({ data, theme, variant = null }) {
  const TEXTS = {
    SEATS: t('nseats'),
    DIRECT: t('direct'),
  };
  const locale = useCurrentLocale();
  const [amenities, setAmenities] = useState([]);

  useEffect(() => {
    const amenitiesObj = data.aircraft.amenities;
    amenitiesObj.then(result => setAmenities(result));
  }, [data]);

  function flightDateTime(date) {
    return capitalizeFirstLetter(
      format(parseISO(date), "EEEE', 'PPPp", { locale })
    );
  }

  return (
    <Styled.SharedFlightInfo>
      <Styled.AircraftInfo
        isVisible={theme === 'origin'}
        bgImage={data.aircraft.image}
      >
        <div className="infos">
          <span className="infos__primary">{data.aircraft.size}</span>
          <span className="infos__secondary">{data.aircraft.name}</span>
          <span className="infos__seats">
            {sprintf(TEXTS.NSEATS, data.aircraft.seats)}
          </span>
        </div>
      </Styled.AircraftInfo>
      <Styled.FlightInfo shortMode={theme !== 'origin'} variant={variant}>
        <span className="dateTime">{flightDateTime(data.origin.dateTime)}</span>
        <div className="segmentsTime">
          <span className="startTime">
            {format(parseISO(data.origin.dateTime), 'HH:mm')}
          </span>
          <span className="type">{TEXTS.DIRECT}</span>
          <span className="endTime">
            {!!data.destination.dateTime &&
              format(parseISO(data.destination.dateTime), 'HH:mm')}
          </span>
        </div>
        <div className="iataAndAmeneties">
          <div className="iata">
            <span className="iata__code">{data.origin.code}</span>
            <img className="iata__arrow" src={ArrowIcon} />
            <span className="iata__code">{data.destination.code}</span>
          </div>
          <div className="amenities">
            <Amenities
              showTitle={false}
              amenities={amenities}
              limitToShow={3}
              greyScale={false}
              theme={theme === 'origin' ? 'green' : 'white'}
            />
          </div>
        </div>
      </Styled.FlightInfo>
    </Styled.SharedFlightInfo>
  );
}

SharedFlightInfo.propTypes = {
  theme: PropTypes.string,
  variant: PropTypes.string,
  data: PropTypes.shape(),
};

export default SharedFlightInfo;
