// Third party libs/components
import React from 'react';

// Components
import * as Styled from './BaseStep.style';

// Utils
import { translate as t } from '@utils/translate';

const BaseStep = ({ onSelect }) => {
  const TEXTS = {
    ONE_WAY_LABEL: t('oneWay'),
    ROUND_TRIP_LABEL: t('roundTrip'),
  };

  return (
    <Styled.StepContainer>
      <Styled.StepOption onClick={() => onSelect(true)}>
        {TEXTS.ONE_WAY_LABEL}
      </Styled.StepOption>
      <Styled.StepOption onClick={() => onSelect(false)}>
        {TEXTS.ROUND_TRIP_LABEL}
      </Styled.StepOption>
    </Styled.StepContainer>
  );
};

export default BaseStep;
